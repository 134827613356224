import React from "react";
import Modal from "../../Components/Modal";
import WithTableView from "../../GenericPages/WithTableView";
import withRouter from "../../Components/withRouter";
import axios from "axios";
import { services_body_keys, services_table_head } from "../../Utils/Data";
import { toast } from "react-hot-toast";
import Pagination from "../../Components/Table/Pagination";

class Services extends React.Component<any, any> {
  state = {
    data: [],
    modal: false,
    inFocus: "",
    page: 1,
  };

  componentDidMount(): void {
    this.getData();
  }

  getData = () => {
    // fetch data
    axios({
      url:
        "https://backend.cftcommerce.com/api/service/?identifier=" +
        localStorage.getItem("identifier"),
      method: "get",
    })
      .then((res) => {
        let temp = res.data.services.map((val: any) => {
          let temp_status = val.status;
          delete val.status;
          return { ...val, activeStatus: temp_status };
        });

        this.setState({ data: temp });
      })
      .catch((err) => {
        console.log("response :::: ", err);
      });
  };

  handleDelete = () => {
    this.setState({ modal: false });

    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url:
            "https://backend.cftcommerce.com/api/service/" + this.state.inFocus,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res) => {
            this.setState({ inFocus: "" });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          this.getData();
          return "Deleted Successfully!";
        },
        error: "Error while deleting",
      }
    );
  };

  handleDropdownUpdate = (data: any, event: any) => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: "https://backend.cftcommerce.com/api/service/" + data._id,
          method: "patch",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
          data: { status: event.target.value },
        })
          .then((res: any) => {
            resolve(res);
            this.getData();
          })
          .catch((err: any) => {
            reject(err);
          });
      }),
      {
        loading: "Updating...",
        success: "Updated Successfully!",
        error: "Error while updating",
      }
    );
  };

  render() {
    return (
      <>
        <Modal
          title={"Delete"}
          description={
            "Do you really want to delete this record? This process cannot be undone."
          }
          state={this.state.modal}
          onBtn1Click={() => this.setState({ modal: false, inFocus: "" })}
          onBtn2Click={() => this.handleDelete()}
        />
        <WithTableView
          heading="Services"
          head={services_table_head}
          body={this.state.data}
          body_keys={services_body_keys}
          show_btn_1={true}
          isIcon={true}
          iconType="add"
          btn1Text="New Service"
          btn1Type="filled"
          page={this.state.page}
          onClickHandler={() => {
            this.props.router.navigate("/services/add");
          }}
          onRowClick={(val: any) => {
            this.props.router.navigate("/services/update/" + val._id);
          }}
          onActionClick={(_: any, id: any) => {
            this.setState({ modal: true, inFocus: id });
          }}
          handleDropdownUpdate={this.handleDropdownUpdate}
        />
      </>
    );
  }
}

export default withRouter(Services);
