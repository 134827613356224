import styles from "../../Styles/tableLoading.module.css";

const TableLoading = (props: any) => {
  return (
    <div
      className={styles.container}
      style={{ gridTemplateColumns: `repeat(${props.head.length}, 1fr)` }}
    >
      {props.head.map((val: any, index: any) => {
        return (
          <div key={index} className={`${styles.head} ${styles.cell}`}>
            {typeof val === "string" ? val : val?.title}
          </div>
        );
      })}

      {[...Array(props.head.length * 5)].map(() => {
        return (
          <div className={`${styles.cell}`}>
            <div className={styles.loading_box}>
              <div className={styles.loading_animation}></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TableLoading;
