import { useNavigate } from "react-router-dom";
import WithTableView from "../../GenericPages/WithTableView";

const BulkMedia = () => {
  const navigate = useNavigate();

  return (
    <>
      <WithTableView
        heading={"Media Upload"}
        head={[]}
        body={[]}
        body_keys={[]}
        show_btn_1={true}
        btn1Text={"Add Media"}
        btn1Type={"filled"}
        onClickHandler={() => navigate("/media/add")}
        onRowClick={() => {}}
        onActionClick={() => {}}
      />
    </>
  );
};

export default BulkMedia;
