export function numberToWords(number: any) {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  const scales = ["", "Thousand", "Lakh", "Crore"];

  if (number === 0) return "Zero";

  let words = "";

  function getHundreds(n: any) {
    let str = "";
    const hundred = Math.floor(n / 100);
    const remainder = n % 100;

    if (hundred > 0) {
      str += ones[hundred] + " Hundred ";
    }

    if (remainder > 0) {
      if (remainder < 20) {
        if (hundred > 0) {
          str += "and ";
        }
        str += ones[remainder] + " ";
      } else {
        if (hundred > 0) {
          str += "and ";
        }
        str += tens[Math.floor(remainder / 10)] + " ";
        if (remainder % 10 > 0) {
          str += ones[remainder % 10] + " ";
        }
      }
    }

    return str.trim();
  }

  let scaleIndex = 0;
  const numberStr = number.toString();
  let chunk;

  if (numberStr.length > 7) {
    // Crore part
    chunk = Math.floor(number / 10000000);
    words += getHundreds(chunk) + " Crore ";
    number = number % 10000000;
    scaleIndex = 2;
  }

  if (numberStr.length > 5) {
    // Lakh part
    chunk = Math.floor(number / 100000);
    words += getHundreds(chunk) + " Lakh ";
    number = number % 100000;
    scaleIndex = 1;
  }

  if (numberStr.length > 3) {
    // Thousand part
    chunk = Math.floor(number / 1000);
    words += getHundreds(chunk) + " Thousand ";
    number = number % 1000;
    scaleIndex = 0;
  }

  if (number > 0) {
    words += getHundreds(number);
  }

  return words.trim() + " only";
}
