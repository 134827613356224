import React, { useState } from "react";
import { LineChart } from "../../Charts/LineChart";
import TopCard from "./TopCard";
import styles from "./orders.module.css";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

type Props = {
  order: any;
  startDate: string;
  endDate: string;
  setStartDate: any;
  setEndDate: any;
};

const Orders = (props: Props) => {
  const orders = ["All", "COD", "Online", "Cancelled"];
  const [orderType, setOrderType] = useState("All");

  console.log(props.startDate);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>Orders</h3>
        <div className={styles.date}>
          <input
            type="date"
            value={props.startDate.split("T")[0]}
            onChange={(e: any) => {
              console.log(e.target.value);
              props.setStartDate(e.target.value);
            }}
            style={{ fontSize: ".9rem" }}
          />
          <input
            type="date"
            value={props.endDate.split("T")[0]}
            onChange={(e: any) => {
              props.setEndDate(e.target.value);
            }}
            style={{ fontSize: ".9rem" }}
          />
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.header}></div>
        <div className={styles.topCardContainer}>
          <TopCard
            orders={orders}
            orderType={orderType}
            setOrderType={setOrderType}
          />
        </div>
        <LineChart
          orderType={orderType}
          dataset={props.order}
          startDate={props.startDate}
          endDate={props.endDate}
        />
        <div className={styles.btnContainer}>
          <Link to={"/analytics/orders"} className={styles.btn}>
            See More
            <FaArrowRight size={16} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Orders;
