import styles from "../../Styles/content.module.css";
import Pagination from "../Table/Pagination";

type props = {
  children: any;
  style?: any;
  isPagination?: boolean;
  pageNo?: any;
  changePage?: any;
  total?: any;
};

const ContentBody = (props: props) => {
  return (
    <div
      className={styles.content_body_container}
      style={{
        ...props?.style,
        paddingBottom: props?.isPagination ? "40px" : "",
      }}
    >
      {props.children}
      {props?.isPagination && (
        <div
          style={{
            height: "40px",
            padding: "5px",
            background: "var(--blue-light)",
            borderRadius: "0px 0px 10px 10px",
            display: "flex",
            justifyContent: "right",
            width: "100%",
            position: "absolute",
            bottom: "0",
          }}
        >
          <Pagination
            pageNo={props?.pageNo}
            changePage={props?.changePage}
            total={props?.total}
          />
        </div>
      )}
    </div>
  );
};

export default ContentBody;
