import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const TopPerformingCategory = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData]: any = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url: `https://backend.cftcommerce.com/api/analytics/topPerformingCategory?identifier=${localStorage.getItem("identifier")}`,
      method: "get",
    })
      .then((res: any) => {
        console.log(res);

        const cat = Object.keys(res.data.category);

        console.log(cat);
        const dataTemp = {
          labels: cat,
          datasets: [
            {
              data: cat.map((val) => res.data.category[val]),
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        };

        setData(dataTemp);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  if (loading)
    return (
      <div className="card">
        <h3>Top Performing Category</h3>
        <div
          style={{
            height: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      </div>
    );

  return (
    <div className="card">
      <h3>Top Performing Category</h3>
      <div style={{ padding: "20px" }}>
        <Pie data={data} />
      </div>
    </div>
  );
};
