import React from "react";
import AddServiceOrder from "./serviceOrders/AddServiceOrder";
import AddProductOrder from "./productOrders/AddProductOrder";

const AddOrder = () => {
  return (
    <>
      {localStorage.getItem("ecommerce") === "true" ? (
        <AddProductOrder />
      ) : (
        <AddServiceOrder />
      )}
    </>
  );
};

export default AddOrder;
