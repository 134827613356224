import React, { useEffect, useRef, useState } from "react";
import styles from "./shippingLabelGenerator.module.css";
import { formatDate } from "../../Utils/convertdate";
import axios from "axios";
import Label from "./label/label";
import labelInput from './label/labelInput';
import LabelInput from "./label/labelInput";
import { useReactToPrint } from "react-to-print";

const ShippingLabel = (props: any) => {
    
    const { user, product, order } = props;

    // console.log(order)
    const [admindetails, setAdmindetails]:any = useState();
    

      useEffect(() => {
        
        getAdminByToken();
      }, []);

      const getAdminByToken = () => {
        axios({
          method: "get",
          url: "https://backend.cftcommerce.com/api/admins/getAdminByToken",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res:any) => {
              // console.log(res.data.data)
              setAdmindetails(res?.data?.data)
           
          })
          .catch((err:any) => {
            console.log(err);
          });
      };

  
  const [labelData, setLabelData] = useState<any>({});
    
    useEffect(() => {
      setLabelData({
          logoUrl:admindetails?.logoUrl,
          serviceType: "Standard Mail",
          companyName: admindetails?.businessName,
          companyAddress:
            (admindetails?.address?.addLineOne +
            "," +
            admindetails?.address?.addLineTwo +
            "," +
            admindetails?.address?.landmark +
            "," +
            admindetails?.address?.city) || "",
          companyStateZip: admindetails?.address?.pincode,
          companyCountry: admindetails?.address?.state,
          customerName: order?.shippingAddress?.name,
          customerAddress:
            order.shippingAddress?.addLineOne +
            " , " +
            order.shippingAddress?.addLineTwo +
            " , " +
            order.shippingAddress?.landmark +
            " , " +
            order.shippingAddress?.city,

          customerStateZip: order.shippingAddress?.pincode,
          customerCountry: order.shippingAddress?.state,
          paymentMode: order?.modeOfPayment==="cod"? "Cash on Delivery":"Online",
          orderDate: order?.date,
          shipDate: formatDate(new Date()),
          weight: "",
          soNumber: "",
          carrierName: "",
          trackingId: "",
          deliveryInstructions: [],
        });
    }, [props, admindetails])
  
  
  // console.log(labelData)
    

    
  const handleInputChange = (e:any) => {
    const { name, value }:any = e.target;
    setLabelData((prevData:any) => ({
      ...prevData,
      [name]: value,
    }));
    };
    
interface Option {
  label: string;
  value: string;
}

const options: Option[] = [
  { label: "Handle with Care", value: "handleWithCare" },
  { label: "Fragile item", value: "fragileItem" },
  { label: "Perishable goods", value: "perishableGoods" },
  { label: "Temperature-sensitive product", value: "temperatureSensitive" },
  { label: "Do not drop", value: "doNotDrop" },
  { label: "Avoid tilting", value: "avoidTilting" },
  { label: "Signature required upon delivery", value: "signatureRequired" },
  { label: "Leave package at front desk/reception", value: "leaveAtFrontDesk" },
  { label: "No contact delivery", value: "noContactDelivery" },
  { label: "Do not leave unattended", value: "doNotLeaveUnattended" },
  { label: "Do not stack", value: "doNotStack" },
  { label: "Do not bend", value: "doNotBend" },
];

  
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = event.target.value;
      const selectedOption = options.find(
        (option:any) => option.value === selectedValue
      );
      if (selectedOption && !selectedOptions.includes(selectedOption)) {
        setSelectedOptions((prev) => [...prev, selectedOption]);
      }
    };

    const handleRemove = (value: string) => {
      setSelectedOptions((prev) =>
        prev.filter((option) => option.value !== value)
      );
    };

   const componentRef = useRef<any>();
   const handlePrint: any = useReactToPrint({
     // @ts-ignore
     content: () => componentRef?.current,
   });

   
    return (
      <div
        // ref={componentRef}
        className={styles.modal_container}
        onClick={props.close}
      >
        <div
          className={styles.label_wrapper}
          onClick={(e: any) => e.stopPropagation()}
        >
         {!labelData?.companyAddress ? <>Loading...</>: <Label
            labelData={labelData}
            handleChange={handleInputChange}
            options={options}
            selectedOptions={selectedOptions}
            refr={componentRef}
          />}
          <LabelInput
            labelData={labelData}
            handleChange={handleInputChange}
            options={options}
            handleSelect={handleSelect}
            selectedOptions={selectedOptions}
            handleRemove={handleRemove}
            refr={componentRef}
            handlePrint={handlePrint}
          />
        </div>
      </div>
    );
};

export default ShippingLabel;
