import { useContext } from "react";
import { SideBarContext } from "./sideBar";

function withContext(Component: any) {
  const Wrapper = (props: any) => {
    const context = useContext(SideBarContext);

    return <Component context={{ ...context }} {...props} />;
  };

  return Wrapper;
}

export default withContext;
