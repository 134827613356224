import { Component } from "react";
import CreateBlog from "../createBlog";
import langObj from "../../../Utils/lang";
import { useLangContext } from "../../../Store/langContext";
import withRouter from "../../../Components/withRouter";

class UpdateBlog extends Component<any, any> {
  render() {
    return (
      <>
        <CreateBlog title={langObj.updateBlog[this.props.lang]} />
      </>
    );
  }
}

export default withRouter(UpdateBlog);
