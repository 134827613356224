import { useLocation } from "react-router-dom";

export const useQuery = () => {
  const location = useLocation();
  const searchParams: any = new URLSearchParams(location.search);

  const paramsObject: any = {};

  for (const [key, value] of searchParams.entries()) {
    // If the key already exists, convert it to an array
    if (paramsObject.hasOwnProperty(key)) {
      if (!Array.isArray(paramsObject[key])) {
        paramsObject[key] = [paramsObject[key]];
      }
      paramsObject[key].push(value);
    } else {
      paramsObject[key] = value;
    }
  }
  let query_string = "";
  // console.log(paramsObject);
  Object.entries(paramsObject).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (key === "Category") {
          query_string += `category[]=${val}&`;
        } else if (key === "Collection") {
          query_string += `collectionName[]=${val}&`;
        } else {
          query_string += `${key}=${val}&`;
        }
      });
    } else {
      if (key === "Category") {
        query_string += `category[]=${value}&`;
      } else if (key === "Collection") {
        query_string += `collectionName[]=${value}&`;
      } else {
        query_string += `${key}=${value}&`;
      }
    }
  });

  return query_string;
};
