import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import ObjToExcel from "../../Utils/objToExcel";
import { useLocation, useNavigate } from "react-router-dom";
import { useLangContext } from "../../Store/langContext";
import { useAdminContext } from "../../Store/adminContext";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import styles from "./leads.module.css";
import { ReactComponent as DownIcon } from "../../Assets/icons/down.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import SingleRow from "../../Components/Leads/singleRow";
import { data } from "../../Components/Charts/BarChart";
import { ReactComponent as TrashIcon } from "../../Assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../Assets/newIcons/edit.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
let inFocus = "";

const Leads = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang }: any = useLangContext();
  const [secondaryView, setSecondaryView] = useState(false);
  const [inView, setInView]: any = useState({});
  const [tab, setTab] = useState("overview");

  const [contacts, setContacts]: any = useState([]);
  const [updatedContacts, setUpdatedContacts]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [subAdmins, setSubAdmins] = useState([]);

  const [contactOwnerDropdown, setContactOwnerDropdown] = useState(false);
  const [createDateDropdown, setCreateDateDropdown] = useState(false);
  const [lastActivityDropdown, setLastActivityDropdown] = useState(false);
  const [leadStatusDropdown, setLeadStatusDropdown] = useState(false);
  const [actionsDropdown, setActionsDropdown] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);

  const [navContactOwnerSearch, setNavContactOwnerSearch] = useState("");
  const [navCreateDateSearch, setNavCreateDateSearch] = useState("");
  const [navLastActivitySearch, setNavLastActivitySearch] = useState("");
  const [navLeadStatusSearch, setNavLeadStatusSearch] = useState("");

  const [remark, setRemark] = useState("");
  const [logs, setLogs] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [disableForAction, setDisableForAction] = useState(false);

  const [filter, setFilter]: any = useState({
    contactOwner: [],
    createDate: "",
    lastActivityDate: "",
    leadStatus: [],
    search: "",
  });

  const [deleteById, setDeleteById] = useState("");

  const { admin }: any = useAdminContext();

  useEffect(() => {
    axios({
      method: "GET",
      url: "https://backend.cftcommerce.com/api/sub-admin",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setSubAdmins(res.data.sub_admins);
      })
      .catch((err: any) => {
        console.log("error ::: ", err);
      });
  }, []);

  useEffect(() => {
    handlePage();
  }, [filter]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setFilter((prevFilter: any) => ({ ...prevFilter, search: searchTerm }));
    }, 500); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (contacts.length === 0) return;

    if (contacts.filter((val: any) => val.actions.select).length !== 0) {
      setDisableForAction(true);
    } else {
      setDisableForAction(false);
    }
  }, [contacts]);

  useEffect(() => {
    setUpdateMode(updatedContacts.length === 0 ? false : true);
  }, [updatedContacts]);

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handlePage = () => {
    getData();
  };

  const getLogs = () => {
    axios({
      method: "get",
      url:
        "https://backend.cftcommerce.com/api/contact-logs?identifier=" +
        localStorage.getItem("identifier"),
    })
      .then((res: any) => {
        console.log(res.data.data);
        setLogs(res.data.data?.fromTo);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getData = () => {
    setLoading(true);
    getLogs();
    let search = new URLSearchParams(location.search);

    const data: any = { ...filter, status: filter.leadStatus };

    if (data.contactOwner.length === 0) delete data.contactOwner;
    if (!data.createDate) delete data.createDate;
    if (!data.lastActivityDate) delete data.lastActivityDate;
    delete data.leadStatus;
    if (data.status.length === 0) delete data.leadStatus;
    if (!data.search) delete data.search;

    axios({
      method: "post",
      url: `https://backend.cftcommerce.com/api/leads/search?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setContacts([
          ...res.data.contacts.map((val: any) => {
            if (val?.modifiedAt) {
              const utc = new Date(val?.modifiedAt);
              const utc_createdAt = new Date(val?.createdAt);

              const local = new Date(
                utc.toLocaleString("en-us", { timeZone: "Asia/Kolkata" })
              );

              const local_createdAt = new Date(
                utc_createdAt.toLocaleString("en-us", {
                  timeZone: "Asia/Kolkata",
                })
              );

              const formattedLocal = `${local.getFullYear()}-${String(
                local.getMonth() + 1
              ).padStart(2, "0")}-${String(local.getDate()).padStart(
                2,
                "0"
              )}T${String(local.getHours()).padStart(2, "0")}:${String(
                local.getMinutes()
              ).padStart(2, "0")}`;

              const formattedLocal_createdAt = `${local_createdAt.getFullYear()}-${String(
                local_createdAt.getMonth() + 1
              ).padStart(2, "0")}-${String(local_createdAt.getDate()).padStart(
                2,
                "0"
              )}T${String(local_createdAt.getHours()).padStart(
                2,
                "0"
              )}:${String(local_createdAt.getMinutes()).padStart(2, "0")}`;

              if (inView._id === val._id) {
                setInView({
                  ...val,
                  modifiedAt: formattedLocal,
                  createdAt: formattedLocal_createdAt,
                  actions: { select: false },
                });
              }

              return {
                ...val,
                modifiedAt: formattedLocal,
                createdAt: formattedLocal_createdAt,
                actions: { select: false },
              };
            } else {
              if (inView._id === val._id) {
                setInView({
                  ...val,
                  actions: { select: false },
                });
              }
              return {
                ...val,
                actions: { select: false },
              };
            }
          }),
        ]);
        setTotal(res.data.totalContacts);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleRemarkUpdate = (data: any, remark: any) => {
    axios({
      method: "patch",
      url: `https://backend.cftcommerce.com/api/contacts/${data._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
      data: { remarks: remark },
    })
      .then((res: any) => {
        toast.success("Remark updated successfully.");
        getData();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  };

  const handleDropdownUpdate = (data: any, event: any) => {
    axios({
      method: "patch",
      url: `https://backend.cftcommerce.com/api/contacts/${data._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
      data: { status: event.target.value },
    })
      .then((res: any) => {
        toast.success("Status updated successfully.");
        getData();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  };

  const handleDelete = async () => {
    setModal(false);
    setLoading(true);

    const temp = [...contacts]
      .filter((val) => val.actions.select)
      .map((v) => v._id);

    await axios({
      method: "delete",
      url: "https://backend.cftcommerce.com/api/contacts/multiple-delete",
      data: { ids: temp },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    });

    getData();
    setLoading(false);
    toast.success("Deleted Successfully.");
  };

  const handleSingleDelete = () => {
    setModal(false);
    setLoading(true);

    let index: any;

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i]._id === deleteById) {
        index = i;
        break;
      }
    }

    let nextInView: any;

    if (index === 0) {
      nextInView = contacts[1];
    } else if (contacts.length - 1 === index) {
      nextInView = contacts[index - 1];
    } else {
      nextInView = contacts[index - 1];
    }

    axios({
      url: "https://backend.cftcommerce.com/api/contacts/" + deleteById,
      method: "delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        console.log(res);
        getData();
        setLoading(false);
        console.log(nextInView);
        setInView(nextInView);
        toast.success("Deleted Successfully.");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("Something went wrong while delete the contact.");
      });
  };

  const handleBulkUpdate = async (to: any) => {
    setLoading(true);
    const temp = [...contacts]
      .filter((val) => val.actions.select)
      .map((v) => ({ ...v, status: to }));

    await axios({
      url:
        "https://backend.cftcommerce.com/api/contacts/multiple-updates?identifier=" +
        localStorage.getItem("identifier"),
      method: "patch",
      data: { updates: temp },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    });

    getData();
    setLoading(false);
    toast.success("Updated Successfully.");
  };

  const handleDeleteClick = () => {
    setModal(true);
  };

  const exportExcel = () => {
    ObjToExcel(contacts, "Leads", "Leads", [
      { title: "Name", value: "firstName" },
      { title: "Middle Name", value: "middleName" },
      { title: "Last Name", value: "lastName" },
      { title: "Mobile Number", value: "mobileNumber" },
      { title: "Email", value: "personalEmail" },
      { title: "Primary Company", value: "companyName" },
      { title: "Contact Owner", value: "leadOwner" },
      { title: "Lead Status", value: "status" },
      { title: "Created At", value: "createdAt" },
      { title: "Modified At", value: "modifiedAt" },
    ]);
  };

  const checkAll = () => {
    setContacts((prev: any) => {
      let temp = [...prev];
      if (
        temp.filter((val: any) => val.actions.select).length === temp.length
      ) {
        temp = temp.map((val) => ({ ...val, actions: { select: false } }));
      } else {
        temp = temp.map((val) => ({ ...val, actions: { select: true } }));
      }
      return temp;
    });
  };

  const changeSelect = (id: any) => {
    console.log("id ::: ", id);
    setContacts((prev: any) => {
      let temp: any = [...prev];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]._id === id) {
          temp[i].actions.select = !temp[i].actions.select;
          break;
        }
      }
      return temp;
    });
  };

  const updateContacts = async () => {
    setLoading(true);

    for (let i = 0; i < updatedContacts.length; i++) {
      const d = { ...updatedContacts[i], modifiedAt: new Date().toISOString() };
      delete d.actions;

      await axios({
        url: `https://backend.cftcommerce.com/api/contacts/${d._id}`,
        method: "patch",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
        data: d,
      });
    }

    toast.success("Updated successfully.");
    setUpdatedContacts([]);
    setLoading(false);
    getData();
  };

  const handleAddRemark = () => {
    setLoading(true);
    axios({
      url: "https://backend.cftcommerce.com/api/contacts/" + inView._id,
      method: "patch",
      data: {
        remarks: [
          { content: remark, date: new Date(), by: admin?.email || "" },
          ...inView?.remarks,
        ],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        console.log(res);
        getData();
        setRemark("");
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getChangedFields = (fromObj: any, toObj: any) => {
    const changedFields = [];
    for (let key in fromObj) {
      if (fromObj[key] !== toObj[key]) {
        changedFields.push({
          field: key,
          fromValue: fromObj[key],
          toValue: toObj[key],
        });
      }
    }
    return changedFields;
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => {
          setModal(false);
          inFocus = "";
          setDeleteById("");
        }}
        onBtn2Click={() => {
          if (deleteById) {
            handleSingleDelete();
          } else {
            handleDelete();
          }
        }}
        state={modal}
      />
      <Content>
        <ContentHead
          title={"Contacts"}
          showBtn1={true}
          btn1Name={updateMode ? "update_btn1" : "btn1"}
          btn1Text={"Add New"}
          isIcon1={true}
          iconType1={faPlus}
          btn1type="filled"
          isMoreSettings={true}
          moreSettingsList={[
            { icon: "import", title: "Import", name: "import" },
            { icon: "export", title: "Export", name: "export" },
            { icon: "logs", title: "Logs", name: "logs" },
          ]}
          handleMoreClick={(name: any) => {
            if (name === "import") {
              navigate("/contacts/import-file");
            } else if (name === "export") {
              exportExcel();
            } else if (name === "logs") {
              navigate("/contacts/logs");
            }
          }}
          onClickHandler={(e: any) => {
            if (e.target.name === "btn1") {
              navigate("/contacts/add");
            }
          }}
          customElement={
            <div className={styles.search_section}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder="Search name, phone, email ..."
                value={searchTerm}
                onChange={handleChange}
              />
            </div>
          }
        />
        <ContentBody style={{ padding: 0 }}>
          {(contactOwnerDropdown ||
            createDateDropdown ||
            lastActivityDropdown ||
            leadStatusDropdown ||
            actionsDropdown) && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "transparent",
                zIndex: 100,
              }}
              onClick={() => {
                setContactOwnerDropdown(false);
                setCreateDateDropdown(false);
                setLastActivityDropdown(false);
                setLeadStatusDropdown(false);
                setActionsDropdown(false);
              }}
            ></div>
          )}
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div className={styles.filter_container}>
              <div
                className={styles.drop_down_container}
                style={{
                  opacity: disableForAction ? ".5" : "1",
                  cursor: disableForAction ? "not-allowed" : "pointer",
                  pointerEvents: disableForAction ? "none" : "all",
                }}
              >
                <div
                  className={styles.drop_down_content}
                  onClick={() => setContactOwnerDropdown(true)}
                  style={
                    filter?.contactOwner.length !== 0
                      ? {
                          padding: "7px 10px",
                          borderRadius: "5px",
                          background: "#d9e7f8",
                        }
                      : {}
                  }
                >
                  {filter?.contactOwner.length !== 0 && (
                    <div
                      style={{
                        borderRadius: "100%",
                        color: "var(--brand-color)",
                        fontSize: ".8rem",
                      }}
                    >
                      {`(${filter.contactOwner.length})`}
                    </div>
                  )}
                  <p>Contact owner</p>
                  <DownIcon />
                  {filter?.contactOwner.length !== 0 && (
                    <FontAwesomeIcon
                      icon={faClose}
                      color={"grey"}
                      style={{ marginLeft: "5px" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setFilter((prev: any) => ({
                          ...prev,
                          contactOwner: [],
                        }));
                      }}
                    />
                  )}
                </div>
                {contactOwnerDropdown && (
                  <div className={styles.drop_down_section}>
                    <div style={{ padding: "10px" }}>
                      <div className={styles.contact_owner_search}>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          type="text"
                          placeholder="Search contact owner"
                          value={navContactOwnerSearch}
                          onChange={(e: any) =>
                            setNavContactOwnerSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "0",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {subAdmins
                        .filter((v: any) => {
                          if (!navContactOwnerSearch) return true;

                          if (
                            v.firstName
                              .toLowerCase()
                              .trim()
                              .includes(
                                navContactOwnerSearch.toLowerCase().trim()
                              ) ||
                            v.lastName
                              .toLowerCase()
                              .trim()
                              .includes(
                                navContactOwnerSearch.toLowerCase().trim()
                              ) ||
                            `${v.firstName.toLowerCase().trim()} ${v.lastName
                              .toLowerCase()
                              .trim()}`.includes(
                              navContactOwnerSearch.toLowerCase().trim()
                            ) ||
                            v.email
                              ?.toLowerCase()
                              .trim()
                              .includes(
                                navContactOwnerSearch.toLowerCase().trim()
                              )
                          )
                            return true;

                          return false;
                        })
                        .map((subadmin: any, index: any) => {
                          return (
                            <div
                              className={styles.contact_owner_single_list}
                              key={index}
                              onClick={() => {
                                setFilter((prev: any) => {
                                  let temp: any = [...filter.contactOwner];
                                  if (temp.length === 0)
                                    return {
                                      ...prev,
                                      contactOwner: [subadmin._id],
                                    };

                                  if (temp.includes(subadmin._id)) {
                                    temp.splice(temp.indexOf(subadmin._id), 1);
                                    return { ...prev, contactOwner: temp };
                                  } else {
                                    temp.push(subadmin._id);
                                    return { ...prev, contactOwner: temp };
                                  }
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={filter.contactOwner.includes(
                                  subadmin._id
                                )}
                              />
                              <div>
                                <p
                                  style={{ fontSize: ".9rem" }}
                                >{`${subadmin?.firstName} ${subadmin?.lastName}`}</p>
                                <p style={{ fontSize: ".8rem" }}>
                                  {subadmin?.email}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      {!navContactOwnerSearch ? (
                        <div
                          className={styles.contact_owner_single_list}
                          onClick={() => {
                            setFilter((prev: any) => {
                              let temp: any = [...filter.contactOwner];
                              if (temp.length === 0)
                                return {
                                  ...prev,
                                  contactOwner: ["unassigned"],
                                };

                              if (temp.includes("unassigned")) {
                                temp.splice(temp.indexOf("unassigned"), 1);
                                return { ...prev, contactOwner: temp };
                              } else {
                                temp.push("unassigned");
                                return { ...prev, contactOwner: temp };
                              }
                            });
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={filter.contactOwner.includes("unassigned")}
                          />
                          <div>
                            <p style={{ fontSize: ".9rem" }}>Unassigned</p>
                          </div>
                        </div>
                      ) : "unassigned".includes(
                          navContactOwnerSearch.toLowerCase().trim()
                        ) ||
                        "unassigned".includes(
                          navContactOwnerSearch.toLowerCase().trim()
                        ) ||
                        "unassigned".includes(
                          navContactOwnerSearch.toLowerCase().trim()
                        ) ? (
                        <div
                          className={styles.contact_owner_single_list}
                          onClick={() => {
                            setFilter((prev: any) => {
                              let temp: any = [...filter.contactOwner];
                              if (temp.length === 0)
                                return {
                                  ...prev,
                                  contactOwner: ["unassigned"],
                                };

                              if (temp.includes("unassigned")) {
                                temp.splice(temp.indexOf("unassigned"), 1);
                                return { ...prev, contactOwner: temp };
                              } else {
                                temp.push("unassigned");
                                return { ...prev, contactOwner: temp };
                              }
                            });
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={filter.contactOwner.includes("unassigned")}
                          />
                          <div>
                            <p style={{ fontSize: ".9rem" }}>Unassigned</p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles.drop_down_container}
                style={{
                  opacity: disableForAction ? ".5" : "1",
                  cursor: disableForAction ? "not-allowed" : "pointer",
                  pointerEvents: disableForAction ? "none" : "all",
                }}
              >
                <div
                  className={styles.drop_down_content}
                  onClick={() => setCreateDateDropdown(true)}
                  style={
                    filter?.createDate
                      ? {
                          padding: "7px 10px",
                          borderRadius: "5px",
                          background: "#d9e7f8",
                        }
                      : {}
                  }
                >
                  <p>Create Date</p>
                  <DownIcon />
                  {filter?.createDate && (
                    <FontAwesomeIcon
                      icon={faClose}
                      color={"grey"}
                      style={{ marginLeft: "5px" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setFilter((prev: any) => ({ ...prev, createDate: "" }));
                      }}
                    />
                  )}
                </div>
                {createDateDropdown && (
                  <div className={styles.drop_down_section}>
                    <div style={{ padding: "10px" }}>
                      <div className={styles.contact_owner_search}>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          type="text"
                          placeholder="Search"
                          value={navCreateDateSearch}
                          onChange={(e: any) =>
                            setNavCreateDateSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "0",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {date_values
                        .filter((v: any) => {
                          if (!navCreateDateSearch) return true;

                          const text = navCreateDateSearch.toLowerCase().trim();

                          if (v.title.toLowerCase().includes(text)) return true;
                          if (v.description.toLowerCase().includes(text))
                            return true;

                          return false;
                        })
                        .map((val: any, index: any) => {
                          return (
                            <div
                              className={styles.contact_owner_single_list}
                              key={index}
                              onClick={() => {
                                setFilter((prev: any) => ({
                                  ...prev,
                                  createDate: val.value,
                                }));
                                setCreateDateDropdown(false);
                              }}
                            >
                              <div>
                                <p style={{ fontSize: ".9rem" }}>{val.title}</p>
                                <p style={{ fontSize: ".8rem" }}>
                                  {val.description}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles.drop_down_container}
                style={{
                  opacity: disableForAction ? ".5" : "1",
                  cursor: disableForAction ? "not-allowed" : "pointer",
                  pointerEvents: disableForAction ? "none" : "all",
                }}
              >
                <div
                  className={styles.drop_down_content}
                  onClick={() => setLastActivityDropdown(true)}
                  style={
                    filter?.lastActivityDate
                      ? {
                          padding: "7px 10px",
                          borderRadius: "5px",
                          background: "#d9e7f8",
                        }
                      : {}
                  }
                >
                  <p>Last Activity Date</p>
                  <DownIcon />
                  {filter?.lastActivityDate && (
                    <FontAwesomeIcon
                      icon={faClose}
                      color={"grey"}
                      style={{ marginLeft: "5px" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setFilter((prev: any) => ({
                          ...prev,
                          lastActivityDate: "",
                        }));
                      }}
                    />
                  )}
                </div>
                {lastActivityDropdown && (
                  <div className={styles.drop_down_section}>
                    <div style={{ padding: "10px" }}>
                      <div className={styles.contact_owner_search}>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          type="text"
                          placeholder="Search"
                          value={navLastActivitySearch}
                          onChange={(e: any) =>
                            setNavLastActivitySearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "0",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {date_values
                        .filter((v: any) => {
                          if (!navLastActivitySearch) return true;
                          const text = navLastActivitySearch
                            .toLowerCase()
                            .trim();

                          if (v.title.toLowerCase().includes(text)) return true;
                          if (v.description.toLowerCase().includes(text))
                            return true;

                          return false;
                        })
                        .map((val: any, index: any) => {
                          return (
                            <div
                              className={styles.contact_owner_single_list}
                              key={index}
                              onClick={() => {
                                setFilter((prev: any) => ({
                                  ...prev,
                                  lastActivityDate: val.value,
                                }));
                                setLastActivityDropdown(false);
                              }}
                            >
                              <div>
                                <p style={{ fontSize: ".9rem" }}>{val.title}</p>
                                <p style={{ fontSize: ".8rem" }}>
                                  {val.description}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles.drop_down_container}
                style={{
                  opacity: disableForAction ? ".5" : "1",
                  cursor: disableForAction ? "not-allowed" : "pointer",
                  pointerEvents: disableForAction ? "none" : "all",
                }}
              >
                <div
                  className={styles.drop_down_content}
                  onClick={() => setLeadStatusDropdown(true)}
                  style={
                    filter?.leadStatus.length !== 0
                      ? {
                          padding: "7px 10px",
                          borderRadius: "5px",
                          background: "#d9e7f8",
                        }
                      : {}
                  }
                >
                  {filter?.leadStatus.length !== 0 && (
                    <div
                      style={{
                        borderRadius: "100%",
                        color: "var(--brand-color)",
                        fontSize: ".8rem",
                      }}
                    >
                      {`(${filter.leadStatus.length})`}
                    </div>
                  )}
                  <p>Lead Status</p>
                  <DownIcon />
                  {filter?.leadStatus.length !== 0 && (
                    <FontAwesomeIcon
                      icon={faClose}
                      color={"grey"}
                      style={{ marginLeft: "5px" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setFilter((prev: any) => ({ ...prev, leadStatus: [] }));
                      }}
                    />
                  )}
                </div>
                {leadStatusDropdown && (
                  <div className={styles.drop_down_section}>
                    <div style={{ padding: "10px" }}>
                      <div className={styles.contact_owner_search}>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          type="text"
                          placeholder="Search"
                          value={navLeadStatusSearch}
                          onChange={(e: any) =>
                            setNavLeadStatusSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "0",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {lead_values
                        .filter((v: any) => {
                          if (!navLeadStatusSearch) return true;

                          const text = navLeadStatusSearch.toLowerCase().trim();

                          if (v.title.toLowerCase().includes(text)) return true;

                          return false;
                        })
                        .map((val: any, index: any) => {
                          return (
                            <div
                              className={styles.contact_owner_single_list}
                              key={index}
                              onClick={() => {
                                setFilter((prev: any) => {
                                  let temp: any = [...filter.leadStatus];
                                  if (temp.length === 0)
                                    return { ...prev, leadStatus: [val.value] };

                                  if (temp.includes(val.value)) {
                                    temp.splice(temp.indexOf(val.value), 1);
                                    return { ...prev, leadStatus: temp };
                                  } else {
                                    temp.push(val.value);
                                    return { ...prev, leadStatus: temp };
                                  }
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={filter.leadStatus.includes(val.value)}
                              />
                              <div>
                                <p style={{ fontSize: ".9rem" }}>{val.title}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              {disableForAction && (
                <div className={styles.drop_down_container}>
                  <div
                    className={styles.drop_down_content}
                    onClick={() => setActionsDropdown(true)}
                  >
                    <p>Actions</p>
                    <DownIcon />
                  </div>
                  {actionsDropdown && (
                    <div className={styles.drop_down_section}>
                      <div style={{ padding: "10px" }}>
                        <div className={styles.contact_owner_search}>
                          <FontAwesomeIcon icon={faSearch} />
                          <input type="text" placeholder="Search" />
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "0",
                          maxHeight: "250px",
                          overflowY: "auto",
                        }}
                      >
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={handleDeleteClick}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>Delete</p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBulkUpdate("new")}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>Set Status: New</p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBulkUpdate("open")}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Open
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBulkUpdate("in_progress")}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: In Progress
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBulkUpdate("open_deal")}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Open Deal
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBulkUpdate("unqualified")}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Unqualified
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleBulkUpdate("attempted_to_contact")
                          }
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Attempted to contact
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBulkUpdate("connected")}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Connected
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBulkUpdate("bad_timing")}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Bad Timing
                            </p>
                          </div>
                        </div>
                        <div
                          className={styles.contact_owner_single_list}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBulkUpdate("unassigned")}
                        >
                          <div>
                            <p style={{ fontSize: ".9rem" }}>
                              Set Status: Unassigned
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {secondaryView ? (
              <div className={styles.secondary_view}>
                <div className={styles.secondary_list_view}>
                  {contacts.map((val: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={styles.secondary_short_item}
                        style={
                          val._id === inView?._id
                            ? {
                                background: "var(--brand-color)",
                                color: "white",
                              }
                            : {}
                        }
                        onClick={() => setInView(val)}
                      >
                        <input
                          type="checkbox"
                          checked={val.actions.select}
                          onClick={() => {
                            changeSelect(val._id);
                          }}
                        />
                        <div>
                          <p>
                            {val?.firstName || val?.lastName
                              ? [val?.firstName, val?.lastName].join(" ")
                              : "--"}
                          </p>
                          <span>{val?.personalEmail}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.secondary_detail_view}>
                  <div>
                    <div className={styles.sdv_head}>
                      <p style={{ textTransform: "capitalize" }}>
                        {inView?.firstName ||
                        inView?.middleName ||
                        inView?.lastName
                          ? [
                              inView?.firstName?.trim() || "",
                              inView?.middleName?.trim() || "",
                              inView?.lastName?.trim() || "",
                            ]?.join(" ")
                          : "Undefined"}
                      </p>
                      <div className={styles.sdv_buttons}>
                        <button
                          onClick={() =>
                            navigate("/contacts/update/" + inView?._id)
                          }
                        >
                          <EditIcon /> Edit
                        </button>
                        <button
                          onClick={() => {
                            setDeleteById(inView?._id);
                            setModal(true);
                          }}
                        >
                          <TrashIcon />
                          Delete
                        </button>
                        <CloseIcon
                          className={styles.sdv_close_btn}
                          onClick={() => {
                            setSecondaryView(false);
                            setInView(null);
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.tabs_section}>
                      <div
                        className={
                          tab === "overview" ? styles.tab_selected : ""
                        }
                        onClick={() => setTab("overview")}
                      >
                        Overview
                      </div>
                      <div
                        className={tab === "remarks" ? styles.tab_selected : ""}
                        onClick={() => setTab("remarks")}
                      >
                        Remarks
                      </div>
                      <div
                        className={tab === "history" ? styles.tab_selected : ""}
                        onClick={() => setTab("history")}
                      >
                        Logs
                      </div>
                    </div>
                  </div>
                  {tab === "overview" && (
                    <>
                      <div className={styles.secondary_content}>
                        <div>
                          <span>First Name</span>
                          <p>{inView?.firstName || "--"}</p>
                        </div>

                        <div>
                          <span>Middle Name</span>
                          <p>{inView?.middleName || "--"}</p>
                        </div>
                        <div>
                          <span>Last Name</span>
                          <p>{inView?.lastName || "--"}</p>
                        </div>
                        <div>
                          <span>Company Name</span>
                          <p>{inView?.companyName || "--"}</p>
                        </div>
                        <div>
                          <span>Company Website</span>
                          <p>{inView?.companyWebsite || "--"}</p>
                        </div>
                        <div>
                          <span>Company HQ</span>
                          <p>{inView?.companyHQ || "--"}</p>
                        </div>
                        <div>
                          <span>Mobile Number</span>
                          <p>{inView?.mobileNumber}</p>
                        </div>
                        <div>
                          <span>Alt. Mobile Number</span>
                          <p>{inView?.altMobileNumber || "--"}</p>
                        </div>
                        <div>
                          <span>Email</span>
                          <p>{inView?.personalEmail || "--"}</p>
                        </div>
                        <div>
                          <span>Cooperate Email</span>
                          <p>{inView?.cooperateEmail || "--"}</p>
                        </div>
                        <div>
                          <span>Domain</span>
                          <p>{inView?.domain || "--"}</p>
                        </div>
                        <div>
                          <span>Address</span>
                          <p>{inView?.address || "--"}</p>
                        </div>
                        <div>
                          <span>City</span>
                          <p>{inView?.city || "--"}</p>
                        </div>
                        <div>
                          <span>State</span>
                          <p>{inView?.state || "--"}</p>
                        </div>
                        <div>
                          <span>Country</span>
                          <p>{inView?.country || "--"}</p>
                        </div>
                        <div>
                          <span>Pincode</span>
                          <p>{inView?.pincode || "--"}</p>
                        </div>
                      </div>

                      <div className={styles.secondary_content_2}>
                        <div>
                          <span>Reason 1</span>
                          <p>{inView?.reason1 || "--"}</p>
                        </div>
                        <div>
                          <span>Reason 2</span>
                          <p>{inView?.reason2 || "--"}</p>
                        </div>
                        <div>
                          <span>Message</span>
                          <p>{inView?.message || "--"}</p>
                        </div>
                        <div>
                          <span>Terms</span>
                          <p>{inView?.terms || "--"}</p>
                        </div>
                      </div>

                      <div className={styles.secondary_content}>
                        <div
                          style={{
                            gridColumn: "1/4",
                            fontSize: ".9rem",
                          }}
                        >
                          <h4 style={{ fontWeight: 500 }}>
                            About User's Device
                          </h4>
                        </div>
                        <div>
                          <span>User's Ip Address</span>
                          <p>{inView?.ipAddress || "--"}</p>
                        </div>
                        <div>
                          <span>User Agent</span>
                          <p>{inView?.userDeviceDetails?.userAgent || "--"}</p>
                        </div>
                        <div>
                          <span>Browser Name</span>
                          <p>
                            {inView?.userDeviceDetails?.browserName || "--"}
                          </p>
                        </div>
                        <div>
                          <span>Browser Version</span>
                          <p>
                            {inView?.userDeviceDetails?.browserVersion || "--"}
                          </p>
                        </div>
                        <div>
                          <span>Platform</span>
                          <p>{inView?.userDeviceDetails?.platform || "--"}</p>
                        </div>
                        <div>
                          <span>Language</span>
                          <p>{inView?.userDeviceDetails?.language || "--"}</p>
                        </div>
                        <div>
                          <span>Screen Width</span>
                          <p>
                            {inView?.userDeviceDetails?.screenWidth || "--"}
                          </p>
                        </div>
                        <div>
                          <span>Screen Height</span>
                          <p>
                            {inView?.userDeviceDetails?.screenHeight || "--"}
                          </p>
                        </div>
                        <div>
                          <span>Pixel Ratio</span>
                          <p>{inView?.userDeviceDetails?.pixelRatio || "--"}</p>
                        </div>
                      </div>
                    </>
                  )}
                  {tab === "remarks" && (
                    <div className={styles.remarks}>
                      <div className={styles.remark_input}>
                        <label>Add Remark</label>
                        <textarea
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                        <div className={styles.remark_btn}>
                          <button onClick={handleAddRemark}>Add</button>
                        </div>
                      </div>
                      {inView?.remarks?.length !== 0 && (
                        <div className={styles.old_remarks}>
                          <h3>Old Remarks</h3>
                          <div className={styles.old_remarks_container}>
                            {inView?.remarks
                              ?.filter((v: any) => v)
                              ?.map((r: any, index: any) => {
                                console.log(r);
                                return (
                                  <div
                                    className={styles.old_remarks_sub_container}
                                    key={index}
                                  >
                                    <div
                                      className={styles.old_remarks_dot}
                                    ></div>
                                    <div className={styles.old_remarks_heading}>
                                      <p>
                                        {`${r.date
                                          .split("T")[0]
                                          .split("-")
                                          .reverse()
                                          .join("/")} ${
                                          r.date.split("T")[1].split(".")[0]
                                        } by ${r.by}`}
                                      </p>
                                      <p>{r.content}</p>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {tab === "history" && (
                    <div className={styles.history}>
                      <div className={styles.history_container}>
                        {logs
                          ?.filter((l: any) => {
                            let from_temp = l?.from || [];
                            let to_temp = l?.to || [];

                            for (let i = 0; i < from_temp.length; i++) {
                              const single_from = from_temp[i];
                              if (single_from._id === inView._id) {
                                return true;
                              }
                            }

                            for (let i = 0; i < to_temp.length; i++) {
                              const single_to = to_temp[i];
                              if (single_to._id === inView._id) {
                                return true;
                              }
                            }

                            return false;
                          })
                          ?.map((log: any, index: any) => {
                            let from = log?.from?.filter(
                              (v: any) => v?._id === inView._id
                            );
                            let to = log?.to?.filter(
                              (v: any) => v?._id === inView._id
                            );

                            console.log("from", from);
                            console.log("to", to);

                            let Type = "";

                            let changed: any = [];

                            if (from.length === to.length) {
                              // something is updated
                              const single_from = from[0];
                              const single_to = to[0];

                              const changedFields = getChangedFields(
                                single_from,
                                single_to
                              );

                              if (
                                changedFields.length === 1 &&
                                changedFields[0].field === "remarks"
                              ) {
                                Type = "Added Remark";
                              } else {
                                Type = "Updated";
                                changed = changedFields.filter(
                                  (v) =>
                                    v.field !== "remarks" &&
                                    v.field !== "createdAt" &&
                                    v.field !== "modifiedAt"
                                );
                              }

                              console.log("changed fields ::: ", changedFields);
                            } else {
                              // Newly added
                              Type =
                                log?.type === "createdByAdmin"
                                  ? "Created By Admin"
                                  : "Submitted By User";
                              const single_to = to[0];
                            }

                            console.log(Type);
                            console.log(log?.date);
                            console.log(to);

                            return (
                              <div
                                className={styles.history_sub_container}
                                key={index}
                              >
                                <div className={styles.history_dot}></div>
                                <div className={styles.history_heading}>
                                  <h4>{Type}</h4>
                                  <p className={styles.history_time}>
                                    {`${log?.date
                                      ?.split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("/")} ${
                                      log?.date?.split("T")[1]?.split(".")[0]
                                    } by ${log?.by}`}
                                  </p>
                                  {Type === "Added Remark" ? (
                                    <div>{to[0]?.remarks[0]?.content}</div>
                                  ) : (
                                    <>
                                      <div
                                        className={
                                          styles.history_table_container
                                        }
                                      >
                                        <table>
                                          <thead>
                                            {Type === "Created By Admin" ||
                                            Type === "Submitted By Admin" ? (
                                              <tr>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Fields
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Values
                                                </th>
                                              </tr>
                                            ) : (
                                              <tr>
                                                <th></th>
                                                <th>From</th>
                                                <th>To</th>
                                              </tr>
                                            )}
                                          </thead>
                                          <tbody>
                                            {Type === "Created By Admin" ||
                                            Type === "Submitted By Admin" ? (
                                              <>
                                                {Object.keys(to[0])
                                                  ?.filter((v) => {
                                                    return (
                                                      v !== "identifier" &&
                                                      v !==
                                                        "mobileCountryCode" &&
                                                      v !==
                                                        "altMobileCountryCode" &&
                                                      v !== "_id" &&
                                                      v !== "__v" &&
                                                      v !== "id" &&
                                                      v !== "createdAt" &&
                                                      v !== "modifiedAt" &&
                                                      v !== "campaign"
                                                    );
                                                  })
                                                  ?.map((v: any, i: any) => {
                                                    const obj = to[0];

                                                    return (
                                                      <tr key={i}>
                                                        <td
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {contact_fields[v]}
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {v === "remarks"
                                                            ? obj?.remarks[0]
                                                                ?.content ||
                                                              "--"
                                                            : obj[v] || "--"}
                                                        </td>
                                                      </tr>
                                                    );
                                                  })}
                                              </>
                                            ) : (
                                              <>
                                                {from.map(
                                                  (fromObj: any, i: any) => {
                                                    const toObj = to[i];
                                                    const changedFields =
                                                      getChangedFields(
                                                        fromObj,
                                                        toObj
                                                      );

                                                    console.log(changed);

                                                    return changed.map(
                                                      (field: any, j: any) => {
                                                        if (
                                                          typeof field.fromValue ===
                                                          "object"
                                                        ) {
                                                          let u_keys =
                                                            Array.from(
                                                              new Set([
                                                                ...Object.keys(
                                                                  field?.fromValue ||
                                                                    {}
                                                                ),
                                                                ...Object.keys(
                                                                  field?.toValue ||
                                                                    {}
                                                                ),
                                                              ])
                                                            );

                                                          u_keys =
                                                            u_keys.filter(
                                                              (v) =>
                                                                field.fromValue[
                                                                  v
                                                                ] !==
                                                                field.toValue[v]
                                                            );

                                                          return (
                                                            <>
                                                              {u_keys.map(
                                                                (
                                                                  k: any,
                                                                  i: any
                                                                ) => {
                                                                  return (
                                                                    <tr key={j}>
                                                                      <td>
                                                                        {field.field +
                                                                          " " +
                                                                          k}
                                                                      </td>
                                                                      <td>
                                                                        {field
                                                                          .fromValue[
                                                                          k
                                                                        ] ||
                                                                          "-"}
                                                                      </td>
                                                                      <td>
                                                                        {field
                                                                          .toValue[
                                                                          k
                                                                        ] ||
                                                                          "--"}
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                }
                                                              )}
                                                            </>
                                                          );

                                                          console.log(u_keys);

                                                          return (
                                                            <tr key={j}>
                                                              <td>
                                                                {contact_fields[
                                                                  field.field
                                                                ] ||
                                                                  field.field}
                                                              </td>
                                                              <td>
                                                                {/* {
                                                                  field.fromValue
                                                                } */}
                                                                obj
                                                              </td>
                                                              <td>
                                                                {/* {field.toValue} */}
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                        return (
                                                          <tr key={j}>
                                                            <td>
                                                              {
                                                                contact_fields[
                                                                  field.field
                                                                ]
                                                              }
                                                            </td>
                                                            <td>
                                                              {field.fromValue}
                                                            </td>
                                                            <td>
                                                              {field.toValue}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.table_container}>
                <table className={styles.responsive_table}>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={checkAll}
                          checked={
                            contacts.filter((val: any) => val.actions.select)
                              .length === contacts.length
                          }
                        />
                      </th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Contact Owner</th>
                      <th>Primary company</th>
                      <th>Last Activity DATE</th>
                      <th>Lead Status</th>
                      <th>Create Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts.map((val: any, index: any) => {
                      return (
                        <SingleRow
                          data={val}
                          key={index}
                          changeSelect={changeSelect}
                          disableForAction={disableForAction}
                          updatedContacts={updatedContacts}
                          setUpdatedContacts={setUpdatedContacts}
                          subAdmins={subAdmins}
                          onRowClick={() => {
                            setInView(val);
                            setSecondaryView(true);
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const date_values = [
  {
    title: "Today",
    description: "Today from midnight until the current time",
    value: "today",
  },
  {
    title: "Yesterday",
    description: "The previous 24 hour day",
    value: "yesterday",
  },
  {
    title: "Tomorrow",
    description: "The next 24 hour day",
  },
  {
    title: "This week",
    description: "The current calendar week up to now",
    value: "this_week",
  },
  {
    title: "This week so far",
    description: "The current calendar week up to now",
    value: "this_week_so_far",
  },
  {
    title: "Last week",
    description: "The previous calendar week",
    value: "last_week",
  },
  {
    title: "Next week",
    description: "The next calendar week",
    value: "next_week",
  },
  {
    title: "This month",
    description: "the current calendar month",
    value: "this_month",
  },
  {
    title: "This month so far",
    description: "The current calendar month up to now",
    value: "this_month_so_far",
  },
  {
    title: "Last month",
    description: "The previous calendar month",
    value: "last_month",
  },
  {
    title: "Next month",
    description: "The next calendar month",
    value: "next_month",
  },
  {
    title: "This quarter",
    description: "This current quarter",
    value: "this_quarter",
  },
  {
    title: "This fiscal quarter",
    description: "The current fiscal quarter",
    value: "this_fiscal_quarter",
  },
  {
    title: "This quarter so far",
    description: "The current quarter up to now",
    value: "this_quarter_so_far",
  },
  {
    title: "This fiscal quarter so far",
    description: "The current fiscal quarter up to now",
    value: "this_fiscal_quarter_so_far",
  },
  {
    title: "Last quarter",
    description: "The previous full quarter",
    value: "last_quarter",
  },
  {
    title: "Last fiscal quarter",
    description: "The previous full fiscal quarter",
    value: "last_fiscal_quarter",
  },
  {
    title: "Next quarter",
    description: "The next full quarter",
    value: "next_quarter",
  },
  {
    title: "Next fiscal quarter",
    description: "The next full fiscal quarter",
    value: "next_fiscal_quarter",
  },
  {
    title: "This year",
    description: "The current calendar year",
    value: "this_year",
  },
  {
    title: "This fiscal year",
    description: "The current fiscal year",
    value: "this_fiscal_year",
  },
  {
    title: "This year so far",
    description: "The current calendar year up to now",
    value: "this_year_so_far",
  },
  {
    title: "This fiscal year so far",
    description: "The current fiscal year up to now",
    value: "this_fiscal_year_so_far",
  },
  {
    title: "Last year",
    description: "The previous calendar year",
    value: "last_year",
  },
  {
    title: "Last fiscal year",
    description: "The previous fiscal year",
    value: "last_fiscal_year",
  },
  {
    title: "Next year",
    description: "The next calendar year",
    value: "next_year",
  },
  {
    title: "Next fiscal year",
    description: "The next fiscal year",
    value: "next_fiscal_year",
  },
  {
    title: "Last 7 days",
    description: "The previous 7 days before today",
    value: "last_7_days",
  },
  {
    title: "Last 14 days",
    description: "The previous 14 days before today",
    value: "last_14_days",
  },
  {
    title: "Last 30 days",
    description: "The previous 30 days before today",
    value: "last_30_days",
  },
  {
    title: "Last 60 days",
    description: "The previous 60 days",
    value: "last_60_days",
  },
  {
    title: "Last 90 days",
    description: "The previous 90 days",
    value: "last_90_days",
  },
  {
    title: "Last 180 days",
    description: "The previous 180 days",
    value: "last_180_days",
  },
  {
    title: "Last 365 days",
    description: "The previous 365 days",
    value: "last_365_days",
  },
];

const lead_values = [
  { title: "New", value: "new" },
  { title: "Open", value: "open" },
  { title: "In Progress", value: "in_progress" },
  { title: "Marketing Qualified Lead", value: "mql" },
  { title: "Sales Qualified Lead", value: "sql" },
  { title: "Open Deal", value: "open_deal" },
  { title: "Unqualified", value: "unqualified" },
  { title: "Attempted to Contact", value: "attempted_to_contact" },
  { title: "Connected", value: "connected" },
  { title: "Bad Timing", value: "bad_timing" },
  { title: "Unassigned", value: "unassigned" },
];

const contact_fields: any = {
  firstName: "First Name",
  lastName: "Last Name",
  companyName: "Company Name",
  companyWebsite: "Company Website",
  companyHQ: "Company HQ",
  mobileNumber: "Mobile Number",
  altMobileNumber: "Alt. Mobile Number",
  personalEmail: "Email",
  cooperateEmail: "Cooperate Email",
  country: "Country",
  state: "State",
  city: "City",
  address: "Address",
  pincode: "Pincode",
  remarks: "Remarks",
  status: "Status",
};

export default Leads;
