import React from "react";
import styles from "./dropBtn.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useReactToPrint } from "react-to-print";

type Props = {
  text: string;
  type?: string;
  isIcon: boolean;
  refr?: boolean;
  isDropDownIcon?: boolean;
  icon?: any;
  drop_list: any[];
  on_click: any;
  open_drop_down_on_action_click?: boolean;
};

const DropBtn = (props: Props) => {
  const [drop, setDrop] = React.useState(false);
  // console.log(props.refr)


  const handlePrint = useReactToPrint({
     //@ts-ignore
     content: () => props?.refr?.current,
   });
  console.log(props?.isDropDownIcon)
  return (
    <div className={`${styles.container} ${props.type==="transparent"? styles.transparent:""}`}>
      <div
        className={styles.main}
        onClick={(e: any) => {
          e.stopPropagation();
          console.log(props?.open_drop_down_on_action_click);
          if (props?.open_drop_down_on_action_click) {
            setDrop((prev) => !prev);
          } else {
            props.on_click(props.drop_list[0].id);
          }
        }}
      >
        {props?.isIcon && <FontAwesomeIcon icon={props.icon} />}
        {props.text}
      </div>
      <div
        className={styles.drop_part}
        onClick={(e: any) => {
          e.stopPropagation();
          setDrop((prev) => !prev);
        }}
      >
        {props?.isDropDownIcon===undefined && <FontAwesomeIcon icon={faCaretDown} />}
      </div>
      {drop && (
        <ul className={styles.drop_container}>
          {props.drop_list.map((val, index) => {
           
            return (
              <li
                key={index}
                className={styles.drop_item}
                id={val.id}
                onClick={(e: any) => {
                ( val.id === "print" && localStorage.getItem("printingOption"))
                   ? handlePrint()
                   : props.on_click(e.target.id);
                  setDrop((prev) => val.id==="print"? !prev: prev);
                }}
              >
                {val?.icon && <FontAwesomeIcon icon={val.icon} />}
                {val.title}
              </li>
            );
          })}
        </ul>
      )}
      {drop && (
        <div
          className={styles.cover}
          onClick={() => {
            setDrop((prev) => !prev);
          }}
        ></div>
      )}
    </div>
  );
};

export default DropBtn;
