import axios from "axios";

type props = {
  url: string;
  method: string;
  header?: boolean;
  data?: any;
  type:
    | "blogs"
    | "admins"
    | ""
    | "casestudy"
    | "discounts"
    | "productmedia"
    | "leads";
  identifier?: boolean;
};

let url = "https://backend.cftcommerce.com/api/";
// let url = "http://localhost:8080/api/";

const callApi = (props: props) => {
  let data = props.data;

  if (props.identifier) {
    data.identifier = localStorage.getItem("identifier");
  }

  return axios({
    url: url + props.type + "/" + props.url,
    method: props.method,
    data: data,
    headers: props?.header
      ? { Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}` }
      : {},
  });
};

export default callApi;
