import AWS from "aws-sdk";
import compressImage from "./convertImg";
import { toast } from "react-hot-toast";

export const checkForImg = (ext: any) => {
  if (
    ext === "jpg" ||
    ext === "jpeg" ||
    ext === "png" ||
    ext === "gif" ||
    ext === "webp" ||
    ext === "heic"
  ) {
    return true;
  } else {
    return false;
  }
};

const UploadImage = ({ file, name }: { file: any; name: any }) => {
  return new Promise((resolve, reject) => {
    compressImage(file, 0.1)
      .then((val: any) => {
        console.log("compressed value : ", val);
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_ACCESS_KEY_SECRET,
          region: process.env.REACT_APP_APP_REGION,
        });

        const s3 = new AWS.S3();

        // this will dynamically create folder name based on the identifier
        const folder_name = localStorage.getItem("identifier")?.includes("bion")
          ? "bion/"
          : localStorage.getItem("identifier")?.includes("whitetales")
          ? "whitetales/"
          : localStorage.getItem("identifier")?.split(".").join("") + "/";

        const params: any = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: folder_name + name,
          Body: val,
          ACL: "public-read",
          Expires: 3600,
        };

        s3.putObject(params)
          .promise()
          .then(() => {
            // toast.success("image uploaded.")
            resolve(true);
          })
          .catch((err: any) => {
            console.log(err);
            toast.error("something went wrong in images part.");
          });
      })
      .catch(() => {
        toast.error("something went wrong while compressing image.");
      });
  });
};

export const folder_name = () => {
  return localStorage.getItem("identifier")?.includes("bion")
    ? "bion/"
    : localStorage.getItem("identifier")?.includes("whitetales")
    ? "whitetales/"
    : localStorage.getItem("identifier")?.split(".").join("") + "/";
};

export default UploadImage;
