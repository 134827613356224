import { useEffect, useState } from "react";
import { blog_access_keys } from "../../Utils/Data";
import withRouter from "../../Components/withRouter";
import WithTableView from "../../GenericPages/WithTableView";
import axios from "axios";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import withContext from "../../Store/withContext";
import Pagination from "../../Components/Table/Pagination";
import Modal from "../../Components/Modal";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import DraggableTable from "../../Components/DraggableTable";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import { useLocation, useNavigate } from "react-router-dom";
import langObj from "../../Utils/lang";
import { useLangContext } from "../../Store/langContext";
import { useAdminContext } from "../../Store/adminContext";
import ContentOuterHead from "../../Components/Content/contentOuterHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styles from "./blogs.module.css";

interface Blog {
  id: string;
  priority: number;
  blogTitle: string;
  category: string;
  description: string;
  author: string;
  image: string;
  slug: string;
  tags: string;
  buttonOneUrl: string;
  buttonTwoUrl: string;
  status: string;
}

const Blogs = ({ router }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [modal, setModal] = useState(false);
  const [inFocus, setInFocus] = useState("");
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const { admin }: any = useAdminContext();

  const { lang }: any = useLangContext();

  useEffect(() => {
    handlePage();
  }, [location.search, searchTerm]);

  const handlePage = () => {
    let search = new URLSearchParams(location.search);
    getData();
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    console.log("search", searchTerm);

    axios({
      method: "get",
      url: `https://backend.cftcommerce.com/api/blogs?identifier=${localStorage.getItem(
        "identifier"
      )}&page=${Number(search.get("page")) || 1}&limit=${
        Number(search.get("limit")) || 15
      }&${searchTerm ? `search=${searchTerm}` : ""}`,
    })
      .then((res: any) => {
        let temp = res?.data?.blogs.map((val: any) => ({
          ...val,
          id: val._id,
        }));
        let temp_blogs = temp.sort((a: any, b: any) => a.priority - b.priority);

        setBlogs(temp_blogs);
        setLoading(false);
        setTotal(res.data?.totalBlogsCount);
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleSwap = (id1: any, id2: any, priority1: any, priority2: any) => {
    if (admin.role === "subadmin" && !admin.blog_access.write) {
      toast.error("You don't have permission to perform this action.");
      return;
    }
    let temp: any = [...blogs];
    let newIndex1 = temp.findIndex((val: any) => val.id === id1);
    let newIndex2 = temp.findIndex((val: any) => val.id === id2);

    setIndex1(newIndex1);
    setIndex2(newIndex2);

    if (priority1 > priority2) {
      temp[newIndex1].priority = priority2;
      for (let i = newIndex2; i < newIndex1; i++) {
        temp[i].priority = temp[i].priority + 1;
      }
    }

    if (priority2 > priority1) {
      temp[newIndex1].priority = priority2;
      for (let i = newIndex1 + 1; i < newIndex2 + 1; i++) {
        temp[i].priority = temp[i].priority - 1;
      }
    }

    setBlogs(temp.sort((a: any, b: any) => a.priority - b.priority));
    setLoading(true);
    updateForSwap();
  };

  const updateForSwap = async () => {
    if (index1 > index2) {
      for (let i = index2; i < index1 + 1; i++) {
        await callApi({
          method: "patch",
          data: { priority: blogs[i]?.priority },
          url: blogs[i]?.id,
          type: "blogs",
          header: true,
        });
      }
    }

    if (index2 > index1) {
      for (let i = index1; i < index2 + 1; i++) {
        await callApi({
          url: blogs[i].id,
          data: { priority: blogs[i].priority },
          method: "patch",
          type: "blogs",
          header: true,
          identifier: true,
        });
      }
    }

    getData();
  };

  const handleDelete = () => {
    setModal(false);
    const promise = new Promise((resolve, reject) => {
      callApi({
        method: "delete",
        url: inFocus,
        data: {},
        header: true,
        type: "blogs",
      })
        .then((res) => {
          setInFocus("");
          resolve(true);
        })
        .catch((err) => {
          reject();
        });
    });

    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then(() => {
        getData();
      });
  };

  const handleAction = (val: string, id: string) => {
    if (val === "delete") {
      setModal(true);
      setInFocus(id);
    }

    if (val === "archive" || val === "unarchive") {
      const status = val === "archive" ? "draft" : "live";

      const promise = new Promise((resolve, reject) => {
        callApi({
          method: "patch",
          data: { status },
          url: id,
          type: "blogs",
          header: true,
        })
          .then((res) => {
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      });

      const actionText = val === "archive" ? "Archiving" : "UnArchiving";

      toast
        .promise(
          promise,
          {
            loading: `${actionText}...`,
            success: `${actionText} Successfully.`,
            error: `Error when ${actionText.toLowerCase()} the entry.`,
          },
          { success: { duration: 2000 }, error: { duration: 2000 } }
        )
        .then(() => {
          getData();
        });
    }
  };

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => setModal(false)}
        onBtn2Click={handleDelete}
        state={modal}
      />
      <Content>
        <ContentHead
          title={langObj.blog[lang]}
          showBtn1={admin.role === "subadmin" ? admin.blog_access.write : true}
          btn1Name="btn1"
          btn1Text={langObj["New Blog"][lang]}
          btn1type="filled"
          onClickHandler={() => {
            router.navigate("/blog/create_blog");
          }}
          customElement={
            <div className={styles.search_section}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder="Search blogs ..."
                value={searchTerm}
                onChange={handleChange}
              />
            </div>
          }
        />
        <ContentBody
          style={{ padding: 0 }}
          isPagination={true}
          pageNo={Number(new URLSearchParams(location.search).get("page")) || 1}
          changePage={(val: number) => {
            let search = new URLSearchParams(location.search);

            search.set("page", String(val));

            navigate(`/?${String(search)}`);
          }}
          total={total}
        >
          {loading ? (
            <TableLoading head={["S.No.", "Blog Title", "Status", ""]} />
          ) : (
            <DraggableTable
              head={["S.No.", "Blog Title", "Status", ""]}
              body={[...blogs]}
              body_keys={
                admin.role === "subadmin"
                  ? [
                      "sno",
                      "title",
                      "status",
                      admin.blog_access.write
                        ? admin.blog_access.delete
                          ? "action"
                          : "write"
                        : "show",
                    ]
                  : ["sno", "title", "status", "action"]
              }
              onSwap={handleSwap}
              onDelete={(val: any, id: any) => {
                setInFocus(id);
                handleAction("delete", id);
              }}
              onEdit={(id: any) => {
                router.navigate("/blog/update_blog/" + id);
              }}
              pageNo={pageNo - 1}
            />
          )}
        </ContentBody>
      </Content>
    </>
  );
};

export default withRouter(withContext(Blogs));
