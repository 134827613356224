import React from "react";
import AddCompliance from "../Add/index";

class UpdateCompliance extends React.Component {
  render(): React.ReactNode {
    return (
      <>
        <AddCompliance />
      </>
    );
  }
}

export default UpdateCompliance;
