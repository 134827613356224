import { checkForImg } from "./UploadImage";

export const getFileName = (file: any) => {
  const time = new Date();
  const fileName =
    file.name?.substring(0, file.name.lastIndexOf(".")) || file.name;
  let newName = `${fileName}_${time.getHours()}${time.getMinutes()}${time.getSeconds()}${time.getMilliseconds()}`;

  if(checkForImg(file.name.split(".")[file.name.split(".").length - 1])){
    newName += ".webp";
    return newName;
  }

  newName += `.${file.name.split(".")[file.name.split(".").length - 1]}`;
  return newName;
};
