import React, { useRef } from 'react'
import styles from './labelInput.module.css'
import { useReactToPrint } from 'react-to-print';
import Label from './label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const LabelInput = (props: any) => {
    
 

  return (
    <div className={styles.labaelInput_box}>
      <div className={styles.label_inner_box}>
        <span>
          <label>Weight : </label>
          <input
            type="text"
            placeholder="weight"
            name="weight"
            id=""
            onChange={props.handleChange}
          />
        </span>
        <span>
          <label>SO : </label>
          <input
            type="text"
            placeholder="SO"
            name="soNumber"
            id=""
            onChange={props.handleChange}
          />
        </span>
        <span>
          <label>Tracking ID : </label>
          <input
            type="text"
            placeholder="Tracking ID"
            name="trackingId"
            id=""
            onChange={props.handleChange}
          />
        </span>
        <span>
          <label>Delivery partner name : </label>
          <input
            type="text"
            placeholder="weight"
            name="carrierName"
            id=""
            onChange={props.handleChange}
          />
        </span>

        <span>
          <label> Delivery Instructions</label>
          <select onChange={props.handleSelect}>
            <option value="" selected disabled>
              Select an Option
            </option>
            {props.options?.map((option: any) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </select>
        </span>
        <ul className={styles.selectedOptions}>
          {props?.selectedOptions.map((option: any) => (
            <div style={{display:"flex",gap:"10px"}}>
              <li key={option.value} className={styles.selectedOption}>
                {option.label}
              </li>
              <button
                className={styles.removeButton}
                onClick={() => props?.handleRemove(option.value)}
              >
                <FontAwesomeIcon icon={faXmark} color={"red"} size={"lg"}/>
              </button>
            </div>
          ))}
        </ul>
        <button className={styles.printBtn} onClick={props.handlePrint}>
          Print
        </button>
      </div>
    </div>
  );
}

export default LabelInput;