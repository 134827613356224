import React from "react";
import ServiceOrderDetails from "./serviceOrders/ServiceOrderDetails";
import ProductOrderDetails from "./productOrders/ProductOrderDetails";

class OrderDetails extends React.Component<any, any> {
  render(): React.ReactNode {
    return (
      <>
        {localStorage.getItem("ecommerce") === "true" ? (
          <ProductOrderDetails />
        ) : (
          <ServiceOrderDetails />
        )}
      </>
    );
  }
}

export default OrderDetails;
