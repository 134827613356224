import React from "react";
import SingleProduct from "./SingleProduct";
import styles from "./topSellingProducts.module.css";
import HorizontalBarChart from "../../Charts/HorizontalBarChart";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

type Props = {
  products: { name: string; quantity: number }[];
  header: string;
  subHeader: string;
};

const TopSellingProducts = (props: Props) => {
  const data = {
    labels: props.products
      ?.slice(0, 5)
      .map((data) => `${data.name.substring(0, 16)}...`),
    datasets: [
      {
        label: `${props.subHeader}`,
        data: props.products?.slice(0, 5).map((data) => data.quantity),
        backgroundColor: "#397EE6",
        barThickness: 20,
      },
    ],
  };
  return (
    <>
      <h3 className={styles.productH1}>{props.header}</h3>
      <HorizontalBarChart data={data} />
      <div className={styles.btnContainer}>
        <Link to={"/analytics/topSellingProducts"} className={styles.btn}>
          See More
          <FaArrowRight size={16} />
        </Link>
      </div>
    </>
  );
};

export default TopSellingProducts;
