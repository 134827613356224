import React, { useState } from 'react'
import styles from './filter.module.css'
type MostViewedProducts = {
    title: string;
    impression: number;
    category:string;
  }[];
type Props = {
    selectedCategory:string;
    setSelectedCategory:React.Dispatch<React.SetStateAction<string>>;
    categories:string[]
}

const FilterData = ({selectedCategory,setSelectedCategory,categories}: Props) => {
  return (
    <form className={styles.filterForm}>
        <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className={styles.select} name="" id="">
            <option value="">All</option>
            {categories.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </select>
    </form>
  )
}

export default FilterData