import React, { useEffect, useState } from "react";
import ContentHead from "../../Components/Content/ContentHead";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import DraggableTable from "../../Components/DraggableTable";

import styles from "./content.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "../../Components/Modal";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import { useAdminContext } from "../../Store/adminContext";
import ContentOuterHead from "../../Components/Content/contentOuterHead";

const ContentPage = () => {
  const navigate = useNavigate();
  const { admin }: any = useAdminContext();

  const [data, setData] = useState([]);
  const [on, setOn] = useState("hero");

  const [loading, setLoading] = useState(false);

  const [hero, setHero]: any = useState([]);
  const [banner1, setBanner1]: any = useState([]);
  const [banner2, setBanner2]: any = useState([]);

  const [modal, setModal]: any = useState(false);
  const [inFocus, setInFocus]: any = useState(false);

  let index1 = 0,
    index2 = 0;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    axios({
      url: `https://backend.cftcommerce.com/api/contents/?identifier=${localStorage.getItem(
        "identifier",
      )}`,
      method: "GET",
    })
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.contents[0]?.hero);
        setHero(
          [...res.data.contents[0]?.hero].sort(
            (a, b) => a.priority - b.priority,
          ),
        );
        setBanner1([...res.data.contents[0]?.banner1]);
        setBanner2([...res.data.contents[0]?.banner2]);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleHeroSwap = (
    id1: any,
    id2: any,
    priority1: any,
    priority2: any,
  ) => {
    let temp: any = [...hero];

    let i1 = temp.findIndex((val: any) => val.id === id1);

    let i2 = temp.findIndex((val: any) => val.id === id2);

    console.log("index of 1st : ", i1);
    console.log("index of second : ", i2);

    index1 = i1;
    index2 = i2;

    if (priority1 > priority2) {
      temp[index1].priority = priority2;
      // means picked from bottom to the top position
      for (let i = index2; i < index1; i++) {
        temp[i].priority = temp[i].priority + 1;
      }
    }

    if (priority2 > priority1) {
      // means picked from the top to bottom position
      temp[index1].priority = priority2;

      for (let i = index1 + 1; i < index2 + 1; i++) {
        temp[i].priority = temp[i].priority - 1;
      }
    }

    setHero(temp.sort((a: any, b: any) => a.priority - b.priority));
    updateHeroSwap(temp.sort((a: any, b: any) => a.priority - b.priority));
  };

  const updateHeroSwap = (val: any) => {
    let data = {
      identifier: localStorage.getItem("identifier"),
      hero: [...val],
    };

    setLoading(true);

    axios({
      url: "https://backend.cftcommerce.com/api/contents",
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        console.log(res);
        toast.success("Priority updated successfully.");

        getData();
      })
      .catch((err: any) => {
        toast.error("Something went wrong while updating priority.");
        console.log(err);
        getData();
      });
  };

  const handleBanner2Swap = (
    id1: any,
    id2: any,
    priority1: any,
    priority2: any,
  ) => {
    let temp: any = [...banner2];

    let i1 = temp.findIndex((val: any) => val.id === id1);

    let i2 = temp.findIndex((val: any) => val.id === id2);

    console.log("index of 1st : ", i1);
    console.log("index of second : ", i2);

    index1 = i1;
    index2 = i2;

    if (priority1 > priority2) {
      temp[index1].priority = priority2;
      // means picked from bottom to the top position
      for (let i = index2; i < index1; i++) {
        temp[i].priority = temp[i].priority + 1;
      }
    }

    if (priority2 > priority1) {
      // means picked from the top to bottom position
      temp[index1].priority = priority2;

      for (let i = index1 + 1; i < index2 + 1; i++) {
        temp[i].priority = temp[i].priority - 1;
      }
    }

    setBanner2(temp.sort((a: any, b: any) => a.priority - b.priority));
    updateBanner2Swap(temp.sort((a: any, b: any) => a.priority - b.priority));
  };

  const updateBanner2Swap = (val: any) => {
    let data = {
      identifier: localStorage.getItem("identifier"),
      banner2: [...val],
    };

    setLoading(true);

    axios({
      url: "https://backend.cftcommerce.com/api/contents",
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        console.log(res);
        toast.success("Priority updated successfully.");
        getData();
      })
      .catch((err: any) => {
        toast.error("Something went wrong while updating priority.");
        console.log(err);
        getData();
      });
  };

  const handleBanner1Swap = (
    id1: any,
    id2: any,
    priority1: any,
    priority2: any,
  ) => {
    let temp: any = [...banner1];

    let i1 = temp.findIndex((val: any) => val.id === id1);

    let i2 = temp.findIndex((val: any) => val.id === id2);

    console.log("index of 1st : ", i1);
    console.log("index of second : ", i2);

    index1 = i1;
    index2 = i2;

    if (priority1 > priority2) {
      temp[index1].priority = priority2;
      // means picked from bottom to the top position
      for (let i = index2; i < index1; i++) {
        temp[i].priority = temp[i].priority + 1;
      }
    }

    if (priority2 > priority1) {
      // means picked from the top to bottom position
      temp[index1].priority = priority2;

      for (let i = index1 + 1; i < index2 + 1; i++) {
        temp[i].priority = temp[i].priority - 1;
      }
    }

    setBanner1(temp.sort((a: any, b: any) => a.priority - b.priority));
    updateBanner1Swap(temp.sort((a: any, b: any) => a.priority - b.priority));
  };

  const updateBanner1Swap = (val: any) => {
    let data = {
      identifier: localStorage.getItem("identifier"),
      banner1: [...val],
    };

    setLoading(true);

    axios({
      url: "https://backend.cftcommerce.com/api/contents",
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        console.log(res);
        toast.success("Priority updated successfully.");
        getData();
      })
      .catch((err: any) => {
        toast.error("Something went wrong while updating priority.");
        console.log(err);
        getData();
      });
  };

  const handleDelete = () => {
    setModal(false);
    if (on === "hero") {
      let temp = [...hero];
      let l_hero = temp.filter((val) => val._id !== inFocus);

      const promise = new Promise((resolve, reject) => {
        axios({
          url: "https://backend.cftcommerce.com/api/contents",
          method: "POST",
          data: {
            identifier: localStorage.getItem("identifier"),
            hero: l_hero,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            setInFocus("");
            resolve(true);
          })
          .catch((err: any) => {
            reject();
          });
      });

      toast
        .promise(promise, {
          loading: "Deleting....",
          success: "Deleted Successfully",
          error: "Error when deleting the entry.",
        })
        .then(() => {
          getData();
        });
    }

    if (on === "b1") {
      let temp = [...banner1];
      let l_banner1 = temp.filter((val) => val._id !== inFocus);

      const promise = new Promise((resolve, reject) => {
        axios({
          url: "https://backend.cftcommerce.com/api/contents",
          method: "POST",
          data: {
            identifier: localStorage.getItem("identifier"),
            banner1: l_banner1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            setInFocus("");
            resolve(true);
          })
          .catch((err: any) => {
            reject();
          });
      });

      toast
        .promise(promise, {
          loading: "Deleting....",
          success: "Deleted Successfully",
          error: "Error when deleting the entry.",
        })
        .then(() => {
          getData();
        });
    }

    if (on === "b2") {
      let temp = [...banner2];
      let l_banner2 = temp.filter((val) => val._id !== inFocus);

      const promise = new Promise((resolve, reject) => {
        axios({
          url: "https://backend.cftcommerce.com/api/contents",
          method: "POST",
          data: {
            identifier: localStorage.getItem("identifier"),
            banner2: l_banner2,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            setInFocus("");
            resolve(true);
          })
          .catch((err: any) => {
            reject();
          });
      });

      toast
        .promise(promise, {
          loading: "Deleting....",
          success: "Deleted Successfully",
          error: "Error when deleting the entry.",
        })
        .then(() => {
          getData();
        });
    }
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handleDelete}
        state={modal}
      />
      <Content>
        <ContentHead title={"Content"} />
        <ContentBody>
          <div className={styles.tabs}>
            <div
              className={on === "hero" ? styles.active : ""}
              onClick={() => setOn("hero")}
            >
              Hero Carousel
            </div>
            <div
              className={on === "b1" ? styles.active : ""}
              onClick={() => setOn("b1")}
            >
              Banner 1
            </div>
            <div
              className={on === "b2" ? styles.active : ""}
              onClick={() => setOn("b2")}
            >
              Banner 2
            </div>
            {isUserAllowedToEdit(admin) && (
              <span
                onClick={() => {
                  if (on === "hero") {
                    navigate("/content/add");
                  } else if (on === "b1") {
                    navigate("/content/banner1/add");
                  } else if (on === "b2") {
                    navigate("/content/banner2/add");
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                Add
              </span>
            )}
          </div>
          {on === "hero" && (
            <div className="card">
              <h3 className={styles.heading}>Hero Carousel </h3>
              <div style={{ paddingTop: "20px" }}>
                {loading ? (
                  <TableLoading head={["S.No.", "Title", "Click Count", ""]} />
                ) : (
                  <DraggableTable
                    head={["S.No.", "Title", "Click Count", ""]}
                    body={[...hero]}
                    body_keys={contentKey(admin)}
                    onSwap={handleHeroSwap}
                    onDelete={(val: any, id: any) => {
                      setModal(true);
                      setInFocus(id);
                    }}
                    onEdit={(id: any) => {
                      navigate("/content/update/" + id);
                    }}
                    pageNo={0}
                  />
                )}
              </div>
            </div>
          )}
          {on === "b1" && (
            <div className="card">
              <h3 className={styles.heading}>Banner 1 </h3>
              <div style={{ paddingTop: "20px" }}>
                {loading ? (
                  <TableLoading head={["S.No.", "Title", "Click Count", ""]} />
                ) : (
                  <DraggableTable
                    head={["S.No.", "Title", "Click Count", ""]}
                    body={[...banner1]}
                    body_keys={contentKey(admin)}
                    onSwap={handleBanner1Swap}
                    onDelete={(val: any, id: any) => {
                      setModal(true);
                      setInFocus(id);
                    }}
                    onEdit={(id: any) => {
                      navigate("/content/banner1/update/" + id);
                    }}
                    pageNo={0}
                  />
                )}
              </div>
            </div>
          )}
          {on === "b2" && (
            <div className="card">
              <h3 className={styles.heading}>Banner 2 </h3>
              <div style={{ paddingTop: "20px" }}>
                {loading ? (
                  <TableLoading head={["S.No.", "Title", "Click Count", ""]} />
                ) : (
                  <DraggableTable
                    head={["S.No.", "Title", "Click Count", ""]}
                    body={[...banner2]}
                    body_keys={contentKey(admin)}
                    onSwap={handleBanner2Swap}
                    onDelete={(val: any, id: any) => {
                      setModal(true);
                      setInFocus(id);
                    }}
                    onEdit={(id: any) => {
                      navigate("/content/banner2/update/" + id);
                    }}
                    pageNo={0}
                  />
                )}
              </div>
            </div>
          )}
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.content_access.write) return true;

  return false;
};

const contentKey = (admin: any) => {
  const base = ["sno", "title", "clickCount"];

  if (admin.role !== "subadmin") return [...base, "action"];

  if (admin.role === "subadmin") {
    if (admin.content_access.delete) {
      return [...base, "action"];
    }
    if (admin.content_access.write) {
      return [...base, "write"];
    }
  }

  return [...base, "show"];
};

export default ContentPage;
