import React, { useRef } from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import styles from "../../Styles/orderDetails.module.css";
import InputField from "../../Components/InputField";
import withRouter from "../../Components/withRouter";
import axios from "axios";
import { toast } from "react-hot-toast";

import { ReactComponent as Arrow } from "../../Assets/icons/arrow-left.svg";
import { folder_name } from "../../Utils/UploadImage";

type Add = {
  name: string;
  addLineOne: string;
  addLineTwo: string;
  landmark: string;
  city: string;
  state: string;
  pincode: string;
};

type User = {
  billingAddress: Add[];
  shippingAddress: Add[];
};

type Props = {
  key: string;
  data: any;
  loading: boolean;
  fulfilmentStatus: any;
  paymentStatus: any;
  returnStatus: string;
  refundStatus: string;
  cancelModal: boolean;
  statusUpdateModal: boolean;
  returnStatusModal: boolean;
  refundStatusModal: boolean;
  labelGeneratorModal: boolean;
  printOptionModal: boolean;
  cancelReturnStatus: string;
  cancelRefundStatus: string;
  admin: any;
  user: User;
  product: any;
  componentRef: any;
};

type statusUpdate = {
  fulfilmentStatus?: any;
  refundStatus?: string;
  returnStatus?: string;
  paymentStatus?: string;
};

class CartDetails extends React.Component<any, Props> {
  state: any = {
    key: "",
    data: {},
    loading: false,
    fulfilmentStatus: "",
    refundStatus: "NA",
    returnStatus: "NA",
    paymentStatus: "",
    cancelModal: false,
    statusUpdateModal: false,
    returnStatusModal: false,
    refundStatusModal: false,
    labelGeneratorModal: false,
    printOptionModal: false,
    cancelReturnStatus: "",
    cancelRefundStatus: "",
    admin: {},
    user: {},
    product: [],
    componentRef: "",
    targetRef: "",
  };
  componentRef: any;
  targetRef: any;
  thermalInvoiceRef: any;

  componentDidMount() {
    this.getOrderDetails();
    this.getKey();
  }

  getKey = () => {
    axios({
      url: "https://backend.cftcommerce.com/api/users/verifyuser",
      method: "POST",
      data: {
        identifier: localStorage.getItem("identifier"),
        consent: true,
      },
    })
      .then((res) => {
        this.setState({ key: res?.data?.key });

        if (res?.data?.key) {
          this.getUserDetailsFromRetaino(res?.data?.key);
        }
      })
      .catch((error) => {
        console.log("error response ::: ", error);
      });
  };

  getUserDetailsFromRetaino = (key: string) => {
    axios({
      url: "https://backend.cftcommerce.com/api/users/" + key,
      method: "GET",
      // headers: {
      //   Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      // },
    })
      .then((res) => {})
      .catch((error) => {
        console.log("error response ::: ", error);
      });
  };

  getOrderDetails = () => {
    const { id } = this.props.router.params;
    console.log(id);

    axios({
      method: "get",
      url: "https://backend.cftcommerce.com/api/orders/" + id,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    }).then((res: any) => {
      // console.log(res);
      console.log(res.data);

      if (Array.isArray(res.data?.order)) {
        axios({
          method: "GET",
          url:
            "https://backend.cftcommerce.com/api/orders/increasing?identifier=" +
            localStorage.getItem("identifier") +
            "&page=1&limit=1000",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((response: any) => {
            console.log(response);
            let data = response.data.orders.filter(
              (val: any) => val._id === id
            )[0];

            this.setState({
              data: data,
              fulfilmentStatus: data.fulfilmentStatus,
              refundStatus: data?.refundStatus || "NA",
              returnStatus: data?.returnStatus || "NA",
              paymentStatus: data?.paymentStatus,
            });
            console.log(data);
            this.getCustomerDetails(
              data.customerId,
              data?.serviceId ? data?.serviceId : data?.productId,
              data?.serviceId ? "service" : "product"
            );
          })
          .catch((error: any) => {
            console.log(error);
          });
      } else {
        let data = res.data.order;
        console.log(res.data);
        console.log(data);

        this.setState({
          data: data,
          fulfilmentStatus: data.fulfilmentStatus,
          product: data.products,
          refundStatus: data?.refundStatus,
          returnStatus: data?.returnStatus,
          paymentStatus: data?.paymentStatus,
        });

        this.getCustomerDetails(
          data.customerId,
          data?.serviceId ? data?.serviceId : data?.productId,
          data?.serviceId ? "service" : "product"
        );
      }
    });
  };

  getCustomerDetails = (id: string, ID: string, type: string) => {
    console.log(id, ID, type);
    axios({
      url: "https://backend.retaino.in/api/users/getuserbyid/" + id,
      method: "get",
    })
      .then((res: any) => {
        console.log(res.data.user);

        this.setState({ user: res.data.user });
        this.getDetails(type, ID);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  getDetails = (type: string, ID: string) => {
    axios({
      url: "https://backend.cftcommerce.com/api/service/" + ID,
      method: "GET",
    })
      .then((res: any) => {
        console.log(res.data.service);
        this.setState({ product: res.data.service, loading: false });
      })
      .catch((err: any) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  updateOrderStatus = (status: statusUpdate): void => {
    // console.log(status);
    let temp = {
      ...status,
      identifier: localStorage.getItem("identifier"),
      date: new Date(),
    };

    axios({
      url:
        "https://backend.cftcommerce.com/api/orders/product/" +
        this.props.router.params.id,
      method: "patch",
      data: temp,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        // console.log(res);
        if (res.data.message === "updated successfully") {
          toast.success("Order status updated successfully.");
          // this.props.router.navigate("/orders");
          // this.props.router.refresh()
          this.getOrderDetails();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong.");
      });
  };

  render(): React.ReactNode {
    console.log(this.state?.user);
    return (
      <>
        {this.state.loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        ) : (
          <Content>
            <ContentHead
              title={<>Cart Details {/* @ts-ignore */}</>}
              showBackBtn={true}
              backBtnText={
                <div
                  className={styles.back_btns_container}
                  onClick={(e: any) => {
                    this.props.router.navigate("/orders");
                  }}
                >
                  <Arrow />
                </div>
              }
              backBtnName="backBtn"
              backBtnType="rounded_grey"
              onClickHandler={(event: any) => {
                console.log(event.target.name);
                if (event.target.name === "backBtn") {
                  this.props.router.navigate("/carts");
                }
              }}
            />
            <ContentBody>
              {!this.state.loading && (
                <div className={styles.wrapper}>
                  <div
                    className={styles.container}
                    style={{ border: ".5px solid grey" }}
                  >
                    <h2>Order</h2>

                    <div className={styles.details}>
                      <div className={styles.details_1}>
                        <p>
                          Order Id : {/* @ts-ignore */}
                          <span>{this.state.data.orderRefId}</span>
                        </p>
                        <p>
                          Order Status:
                          <span style={{ textTransform: "capitalize" }}>
                            {/* @ts-ignore */}
                            {this.state.data?.fulfilmentStatus ===
                            "outfordelivery"
                              ? "Out for delivery"
                              : // @ts-ignore
                                this.state.data?.fulfilmentStatus}
                          </span>
                        </p>
                        <p>
                          Mode of Payment: {/* @ts-ignore */}
                          <span>{this.state.data?.modeOfPayment}</span>
                        </p>
                        <p>
                          Payment Status: {/* @ts-ignore */}
                          <span>{this.state.data?.paymentStatus}</span>
                        </p>
                      </div>
                      <div className={styles.details_1}>
                        {/* @ts-ignore */}
                        {this.state.data?.refundStatus && (
                          <p>
                            Refund Status :{" "}
                            <span>
                              {/* @ts-ignore */}
                              {this.state.data?.refundStatus}
                            </span>
                          </p>
                        )}
                        {/* @ts-ignore */}
                        {this.state.data?.returnStatus && (
                          <p>
                            Return Status :{" "}
                            <span>
                              {/* @ts-ignore */}
                              {this.state.data?.returnStatus}
                            </span>
                          </p>
                        )}
                        {/* @ts-ignore */}
                        {this.state.data?.deliveryMethod && (
                          <p>
                            Delivery Method :{" "}
                            <span>
                              {/* @ts-ignore */}
                              {this.state.data?.deliveryMethod}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.container}
                    style={{ border: ".5px solid grey" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2>Product</h2>

                      <p style={{ fontWeight: 600 }}>
                        {/* @ts-ignore */}
                        Total : ₹{this.state.data?.total}
                      </p>
                    </div>

                    {this.state.product.map((p: any, index: any) => {
                      return (
                        <div
                          className={styles.product_details}
                          key={index}
                          style={{
                            border: ".5px solid grey",
                            padding: "20px",
                            borderRadius: "15px",
                          }}
                        >
                          {/* product image */}
                          <div className={styles.product_image}>
                            <img
                              src={
                                process.env.REACT_APP_IMAGE +
                                folder_name() +
                                p.productId.mediaUrl[0]
                              }
                              alt=""
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                          {/* product details */}
                          <div
                            className={styles.details}
                            style={{ paddingInline: 0, marginTop: 0 }}
                          >
                            <div className={styles.about_product}>
                              <div
                                style={{
                                  height: "fit-content",
                                  lineHeight: ".9rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "7px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: ".8rem",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {/* @ts-ignore */}
                                  {p.productId?.category}
                                </span>
                                <span
                                  style={{
                                    fontSize: "1rem",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {/* @ts-ignore */}
                                  {p.productId?.collectionName}
                                </span>
                                <h3
                                  style={{
                                    lineHeight: "1rem",
                                    textTransform: "capitalize",
                                    marginTop: "5px",
                                  }}
                                >
                                  {/* @ts-ignore */}
                                  {p.productId?.title}
                                </h3>
                                <p
                                  style={{
                                    lineHeight: "1.1rem",
                                    textTransform: "capitalize",
                                    marginTop: "8px",
                                  }}
                                >
                                  Rs. {p.productId.pricing.price}
                                </p>
                              </div>
                            </div>
                            <div>
                              <p>
                                Quantity : {/* @ts-ignore */}
                                <span>{p.quantity}</span>
                              </p>
                              <div
                                className={styles.variants}
                                style={{ marginTop: "10px" }}
                              >
                                <p>Variant :</p>
                                <ul>
                                  {/* @ts-ignore */}
                                  {p?.variant.map((val: any, index: any) => {
                                    return (
                                      <li key={index}>
                                        {val.options_name} : {val.options_value}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* // @ts-ignore */}
                  {Object.keys(this?.state?.user)?.length > 0 && (
                    <div
                      className={styles.container}
                      style={{ border: ".5px solid grey" }}
                    >
                      <h2>Customer</h2>
                      <div className={styles.details}>
                        <div className={styles.details_1}>
                          <p>
                            First Name : {/* @ts-ignore */}
                            <span>{this.state.user?.firstName}</span>
                          </p>
                          <p>
                            Last Name : {/* @ts-ignore */}
                            <span>{this.state.user?.lastName}</span>
                          </p>
                        </div>
                        <div className={styles.details_1}>
                          <p>
                            Email :{" "}
                            <span>
                              {/* @ts-ignore */}
                              {this.state.user?.email}
                            </span>
                          </p>
                          <p>
                            Mobile :{" "}
                            <span>
                              {/* @ts-ignore */}+
                              {this.state.user?.mobile?.countryCode}-
                              {/* @ts-ignore */}
                              {this.state.user?.mobile?.number}
                            </span>
                          </p>

                          <p>
                            Billing Address :{" "}
                            <span>
                              {/* @ts-ignore */}
                              {this.state.user?.billingAddress[0]?.name},{" "}
                              {
                                // @ts-ignore
                                this.state.user?.billingAddress[0]?.addLineOne
                              }{" "}
                              {
                                //@ts-ignore
                                this.state.user?.billingAddress[0]?.addLineTwo
                              }{" "}
                              {/* @ts-ignore */}
                              {
                                this.state.user?.billingAddress[0]?.landmark
                              }{" "}
                              {/* @ts-ignore */}
                              {this.state.user?.billingAddress[0]?.city}{" "}
                              {/* @ts-ignore */}
                              {this.state.user?.billingAddress[0]?.state} -{" "}
                              {/* @ts-ignore */}
                              {this.state.user?.billingAddress[0]?.pincode}
                            </span>
                          </p>
                          {/* @ts-ignore */}
                          {!this.state.data?.serviceId && (
                            <p>
                              Shipping Address :{" "}
                              <span>
                                {/* @ts-ignore */}
                                {
                                  this.state.user?.shippingAddress[0]?.name
                                },{" "}
                                {
                                  // @ts-ignore
                                  this.state.user?.shippingAddress[0]
                                    ?.addLineOne
                                }{" "}
                                {
                                  // @ts-ignore
                                  this.state.user?.shippingAddress[0]
                                    ?.addLineTwo
                                }{" "}
                                {
                                  //@ts-ignore
                                  this.state.user?.shippingAddress[0]?.landmark
                                }{" "}
                                {/* @ts-ignore */}
                                {this.state.user?.shippingAddress[0]?.city}{" "}
                                {
                                  // @ts-ignore
                                  this.state.user?.shippingAddress[0]?.state
                                }{" "}
                                - {/* @ts-ignore */}
                                {this.state.user?.shippingAddress[0]?.pincode}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </ContentBody>
          </Content>
        )}

        {this.state.cancelModal && (
          <div className={styles.cancel_modal_container}>
            <div className={styles.cancel_card}>
              <h1>Cancel Order</h1>
              <p>
                Are you sure about this action ? user will see their status as :{" "}
              </p>
              {/* @ts-ignore */}
              {(this.state.data.modeOfPayment === "upi1" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "upi2" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "credit" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "debit" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "netbanking" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "emi" ||
                // @ts-ignore
                this.state.data.modeOfPayment === "wallet") && (
                <div className={styles.cancel_fields_container}>
                  <InputField
                    type="select"
                    isLabel={true}
                    lable={"Refund Status"}
                    value={this.state.cancelRefundStatus}
                    onChange={(e: any) => {
                      this.setState({ cancelRefundStatus: e.target.value });
                    }}
                    options={[
                      { title: "Request", value: "requested" },
                      { title: "Processing", value: "processing" },
                      { title: "Completed", value: "completed" },
                    ]}
                    accessText="title"
                    accessValue="value"
                  />
                </div>
              )}
              <div className={styles.cancel_btns}>
                <button onClick={() => this.setState({ cancelModal: false })}>
                  Discard
                </button>
                <button
                  onClick={() =>
                    this.updateOrderStatus({ fulfilmentStatus: "cancelled" })
                  }
                >
                  Cancel Order
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.sales_access.write) return true;

  return false;
};

export default withRouter(CartDetails);
