import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AppCard = ({ application, onIntegrateClick, isIntegrated }: any) => {
  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        border: "1px solid lightgrey",
        borderRadius: "15px",
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div
          style={{
            height: "40px",
            width: "40px",
            border: "1px solid lightgrey",
            padding: "7px",
            borderRadius: "100%",
          }}
        >
          <img
            src={application.logo}
            alt=""
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        <h3 style={{ fontWeight: "500", fontSize: "1.2rem" }}>
          {application.title}
        </h3>
        {isIntegrated ? (
          <div style={{ marginLeft: "auto", display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                textDecoration: "underline",
                color: "grey",
                cursor: "pointer",
              }}
              onClick={() => onIntegrateClick({ ...application, fetch: true })}
            >
              Edit
            </button>
          </div>
        ) : (
          <button
            style={{
              background: "var(--brand-color)",
              color: "white",
              padding: "5px 10px",
              border: "none",
              outline: "none",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "1rem",
              borderRadius: "5px",
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={() => onIntegrateClick(application)}
          >
            <FontAwesomeIcon icon={faAdd} />
            Integrate
          </button>
        )}
      </div>
      <p style={{ fontSize: ".9rem", color: "grey" }}>
        {application.description}
      </p>
    </div>
  );
};

export default AppCard;
