import React from "react";

const ImageComponent = ({
  isFile,
  url,
  fileUrl,
}: {
  isFile: boolean;
  url?: string;
  fileUrl?: string;
}) => {
  const checkExtension = () => {
    if (url) {
      return {
        status: true,
        type: url?.split(".")[url?.split(".").length - 1],
      };
    }

    return { status: false };
  };
  return (
    <>
      {isFile ? (
        <img src={fileUrl} alt={""} />
      ) : (
        <>
          {checkExtension().status ? (
            checkExtension().type === "avif" ? (
              <img src={url} alt="" typeof="image/avif" />
            ) : (
              <img src={url} alt={""} />
            )
          ) : (
            <>Not Found</>
          )}
        </>
      )}
    </>
  );
};

export default ImageComponent;
