import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

const LangContext = createContext({});

const LangContextProvider = ({ children }: { children: ReactNode }) => {
  const [lang, setLang] = useState("");

  useLayoutEffect(() => {
    const temp_lang = localStorage.getItem("lang");
    if (temp_lang) {
      setLang(temp_lang);
    } else {
      setLang("en");
      localStorage.setItem("lang", "en");
    }
  }, []);

  const value = {
    lang,
    setLang: (value: string) => {
      setLang(value);
      localStorage.setItem("lang", value);
    },
  };

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
};

const useLangContext = () => {
  return useContext(LangContext);
};

export { LangContextProvider, useLangContext };
