import React from "react";
import InputField from "../InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { folder_name } from "../../Utils/UploadImage";
import TextEditor from "../TextEditor";
import heic2any from "heic2any";

const EnhancedProductContent = (props: any) => {
  const handleImage = async (file: any, index: any) => {
    let temp = [...props?.value];

    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      temp[index].mediaUrl = {
        obj: convertedFile,
        url: URL.createObjectURL(convertedFile),
      };
    } else {
      temp[index].mediaUrl = {
        obj: file,
        url: URL.createObjectURL(file),
      };
    }

    props?.onChange(temp);
  };

  return (
    <div className="card">
      <h3>
        Enhanced Product Content{" "}
        <button
          onClick={() => {
            props?.onChange([
              ...props?.value,
              {
                title: "",
                mediaUrl: { obj: null, url: "" },
                onClickUrl: "",
                position: "",
                description: "",
              },
            ]);
          }}
        >
          Add
        </button>
      </h3>
      <ul className="grid" style={{ listStyle: "none", marginTop: "20px" }}>
        {props?.value.map((v: any, index: any) => {
          return (
            <li
              key={index}
              style={{
                border: "1px dashed black",
                padding: "20px",
                borderRadius: "20px",
                position: "relative",
              }}
              className="grid"
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{
                  position: "absolute",
                  height: "20px",
                  width: "20px",
                  background: "#eff5fc",
                  color: "#36619b",
                  padding: "5px",
                  borderRadius: "100%",
                  right: 0,
                  top: 0,
                  transform: "translate(50%, -50%)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  let temp = [...props.value];
                  temp.splice(index, 1);
                  props.onChange(temp);
                }}
              />
              <InputField
                type="text"
                isLabel={true}
                lable={"Title"}
                value={props?.value[index]?.title}
                onChange={(e: any) => {
                  let temp = [...props?.value];
                  temp[index].title = e.target.value;
                  props?.onChange(temp);
                }}
              />
              <div className="grid_2">
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"On Click Url"}
                  value={props?.value[index]?.onClickUrl}
                  onChange={(e: any) => {
                    let temp = [...props?.value];
                    temp[index].onClickUrl = e.target.value;
                    props?.onChange(temp);
                  }}
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"Position"}
                  value={props?.value[index]?.position}
                  onChange={(e: any) => {
                    let temp = [...props?.value];
                    temp[index].position = e.target.value;
                    props?.onChange(temp);
                  }}
                />
              </div>

              {!props?.value[index]?.mediaUrl.url && (
                <div className="media_container">
                  <FontAwesomeIcon icon={faPlus} />
                  <p>Add Images</p>
                  <input
                    type="file"
                    accept=".heic,image/*"
                    onChange={(e: any) => handleImage(e.target.files[0], index)}
                  />
                </div>
              )}
              {props?.value[index]?.mediaUrl.url && (
                <div className="media_content_container">
                  <div className="media">
                    <img
                      src={
                        v?.mediaUrl?.obj
                          ? v?.mediaUrl.url
                          : process.env.REACT_APP_IMAGE +
                            folder_name() +
                            v?.mediaUrl.url
                      }
                      alt=""
                    />
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={() => {
                        let temp = [...props?.value];
                        temp[index].mediaUrl = { object: null, url: "" };
                        props?.onChange(temp);
                      }}
                    />
                  </div>
                </div>
              )}
              <TextEditor
                content={props?.value[index].description}
                handleChange={(val: any) => {
                  let temp = [...props?.value];
                  temp[index].description = val;
                  props?.onChange(temp);
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EnhancedProductContent;
