import axios from "axios";

const url =
  "https://shopable-backend-epklwnz2xq-em.a.run.app/api/v1/staging/shopable-product";

export const addShoppableVideo = ({ files, id }: any) => {
  const formData = new FormData();

  formData.append("files", files);
  formData.append("id", id);

  return axios({
    method: "post",
    url: url,
    data: formData,
  });
};
