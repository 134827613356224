import React from "react";
import styles from "./dropBtn.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const MoreDropBtn = (props: any) => {
  return (
    <>
      <div className={styles.container}>
        <ul className={styles.drop_container}>
          {props.drop_list.map((val: any, index: any) => {
            return (
              <li
                key={index}
                className={styles.drop_item}
                id={val.id}
                onClick={(e: any) => {
                  props.on_click(e.target.id);
                }}
              >
                {val?.icon && (
                  <val.icon style={{ height: "18px", width: "18px" }} />
                )}
                {val.title}
              </li>
            );
          })}
        </ul>

        <div
          className={styles.cover}
          onClick={() => {
            props.setMoreDropdown((prev: Boolean) => !prev);
          }}
        ></div>
      </div>
    </>
  );
};

export default MoreDropBtn;
