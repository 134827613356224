import { useEffect, useReducer, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import InputField from "../../Components/InputField";
import TextEditor from "../../Components/TextEditor";
import ToggleSwitch from "../../Components/ToggleSwitch";
import { useNavigate, useParams } from "react-router-dom";
import { uploadMedia } from "../../Utils/uploadMedia";
import toast from "react-hot-toast";
import axios from "axios";
import ImageFolder from "../../Components/ImageFolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import { getFileName } from "../../Utils/getFileName";
import { useAdminContext } from "../../Store/adminContext";
import heic2any from "heic2any";

function reducer(state: any, action: any) {
  switch (action.type) {
    case "TITLE":
      return { ...state, title: action.payload };
    case "DESCRIPTION":
      return { ...state, description: action.payload };
    case "VISIBILITY":
      return { ...state, visibility: action.payload };
    case "LOADING":
      return { ...state, loading: true };
    case "ADD_IMAGE":
      return {
        ...state,
        mediaUrl: [
          ...state.mediaUrl,
          {
            mediaUrl: {
              obj: action.payload.obj,
              url: action.payload.url,
            },
            position: -1,
          },
        ],
      };
    case "REMOVE_MEDIA":
      return {
        ...state,
        mediaUrl: [],
      };
    case "METADATA":
      return {
        ...state,
        metaData: action.payload,
      };
    case "ALL":
      return {
        ...state,
        title: action.payload?.title,
        description: action.payload?.description,
        visibility: action.payload?.visibility,
        mediaUrl: action.payload?.media?.map((val: any) => ({
          mediaUrl: { obj: null, url: val.mediaUrl },
          position: val.position,
        })),
        metaData: action.payload?.metaData || {
          metaTitle: "",
          metaDescription: "",
        },
      };
    default:
      throw Error("Unknown Action");
  }
}

const AddUpdateCollection = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { admin }: any = useAdminContext();

  const [open, setOpen] = useState(false);
  const [data, dataDispatch] = useReducer(reducer, {
    title: "",
    description: "",
    visibility: false,
    mediaUrl: [],
    loading: false,
    metaData: {
      metaTitle: "",
      metaDescription: "",
    },
  });

  useEffect(() => {
    if (params?.id) {
      getData();
    }
  }, []);

  const getData = () => {
    axios({
      url:
        "https://backend.cftcommerce.com/api/productcollection/" + params?.id,
      method: "GET",
    })
      .then((res: any) => {
        dataDispatch({ type: "ALL", payload: res.data.productCollection });
      })
      .catch((err: any) => {
        toast.error("Error in fetching data.");
        navigate("/categories");
      });
  };

  const handleVisibility = () => {
    dataDispatch({
      type: "VISIBILITY",
      payload: !data.visibility,
    });
  };

  const uploadImage = async () => {
    if (data.mediaUrl.length === 0) {
      return [];
    }
    if (data.mediaUrl[0].mediaUrl.obj !== null) {
      const filename = getFileName(data.mediaUrl[0].mediaUrl.obj);
      await UploadImage({
        file: data.mediaUrl[0].mediaUrl.obj,
        name: filename,
      });
      return [{ mediaUrl: filename, position: -1 }];
    } else {
      return [{ mediaUrl: data.mediaUrl[0].mediaUrl.url, position: -1 }];
    }
  };

  const handleCreateAndUpdate = async () => {
    dataDispatch({ type: "LOADING" });

    console.log(data);

    let body: any = {
      identifier: localStorage.getItem("identifier"),
      title: data.title,
      description: data.description,
      visibility: data.visibility,
      // media: data.mediaUrl,
      metaData: data.metaData,
    };

    body.media = await uploadImage();

    axios({
      method: params?.id ? "PATCH" : "POST",
      url: `https://backend.cftcommerce.com/api/productcollection${
        params?.id ? "/" + params.id : "/"
      }`,
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        navigate("/collections");
        toast.success("Category Added Successfully.");
      })
      .catch((err: any) => {
        toast.error("Something went wrong, while adding a category");
      });
  };

  const onMediaSelect = async (media: any) => {
    const file = media[0];
    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      dataDispatch({
        type: "ADD_IMAGE",
        payload: {
          url: URL.createObjectURL(convertedFile),
          obj: convertedFile,
        },
      });
    }
    dataDispatch({
      type: "ADD_IMAGE",
      payload: { url: URL.createObjectURL(media[0]), obj: media[0] },
    });
    setOpen(false);
  };

  return (
    <>
      <ImageFolder
        open={open}
        onClose={() => setOpen(false)}
        selectMultiple={false}
        onSave={onMediaSelect}
        alreadySelected={data.mediaUrl}
      />
      <Content>
        <ContentHead
          title={params?.id ? "Update Collection" : "Add Collection"}
          showBtn1={isUserAllowedToEdit(admin)}
          btn1type={"filled"}
          btn1Text={"Save"}
          btn1Name={"btn1"}
          showBtn2={isUserAllowedToEdit(admin)}
          btn2Text={"Save as Draft"}
          btn2type="outlined"
          btn2Name="btn2"
          showBtn3={true}
          btn3type={"outlined_del"}
          btn3Text={"Cancel"}
          btn3Name={"btn3"}
          onClickHandler={(e: any) => {
            if (e.target.name === "btn1") {
              handleCreateAndUpdate();
            } else if (e.target.name === "btn3") {
              navigate("/collections");
            } else if (e.target.name === "btn2") {
              dataDispatch({ type: "VISIBILITY", payload: true });
              handleCreateAndUpdate();
            }
          }}
        />
        <ContentBody>
          <div className="grid">
            <div className="card grid">
              <div className="grid_2">
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"Collection Name"}
                  value={data.title}
                  onChange={(e: any) => {
                    dataDispatch({
                      type: "TITLE",
                      payload: e.target.value.toLowerCase(),
                    });
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    border: "1px solid rgb(222,222,222)",
                    borderRadius: "10px",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "20px",
                    marginTop: "27px",
                  }}
                >
                  <p>Visibility</p>
                  <ToggleSwitch
                    value={data.visibility}
                    onClick={() => {
                      dataDispatch({
                        type: "VISIBILITY",
                        payload: !data.visibility,
                      });
                    }}
                  />
                </div>
              </div>
              {data.mediaUrl.length === 0 && (
                <div
                  className="media_container"
                  // onClick={() => setOpen(true)}
                >
                  <input
                    type="file"
                    accept=".heic,image/*"
                    onChange={async (e: any) => {
                      const file = e.target.files[0];
                      if (file && file.type === "image/heic") {
                        const convertedBlob: any = await heic2any({
                          blob: file,
                          toType: "image/jpeg",
                        });
                        const convertedFile = new File(
                          [convertedBlob],
                          "converted.jpg",
                          {
                            type: "image/jpeg",
                          }
                        );
                        dataDispatch({
                          type: "ADD_IMAGE",
                          payload: {
                            url: URL.createObjectURL(convertedFile),
                            obj: convertedFile,
                          },
                        });
                      } else
                        dataDispatch({
                          type: "ADD_IMAGE",
                          payload: {
                            url: URL.createObjectURL(e.target.files[0]),
                            obj: e.target.files[0],
                          },
                        });
                    }}
                  />
                  <p>+</p>
                  <p>Add Media</p>
                </div>
              )}
              {data.mediaUrl.length !== 0 && (
                <ul className="selected_media_container">
                  {data.mediaUrl?.map((val: any, index: any) => {
                    return (
                      <div className="media_content">
                        <img
                          src={
                            val.mediaUrl.obj !== null
                              ? val.mediaUrl.url
                              : `${
                                  process.env.REACT_APP_IMAGE
                                }${folder_name()}${val.mediaUrl.url}`
                          }
                          alt={""}
                        />
                        <FontAwesomeIcon
                          icon={faClose}
                          color={"#36619b"}
                          onClick={() =>
                            dataDispatch({
                              type: "REMOVE_MEDIA",
                              payload: index,
                            })
                          }
                        />
                      </div>
                    );
                  })}
                </ul>
              )}
              <InputField
                type="textarea"
                isLabel={true}
                lable={"Description"}
                value={data.description}
                onChange={(e: any) =>
                  dataDispatch({ type: "DESCRIPTION", payload: e.target.value })
                }
              />
            </div>
            <div className="card grid">
              <h3>Meta Data</h3>
              <InputField
                isLabel={true}
                lable={"Meta Title"}
                value={data.metaData.metaTitle}
                onChange={(e: any) => {
                  dataDispatch({
                    type: "METADATA",
                    payload: { ...data.metaData, metaTitle: e.target.value },
                  });
                }}
                type="text"
              />
              <InputField
                isLabel={true}
                lable={"Meta Description"}
                value={data.metaData.metaDescription}
                onChange={(e: any) => {
                  dataDispatch({
                    type: "METADATA",
                    payload: {
                      ...data.metaData,
                      metaDescription: e.target.value,
                    },
                  });
                }}
                type="textarea"
              />
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.write) return true;

  return false;
};

export default AddUpdateCollection;
