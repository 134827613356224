import { faClose } from "@fortawesome/free-solid-svg-icons";
import styles from "../../Styles/products.module.css";
import InputField from "../InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleSwitch from "../ToggleSwitch";

const Specifications = (props: any) => {
  return (
    <>
      <div className={`${styles.field_container}`}>
        <div className={styles.variant_heading}>
          <h3>Specifications</h3>
          <button onClick={props.handleAddSpec}>Add</button>
        </div>
        <div className={styles.variants_container}>
          {[...props.state.specifications].map((val: any, index: any) => {
            return (
              <div className={styles.variant_container} key={index}>
                <InputField
                  type="text"
                  value={val.options_name}
                  onChange={(e: React.BaseSyntheticEvent) => {
                    props.handleSpecProperties(e, index);
                  }}
                  isLabel={true}
                  lable="Option name"
                  name="options_name"
                />
                <InputField
                  type="string"
                  value={val.options_value}
                  onChange={(e: any) => {
                    props.handleSpecProperties(e, index);
                  }}
                  isLabel={true}
                  lable="Option value"
                  name="options_value"
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    marginTop: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <label>Is Visible</label>
                  <ToggleSwitch
                    value={val.isVisible}
                    onClick={() => {
                      props.handleSpecProperties(
                        {
                          target: { name: "isVisible", value: !val.isVisible },
                        },
                        index
                      );
                    }}
                  />
                </div>

                <div
                  className={styles.remove_variant}
                  onClick={() => props.handleRemoveSpec(index)}
                >
                  <FontAwesomeIcon icon={faClose} color="white" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Specifications;
