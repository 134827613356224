import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import styles from "../../Styles/imageFolder.module.css";

type Props = {
  open: Boolean;
  onClose: () => void;
  onSave: (val: string[]) => void;
  selectMultiple?: Boolean; // this defines how many images can be selected
  alreadySelected: any;
};

const ImageFolder = (props: Props) => {
  const [startClose, setStartClose] = useState(false);
  const [section, setSection] = useState("asset");
  const [selected, setSelected]: any = useState([...props.alreadySelected]);
  const [media, setMedia]: any = useState([]);

  useEffect(() => {
    setMedia([
      "https://dbxtdwj4gd9ez.cloudfront.net/filters:format(webp)/jssolitairesin/BVPV3191_12475533_jssolitaires.in.webp",
      "https://dbxtdwj4gd9ez.cloudfront.net/filters:format(webp)/jssolitairesin/WRVF3870_185956114_jssolitaires.in.webp",
      "https://dbxtdwj4gd9ez.cloudfront.net/filters:format(webp)/jssolitairesin/HTFL1103_184038483_jssolitaires.in.webp",
      "https://dbxtdwj4gd9ez.cloudfront.net/filters:format(webp)/jssolitairesin/BUNX0140_18312791_jssolitaires.in.webp",
      "https://dbxtdwj4gd9ez.cloudfront.net/filters:format(webp)/jssolitairesin/TQPW1318_18312792_jssolitaires.in.webp",
      "https://dbxtdwj4gd9ez.cloudfront.net/filters:format(webp)/jssolitairesin/CAZP7925_181019718_jssolitaires.in.webp",
    ]);
  }, []);

  useEffect(() => {
    setSelected([...props.alreadySelected]);
  }, [props.alreadySelected]);

  const closeModal = () => {
    setStartClose(true);
    setTimeout(() => {
      props.onClose();
      setSelected([]);
      setStartClose(false);
    }, 300);
  };

  const handleSelected = (id: string) => {
    // check if id already in selected or not

    let temp: string[] = [...selected];

    if (selected.find((val: string) => val === id)) {
      let index = selected.findIndex((val: string) => val === id);
      temp.splice(index, 1);
    } else {
      if (props.selectMultiple) {
        temp.push(id);
      } else {
        temp = [id];
      }
    }
    setSelected(temp);
  };

  const checkSelected = (id: string) => {
    return selected.find((val: string) => val === id);
  };

  if (!props?.open) return <></>;

  return (
    <div className={styles.container} onClick={closeModal}>
      <div
        className={`${styles.main} ${startClose && styles.main_close}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.head}>
          <CloseIcon onClick={closeModal} />
          <p>Upload Media</p>
        </div>

        <div className={styles.sections}>
          <div
            className={`${styles.section} ${
              section === "asset" && styles.section_selected
            }`}
            onClick={() => setSection("asset")}
          >
            Asset Library
          </div>
          <div
            className={`${styles.section} ${
              section === "upload" && styles.section_selected
            }`}
            onClick={() => setSection("upload")}
          >
            Upload
          </div>
        </div>

        {section === "asset" && (
          <div className={styles.assets_container}>
            {media.map((val: string, index: number) => {
              return (
                <div
                  key={index}
                  className={styles.asset}
                  onClick={() => handleSelected(val)}
                >
                  <img src={val} alt="" />
                  {checkSelected(val) && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.asset_selected}
                      color="green"
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
        {section === "upload" && (
          <div className={styles.upload_container}>
            <p>Upload images that meet the requirements.</p>
            <div className={styles.upload_section}>
              <input
                type="file"
                accept="image/*"
                onChange={() => alert("file added")}
              />
              <p>To Upload your media, drag files here.</p>
              <span>or</span>
              <div>Upload from Computer</div>
            </div>
            <p>Assets Upload will be saved in asset library.</p>
          </div>
        )}
        <div className={styles.footer}>
          <button onClick={() => props.onSave(selected)}>Save</button>
          <button onClick={() => props.onClose()}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ImageFolder;
