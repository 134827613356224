import { useState } from "react";
import styles from "./contactConfiguration.module.css";
import { ReactComponent as ImportIcon } from "../../../Assets/icons/import.svg";
import { ReactComponent as FileIcon } from "../../../Assets/icons/file.svg";
import { ReactComponent as TrashIcon } from "../../../Assets/icons/trash.svg";
import Info from "../../info";
import InputField from "../../InputField";

type Props = {
  files: any;
  setFiles: any;
  encoding: string;
  setEncoding: any;
  delimiter: string;
  setDelimiter: any;
  tsvDelimiter: string;
  setTsvDelimiter: any;
  duplicate: any;
  setDuplicate: any;
};

const ContactConfiguration = ({
  files,
  setFiles,
  encoding,
  setEncoding,
  delimiter,
  setDelimiter,
  tsvDelimiter,
  setTsvDelimiter,
  duplicate,
  setDuplicate,
}: Props) => {
  return (
    <div className={styles.configuration_container}>
      <div className={styles.import_container}>
        {files.length === 0 && (
          <div className={styles.import_icon}>
            <ImportIcon />
          </div>
        )}

        {files.length !== 0 && (
          <div className={styles.files}>
            {files.map((file: any, index: any) => {
              return (
                <div key={index} className={styles.file}>
                  <FileIcon className={styles.file_icon} />
                  <p className={styles.file_name}>{file.name}</p>
                  <div
                    className={styles.remove_file}
                    onClick={() => {
                      let temp = [...files];
                      temp.splice(index, 1);
                      setFiles(temp);
                    }}
                  >
                    <TrashIcon />
                    <p>Remove</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <p>Drag and drop file to import</p>
        <div className={styles.choose_btn}>
          <input
            type="file"
            accept=".csv,.tsv,.xls,.xlsx,text/csv,text/tab-separated-values,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            multiple
            onChange={(e: any) => {
              setFiles((prev: any) => [...prev, ...e.target.files]);
            }}
          />
          <p>Choose File</p>
        </div>
        <p>Maximum File Size: 25 MB, File Format: CSV or TSV or XLS</p>
      </div>
      <p className={styles.sample_text}>
        Download a <span>sample file</span> and compare it to your import file
        to ensure you have the file perfect for the import.{" "}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
          }}
        >
          <label>Character Encoding</label>
          <Info
            text="Select Character Encoding"
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "UTF-8 ( Unicode )", value: "utf-8" },
              { title: "UTF-16 ( Unicode )", value: "utf-16" },
              { title: "ISO-8859-1", value: "iso-8859-1" },
              { title: "ISO-8859-2", value: "iso-8859-2" },
              { title: "ISO-8859-9 ( Turkish )", value: "iso-8859-9" },
              { title: "GB2312 ( Simplified Chinese )", value: "gb2312" },
              { title: "Big5 ( Traditional Chinese )", value: "big5" },
              { title: "Shift_JIS ( Japanese )", value: "shift-jis" },
            ]}
            accessText="title"
            accessValue="value"
            value={encoding}
            onChange={(e: any) => setEncoding(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
          }}
        >
          <label>CSV File Delimiter</label>
          <Info
            text="File Delimiter"
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "Tab", value: "tab" },
              { title: "Comma ( , )", value: "comma" },
              { title: "Semi-Colon ( ; )", value: "semi-colon" },
            ]}
            accessText="title"
            accessValue="value"
            value={delimiter}
            onChange={(e: any) => setDelimiter(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
          }}
        >
          <label>TSV File Delimiter</label>
          <Info
            text="TSV File Delimiter"
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "Tab", value: "tab" },
              { title: "Comma ( , )", value: "comma" },
              { title: "Semi-Colon ( ; )", value: "semi-colon" },
            ]}
            accessText="title"
            accessValue="value"
            value={tsvDelimiter}
            onChange={(e: any) => setTsvDelimiter(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
          }}
        >
          <label>Duplicate Handling</label>
          <Info
            text="Duplicate Handling"
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "Skip Duplicates", value: "skip" },
              { title: "Overwrite Duplicates", value: "overwrite" },
            ]}
            accessText="title"
            accessValue="value"
            value={duplicate}
            onChange={(e: any) => setDuplicate(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactConfiguration;
