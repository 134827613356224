import axios from "axios";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";

const AdminContext = createContext({});

const AdminContextProvider = ({ children }: { children: ReactNode }) => {
  const [admin, setAdmin]: any = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("token-BO-ADMIN")) {
      getAdminData();
    } else {
      setLoading(false);
    }
  }, []);

  const getAdminData = () => {
    axios({
      url: "https://backend.cftcommerce.com/api/admins/getAdminByToken",
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setAdmin(res.data.data);
        setLoading(false);
      })
      .catch(() => {
        toast.error("Session Expired, Please Login Again");
        localStorage.clear();
        setLoading(false);
      });
  };

  const value = {
    admin,
  };

  if (loading)
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="loader"></span>
      </div>
    );

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};

const useAdminContext = () => {
  return useContext(AdminContext);
};

export { AdminContextProvider, useAdminContext };
