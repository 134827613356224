import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function getDates(startDate: any, endDate: any) {
  console.log(startDate);
  console.log(endDate);
  const dates = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

export function LineChart({
  orderType,
  dataset,
  startDate,
  endDate,
}: {
  orderType: string;
  dataset: any;
  startDate: string;
  endDate: string;
}) {
  const today = new Date();
  const [data, setData]: any = useState({
    labels: [],
    datasets: [],
  });
  const [show, setShow] = useState(true);

  useEffect(() => {
    const ordersData = [
      {
        label: "ALL",
        data: dataset["all"].map((v: any) => v.total),
        borderColor: "black",
        backgroundColor: "black",
      },
      {
        label: "COD",
        data: dataset["COD"].map((v: any) => v.total),
        borderColor: "lightblue",
        backgroundColor: "lightblue",
      },
      {
        label: "Cancelled",
        data: dataset["cancelled"].map((v: any) => v.total),
        borderColor: "red",
        backgroundColor: "red",
      },
      {
        label: "online",
        data: dataset["online"].map((v: any) => v.total),
        borderColor: "lightgreen",
        backgroundColor: "lightgreen",
      },
    ];
    const labels = getDates(new Date(startDate), new Date(endDate));

    // console.log(labels);
    console.log(ordersData);
    
    setData({
      labels,
      datasets: ordersData.filter(
        (order) => order.label.toLowerCase() === orderType.toLowerCase()
      ),
    });
    setShow(false);
    setTimeout(() => setShow(true), 10);
  }, [dataset, orderType]);

  const options = {
    responsive: true,
    redraw: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Orders this month",
      },
    },
  };

  if (!show) return <></>;

  return <Line options={options} data={data} />;
}
