import React, { useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import styles from "./hover.module.css";
import { ReactComponent as InfoIcon } from "../../../Assets/icons/info.svg";

type Props = {
  header: string;
  description: string;
  left?: string;
  right?: string;
};

const HoverCardInfo = ({ header, right, left, description }: Props) => {
  return (
    <div className={styles.container}>
      <InfoIcon className={styles.icon} />
      <div
        style={right ? { right: `${right}` } : { left: "0px" }}
        className={styles.HoverContainer}
      >
        <h5 className={styles.head}>{header}</h5>
        <span className={styles.data}>{description}</span>
      </div>
    </div>
  );
};

export default HoverCardInfo;
