import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";
import UploadImage from "../../Utils/UploadImage";
import { getFileName } from "../../Utils/getFileName";
import { folder_name } from "../../Utils/UploadImage";

function TextEditor(props: any) {
  // global variable
  const quillObj: any = useRef();
  const [loading, setLoading] = useState(false);

  function removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  const uploadFiles = (uploadFileObj: any, filename: any, quillObj: any) => {
    setLoading(true);
    // date added to the file name
    const timestamp = new Date();

    const filenameValue = getFileName(uploadFileObj);

    UploadImage({ file: uploadFileObj, name: filenameValue })
      .then(() => {
        const image_url = `${
          `${process.env.REACT_APP_IMAGE}${folder_name()}` + filenameValue
        }`;
        const range = quillObj.current.getEditorSelection();
        quillObj.current
          .getEditor()
          .insertEmbed(range.index, "image", image_url);

        setLoading(false);
      })
      .catch(() => {
        toast.error("Something went wrong!!!!!");
        setLoading(false);
      });
  };

  const imageHandler = () => {
    const input: any = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file: any = input?.files[0];

      const fileName = file.name;

      const res = await uploadFiles(file, fileName, quillObj);
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [
            { header: "1" },
            { header: "2" },
            { header: [3, 4, 5, 6] },
            { font: [] },
          ],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "color",
    "background",
    "align",
    "link",
    "image",
    "video",
  ];

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <ReactQuill
        ref={quillObj}
        style={{
          borderRadius: "15px",
          outline: "none",
          width: "100%",
        }}
        value={props.content}
        onChange={props.handleChange}
        modules={modules}
        formats={formats}
        placeholder={
          props?.placeholder ? props?.placeholder : "Add Block Content..."
        }
      />
    </div>
  );
}

export default TextEditor;
