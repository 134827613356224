import React from "react";
import styles from "./analytics.module.css";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Content from "../../Components/Content/Content";
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import MostViewedProduct from "../../Components/Analytics/mostViewedProducts/MostViewedProduct";

import Orders from "../../Components/Analytics/Orders/Orders";
import { UserLocation } from "../../Components/Analytics/userLocation/UserLocation";
import { MdInfoOutline } from "react-icons/md";
import HoverCardInfo from "../../Components/common/HoverCardInfo/HoverCardInfo";
import Location from "../../Components/Analytics/location/Location";
import ComingFrom from "../../Components/Analytics/comingFrom/ComingFrom";
import TopSellingProducts from "../../Components/Analytics/topSellingProducts/TopSellingProducts";
import { TopPerformingCategory } from "../../Components/Analytics/topPerformingCategory/topPerformingCategory";
import ContentOuterHead from "../../Components/Content/contentOuterHead";
// import ComingFrom from "../../Components/Analytics/comingFrom/ComingFrom";

type TopCardType = {
  totalOrderSum: number;
  totalNumberOfProducts: number;
  totalNumberOfOrders: number;
  topPerformingProduct: any;
  totalNumberOfUsers: number;
  totalView: number;
};

type MostViewedProducts = {
  title: string;
  impression: number;
}[];

type TopSellingProduct = {
  name: string;
  quantity: number;
}[];

export type ComingFromType = {
  label: string;
  value: number;
  session: number;
};

const url = "https://backend.cftcommerce.com/";
// const url = "http://localhost:8080/";

const Analytics = () => {
  const [data, setData] = useState<TopCardType>();
  const [mostViewedProducts, setMostViewedProducts] =
    useState<MostViewedProducts>();
  const [topSellingProducts, setTopSellingProducts] =
    useState<TopSellingProduct>();
  const [order, setOrder] = useState<any>(null);
  const [comingFrom, setComingFrom] = useState<ComingFromType[]>([]);

  const today = new Date();

  const [endDate, setEndDate] = useState(today.toISOString());
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 2).toISOString(),
  );

  function convertNumberToIndianRupee(number: number) {
    const numberFormat = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    });
    return numberFormat.format(number);
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      method: "get",
      url: `${url}api/analytics/getTopCards?identifier=${localStorage.getItem(
        "identifier",
      )}`,
    })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        getMostViewedProducts();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        getMostViewedProducts();
      });
  };

  const getMostViewedProducts = () => {
    axios({
      url: `${url}api/analytics/getTopViewedProducts?identifier=${localStorage.getItem(
        "identifier",
      )}`,
      method: "get",
    })
      .then((res) => {
        setMostViewedProducts(
          res.data.topViewedProducts.map((v: any) => ({
            title: v.title,
            impression: v.impression,
          })),
        );
        getTopSellingProducts();
      })
      .catch((err) => {
        console.log(err);
        getTopSellingProducts();
      });
  };

  const getTopSellingProducts = () => {
    axios({
      url: `${url}api/analytics/getTopSellingProducts?identifier=${localStorage.getItem(
        "identifier",
      )}`,
      method: "get",
    })
      .then((res) => {
        console.log(res.data.topSellingProducts);
        setTopSellingProducts(res.data.topSellingProducts);
        getOrdersData();
      })
      .catch((err) => {
        console.log(err);
        getOrdersData();
      });
  };

  const getOrdersData = () => {
    axios({
      url: `${url}api/analytics/orders?startDate=${startDate}&endDate=${endDate}&identifier=${localStorage.getItem(
        "identifier",
      )}`,
      method: "get",
    })
      .then((res) => {
        console.log(res.data);
        setOrder(res.data);
        getComingFromData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getComingFromData = () => {
    // https://backend.cftcommerce.com/api/analytics/comingFromData?identifier=pulpofficial.com
    axios({
      url: `${url}api/analytics/comingFromData?identifier=${localStorage.getItem(
        "identifier",
      )}`,
      method: "get",
    })
      .then((res) => {
        // setMostViewedProducts(
        //   res.data.comingFrom.map((v: any) => ({
        //     title: v.title,
        //     impression: v.impression,
        //   }))
        // );
        // console.log(res.data.comingFrom);

        const totalValue = Object.values(res.data.comingFrom).reduce(
          (sum, value) => Number(sum) + Number(value),
          0,
        ) as number;

        console.log(res.data.comingFrom);

        // Step 2: Convert the object to an array with percentage values
        const array = Object.keys(res.data.comingFrom).map((key) => ({
          label: key,
          value: Number(
            ((res.data.comingFrom[key] / totalValue) * 100).toFixed(2),
          ),
          session: res.data.comingFrom[key],
        }));
        const filteredArray = array.filter(
          (data) => !data.label.includes("www.pulpofficial.com"),
        );
        setComingFrom([...filteredArray]);
        // console.log(array);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrdersData();
  }, [startDate, endDate]);

  return (
    <Content>
      <ContentHead title={"Overview"} />
      <ContentBody>
        <div className="grid">
          {data && (
            <ul className={styles.top_cards}>
              <li className={styles.card}>
                <div className={styles.Header}>
                  <h4>Total Views </h4>
                  <HoverCardInfo
                    header=""
                    description="the total no. of views on the website "
                  />
                </div>
                <p>{data?.totalView}</p>
              </li>
              <li className={styles.card}>
                <div className={styles.Header}>
                  <h4>Total Orders </h4>
                  <HoverCardInfo
                    header=""
                    description="the total no. of orders fulfilled"
                  />
                </div>
                <p>{data?.totalNumberOfOrders}</p>
              </li>
              <li className={styles.card}>
                <div className={styles.Header}>
                  <h4>Total Revenue </h4>
                  <HoverCardInfo
                    header=""
                    description="The sum of revenue from purchases minus the cancelled orders."
                  />
                </div>
                <p>{convertNumberToIndianRupee(data?.totalOrderSum)}</p>
              </li>
              <li className={styles.card}>
                <div className={styles.Header}>
                  <h4>Total Products </h4>
                  <HoverCardInfo
                    header="Total Products"
                    description="the total no. of products available on our website "
                  />
                </div>
                <p>{data?.totalNumberOfProducts}</p>
              </li>
              <li className={styles.card}>
                <div className={styles.Header}>
                  <h4>Total Users </h4>
                  <HoverCardInfo
                    right="0px"
                    header="Total Users"
                    description="the total no. of Users"
                  />
                </div>
                <p>{data?.totalNumberOfUsers}</p>
              </li>
            </ul>
          )}
          <div className="grid_2">
            <div className="card">
              {mostViewedProducts?.length !== 0 && (
                <MostViewedProduct
                  subHeader="Most Viewed"
                  header="Most Viewed Products"
                  products={mostViewedProducts!}
                />
              )}
            </div>
            <div className="card">
              {topSellingProducts?.length !== 0 && (
                <TopSellingProducts
                  subHeader="Top Selling"
                  header="Top Selling Products"
                  products={topSellingProducts!}
                />
              )}
            </div>
            <div className="card">
              {order && (
                <Orders
                  order={order}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              )}
            </div>
            <div className="card">
              <ComingFrom comingFrom={comingFrom} />
            </div>
            <TopPerformingCategory />
          </div>
          <div className="card">
            <Location />
          </div>
        </div>
      </ContentBody>
    </Content>
  );
};

export default Analytics;
