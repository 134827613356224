import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as InfoIcon } from "../../Assets/newIcons/info-circle.svg";
type Props = {
  text: string;
  iconStyle?: any;
  containerStyle?: any;
};

const Info = ({ text, iconStyle, containerStyle }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const iconRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (iconRef.current && tooltipRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      let top = iconRect.bottom;
      let left = iconRect.right;

      if (iconRect.bottom + tooltipRect.height > window.innerHeight) {
        top = iconRect.top - tooltipRect.height;
      }

      if (iconRect.right + tooltipRect.width > window.innerWidth) {
        left = iconRect.left - tooltipRect.width;
      }

      setTooltipStyle({
        top: `${top}px`,
        left: `${left}px`,
      });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      ref={iconRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: "relative",
        height: "fit-content",
        width: "fit-content",
        display: "inline-block",
        alignItems: "center",
        ...containerStyle,
      }}
    >
      <InfoIcon style={{ height: "100%", width: "100%", ...iconStyle }} />
      {isHovered && (
        <div
          ref={tooltipRef}
          style={{
            position: "absolute",
            backgroundColor: "#333",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "4px",
            whiteSpace: "nowrap",
            fontSize: ".8rem",
            textTransform: "capitalize",
            fontWeight: "400",
            zIndex: 1000,
            ...tooltipStyle,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Info;
