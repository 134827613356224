import React, { useState, useEffect } from "react";
import axios from "axios";
import withRouter from "../../Components/withRouter";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import Modal from "../../Components/Modal";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import styles from "./users.module.css";
import ContentBody from "../../Components/Content/ContentBody";
import SingleRowUser from "./singleRowUser";

interface UserProps {
  router: any;
}

const Users: React.FC<UserProps> = ({ router }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { lang }: any = useLangContext();
  const [secondaryView, setSecondaryView] = useState(false);
  const [inView, setInView]: any = useState({});
  const [tab, setTab] = useState("overview");

  const [contacts, setContacts]: any = useState([]);
  const [updatedContacts, setUpdatedContacts]: any = useState([]);

  const [modal, setModal] = useState(false);

  const [subAdmins, setSubAdmins] = useState([]);

  const [contactOwnerDropdown, setContactOwnerDropdown] = useState(false);
  const [createDateDropdown, setCreateDateDropdown] = useState(false);
  const [lastActivityDropdown, setLastActivityDropdown] = useState(false);
  const [leadStatusDropdown, setLeadStatusDropdown] = useState(false);
  const [actionsDropdown, setActionsDropdown] = useState(false);

  const [updateMode, setUpdateMode] = useState(false);

  const [remark, setRemark] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [disableForAction, setDisableForAction] = useState(false);

  const [users, setUsers] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    getData();
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      url: `https://backend.cftcommerce.com/api/users`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        console.log("user details : ", res.data.data.allowedUsers);
        setUsers(res.data.data.allowedUsers);
        setTotal(res?.data?.total);
        setLoading(false);
        console.log(users);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleRowClick = (val: any) => {
    router.navigate(`/users/${val._id}`);
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={"Users"}
          showBtn1={false}
          btn1Name={updateMode ? "update_btn1" : "btn1"}
          btn1Text={"Add New"}
          isIcon1={true}
          iconType1={faPlus}
          btn1type="filled"
          isMoreSettings={false}
          moreSettingsList={[
            { icon: "import", title: "Import", name: "import" },
            { icon: "export", title: "Export", name: "export" },
            // { icon: "logs", title: "Logs", name: "logs" },
          ]}
          handleMoreClick={(name: any) => {
            if (name === "import") {
              navigate("/contacts/import-file");
            } else if (name === "export") {
              // exportExcel();
            } else if (name === "logs") {
              navigate("/contacts/logs");
            }
          }}
          onClickHandler={(e: any) => {
            if (e.target.name === "btn1") {
              navigate("/users/add");
            }
          }}
          customElement={
            <div className={styles.search_section}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder="Search name, phone, email ..."
                value={searchTerm}
                // onChange={handleChange}
              />
            </div>
          }
        />
        <ContentBody style={{ padding: 0 }}>
          {(contactOwnerDropdown ||
            createDateDropdown ||
            lastActivityDropdown ||
            leadStatusDropdown ||
            actionsDropdown) && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "transparent",
                zIndex: 100,
              }}
              onClick={() => {
                setContactOwnerDropdown(false);
                setCreateDateDropdown(false);
                setLastActivityDropdown(false);
                setLeadStatusDropdown(false);
                setActionsDropdown(false);
              }}
            ></div>
          )}
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {secondaryView ? (
              <div className={styles.secondary_view}>
                <div className={styles.secondary_list_view}>
                  {users.map((val: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={styles.secondary_short_item}
                        style={
                          val._id === inView?._id
                            ? {
                                background: "var(--brand-color)",
                                color: "white",
                              }
                            : {}
                        }
                        onClick={() => setInView(val)}
                      >
                        {/* <input
                          type="checkbox"
                          // checked={val.actions.select}
                          onClick={() => {
                            // changeSelect(val._id);
                          }}
                        /> */}
                        <div>
                          <p>
                            {val?.firstName || val?.lastName
                              ? [val?.firstName, val?.lastName].join(" ")
                              : "--"}
                          </p>
                          <span>{val?.email}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.secondary_detail_view}>
                  <div>
                    <div className={styles.sdv_head}>
                      <p style={{ textTransform: "capitalize" }}>
                        {inView?.firstName ||
                        inView?.middleName ||
                        inView?.lastName
                          ? [
                              inView?.firstName?.trim() || "",
                              inView?.middleName?.trim() || "",
                              inView?.lastName?.trim() || "",
                            ]?.join(" ")
                          : "Undefined"}
                      </p>
                      <div className={styles.sdv_buttons}>
                        {/* <button
                          onClick={() =>
                            navigate("/users/update/" + inView?._id)
                          }
                        >
                          <EditIcon /> Edit
                        </button>
                        <button
                          onClick={() => {
                            // setDeleteById(inView?._id);
                            setModal(true);
                          }}
                        >
                          <TrashIcon />
                          Delete
                        </button> */}
                        <CloseIcon
                          className={styles.sdv_close_btn}
                          onClick={() => {
                            setSecondaryView(false);
                            setInView(null);
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className={styles.tabs_section}>
                      <div
                        className={
                          tab === "overview" ? styles.tab_selected : ""
                        }
                        onClick={() => setTab("overview")}
                      >
                        Overview
                      </div>
                      <div
                        className={tab === "remarks" ? styles.tab_selected : ""}
                        onClick={() => setTab("remarks")}
                      >
                        Remarks
                      </div>
                      <div
                        className={tab === "history" ? styles.tab_selected : ""}
                        onClick={() => setTab("history")}
                      >
                        Logs
                      </div>
                    </div> */}
                  </div>
                  {tab === "overview" && (
                    <>
                      <div className={styles.secondary_content}>
                        <div>
                          <span>First Name</span>
                          <p>{inView?.firstName || "--"}</p>
                        </div>

                        <div>
                          <span>Middle Name</span>
                          <p>{inView?.middleName || "--"}</p>
                        </div>
                        <div>
                          <span>Last Name</span>
                          <p>{inView?.lastName || "--"}</p>
                        </div>

                        <div>
                          <span>Mobile Number</span>
                          <p>{inView?.mobile.number}</p>
                        </div>

                        <div>
                          <span>Email</span>
                          <p>{inView?.email || "--"}</p>
                        </div>

                        <div>
                          <span>Billing Address</span>

                          <p>
                            {/* @ts-ignore */}
                            {inView?.billingAddress[0]?.name},{" "}
                            {
                              // @ts-ignore
                              inView?.billingAddress[0]?.addLineOne
                            }{" "}
                            {
                              // @ts-ignore
                              inView?.billingAddress[0]?.addLineTwo
                            }{" "}
                            {
                              //@ts-ignore
                              inView?.billingAddress[0]?.landmark
                            }{" "}
                            {/* @ts-ignore */}
                            {inView?.billingAddress[0]?.city}{" "}
                            {
                              // @ts-ignore
                              inView?.billingAddress[0]?.state
                            }{" "}
                            - {/* @ts-ignore */}
                            {inView?.billingAddress[0]?.pincode}
                          </p>
                        </div>
                        <div>
                          <span>Shipping Address</span>

                          <p>
                            {/* @ts-ignore */}
                            {inView?.shippingAddress[0]?.name},{" "}
                            {
                              // @ts-ignore
                              inView?.shippingAddress[0]?.addLineOne
                            }
                            ,{" "}
                            {
                              // @ts-ignore
                              inView?.shippingAddress[0]?.addLineTwo
                            }{" "}
                            ,
                            {
                              //@ts-ignore
                              inView?.shippingAddress[0]?.landmark
                            }{" "}
                            ,{/* @ts-ignore */}
                            {inView?.shippingAddress[0]?.city} ,
                            {
                              // @ts-ignore
                              inView?.shippingAddress[0]?.state
                            }{" "}
                            , - {/* @ts-ignore */}
                            {inView?.shippingAddress[0]?.pincode},
                          </p>
                        </div>
                      </div>

                      {/* <div className={styles.secondary_content}>
                        <div
                          style={{
                            gridColumn: "1/4",
                            fontSize: ".9rem",
                          }}
                        >
                          <h4 style={{ fontWeight: 500 }}>
                            About User's Device
                          </h4>
                        </div>
                        <div>
                          <span>User's Ip Address</span>
                          <p>{inView?.ipAddress || "--"}</p>
                        </div>
                        <div>
                          <span>User Agent</span>
                          <p>{inView?.userDeviceDetails?.userAgent || "--"}</p>
                        </div>
                        <div>
                          <span>Browser Name</span>
                          <p>
                            {inView?.userDeviceDetails?.browserName || "--"}
                          </p>
                        </div>
                        <div>
                          <span>Browser Version</span>
                          <p>
                            {inView?.userDeviceDetails?.browserVersion || "--"}
                          </p>
                        </div>
                        <div>
                          <span>Platform</span>
                          <p>{inView?.userDeviceDetails?.platform || "--"}</p>
                        </div>
                        <div>
                          <span>Language</span>
                          <p>{inView?.userDeviceDetails?.language || "--"}</p>
                        </div>
                        <div>
                          <span>Screen Width</span>
                          <p>
                            {inView?.userDeviceDetails?.screenWidth || "--"}
                          </p>
                        </div>
                        <div>
                          <span>Screen Height</span>
                          <p>
                            {inView?.userDeviceDetails?.screenHeight || "--"}
                          </p>
                        </div>
                        <div>
                          <span>Pixel Ratio</span>
                          <p>{inView?.userDeviceDetails?.pixelRatio || "--"}</p>
                        </div>
                      </div> */}
                    </>
                  )}
                  {tab === "remarks" && (
                    <div className={styles.remarks}>
                      <div className={styles.remark_input}>
                        <label>Add Remark</label>
                        <textarea
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                        <div className={styles.remark_btn}>
                          {/* <button onClick={handleAddRemark}>Add</button> */}
                        </div>
                      </div>
                      {inView?.remarks?.length !== 0 && (
                        <div className={styles.old_remarks}>
                          <h3>Old Remarks</h3>
                          <div className={styles.old_remarks_container}>
                            {inView?.remarks?.map((r: any, index: any) => {
                              console.log(r);
                              return (
                                <div
                                  className={styles.old_remarks_sub_container}
                                  key={index}
                                >
                                  <div className={styles.old_remarks_dot}></div>
                                  <div className={styles.old_remarks_heading}>
                                    <p>
                                      {`${r.date
                                        .split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join("/")} ${
                                        r.date.split("T")[1].split(".")[0]
                                      } by ${r.by}`}
                                    </p>
                                    <p>{r.content}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* {tab === "history" && (
                    <div className={styles.history}>
                      <div className={styles.history_container}>
                        {logs
                          ?.filter((l: any) => {
                            let from_temp = l?.from || [];
                            let to_temp = l?.to || [];

                            for (let i = 0; i < from_temp.length; i++) {
                              const single_from = from_temp[i];
                              if (single_from._id === inView._id) {
                                return true;
                              }
                            }

                            for (let i = 0; i < to_temp.length; i++) {
                              const single_to = to_temp[i];
                              if (single_to._id === inView._id) {
                                return true;
                              }
                            }

                            return false;
                          })
                          ?.map((log: any, index: any) => {
                            let from = log?.from?.filter(
                              (v: any) => v?._id === inView._id
                            );
                            let to = log?.to?.filter(
                              (v: any) => v?._id === inView._id
                            );

                            console.log("from", from);
                            console.log("to", to);

                            let Type = "";

                            let changed: any = [];

                            if (from.length === to.length) {
                              // something is updated
                              const single_from = from[0];
                              const single_to = to[0];

                              const changedFields = getChangedFields(
                                single_from,
                                single_to
                              );

                              if (
                                changedFields.length === 1 &&
                                changedFields[0].field === "remarks"
                              ) {
                                Type = "Added Remark";
                              } else {
                                Type = "Updated";
                                changed = changedFields.filter(
                                  (v) =>
                                    v.field !== "remarks" &&
                                    v.field !== "createdAt" &&
                                    v.field !== "modifiedAt"
                                );
                              }

                              console.log("changed fields ::: ", changedFields);
                            } else {
                              // Newly added
                              Type =
                                log?.type === "createdByAdmin"
                                  ? "Created By Admin"
                                  : "Submitted By User";
                              const single_to = to[0];
                            }

                            return (
                              <div
                                className={styles.history_sub_container}
                                key={index}
                              >
                                <div className={styles.history_dot}></div>
                                <div className={styles.history_heading}>
                                  <h4>{Type}</h4>
                                  <p className={styles.history_time}>
                                    {`${log?.date
                                      ?.split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("/")} ${
                                      log?.date?.split("T")[1]?.split(".")[0]
                                    } by ${log?.by}`}
                                  </p>
                                  {Type === "Added Remark" ? (
                                    <div>{to[0]?.remarks[0]?.content}</div>
                                  ) : (
                                    <>
                                      <div
                                        className={
                                          styles.history_table_container
                                        }
                                      >
                                        <table>
                                          <thead>
                                            {Type === "Created By Admin" ||
                                            Type === "Submitted By Admin" ? (
                                              <tr>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Fields
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Values
                                                </th>
                                              </tr>
                                            ) : (
                                              <tr>
                                                <th></th>
                                                <th>From</th>
                                                <th>To</th>
                                              </tr>
                                            )}
                                          </thead>
                                          <tbody>
                                            {Type === "Created By Admin" ||
                                            Type === "Submitted By Admin" ? (
                                              <>
                                                {Object.keys(to[0])
                                                  ?.filter((v) => {
                                                    return (
                                                      v !== "identifier" &&
                                                      v !==
                                                        "mobileCountryCode" &&
                                                      v !==
                                                        "altMobileCountryCode" &&
                                                      v !== "_id" &&
                                                      v !== "__v" &&
                                                      v !== "id" &&
                                                      v !== "createdAt" &&
                                                      v !== "modifiedAt"
                                                    );
                                                  })
                                                  ?.map((v: any, i: any) => {
                                                    const obj = to[0];

                                                    return (
                                                      <tr key={i}>
                                                        <td
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {contact_fields[v]}
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {v === "remarks"
                                                            ? obj?.remarks[0]
                                                                ?.content ||
                                                              "--"
                                                            : obj[v] || "--"}
                                                        </td>
                                                      </tr>
                                                    );
                                                  })}
                                              </>
                                            ) : (
                                              <>
                                                {from.map(
                                                  (fromObj: any, i: any) => {
                                                    const toObj = to[i];
                                                    const changedFields =
                                                      getChangedFields(
                                                        fromObj,
                                                        toObj
                                                      );

                                                    return changed.map(
                                                      (field: any, j: any) => {
                                                        return (
                                                          <tr key={j}>
                                                            <td>
                                                              {
                                                                contact_fields[
                                                                  field.field
                                                                ]
                                                              }
                                                            </td>
                                                            <td>
                                                              {field.fromValue}
                                                            </td>
                                                            <td>
                                                              {field.toValue}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            ) : (
              <div className={styles.table_container}>
                <table className={styles.responsive_table}>
                  <thead>
                    <tr>
                      <th>
                        {/* <input
                          type="checkbox"
                          // onChange={checkAll}
                          checked={
                            contacts.filter((val: any) => val.actions.select)
                              .length === contacts.length
                          }
                        /> */}
                      </th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>DOB</th>

                      <th>Last Activity DATE</th>

                      <th>Create Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((val: any, index: any) => {
                      return (
                        <SingleRowUser
                          data={val}
                          key={index}
                          // changeSelect={changeSelect}
                          disableForAction={disableForAction}
                          updatedContacts={updatedContacts}
                          setUpdatedContacts={setUpdatedContacts}
                          subAdmins={subAdmins}
                          onRowClick={() => {
                            setInView(val);
                            setSecondaryView(true);
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default withRouter(Users);
