import axios from "axios";

type Props = {
  url: string;
  method: string;
  data?: any;
  header?: boolean;
  bearer?: boolean;
};

export const baseUrl = "https://backend.cftcommerce.com/api/";

const api = (props: Props) => {
  const obj: any = {
    url: baseUrl + props.url,
    method: props.method,
    data: props.data,
  };

  if (props.header && props.bearer) {
    obj.headers = {
      Authorization: `Bearer ${localStorage.getItem("RETAINO_ACCESS_TOKEN")}`,
    };
  } else if (props.header) {
    obj.headers = {
      Authorization: localStorage.getItem("RETAINO_ACCESS_TOKEN"),
    };
  }
  console.log(obj);
  return axios(obj);
};

export const getAllCategoryList = (): Promise<any> => {
  // Return the promise
  return new Promise((resolve, reject) => {
    api({
      url: `products/categoryList/${localStorage.getItem("identifier")}`,
      method: "GET",
    })
      .then((res: any) => {
        console.log(res.data.data[0]);
        resolve(res.data.data[0]); // Resolve the promise with a value
      })
      .catch((error: any) => {
        console.log(error);
        reject(error); // Reject the promise with an error
      });
  });
};

export default api;
