import React from "react";
import withRouter from "../../Components/withRouter";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import styles from "../../Styles/discounts.module.css";
import InputField from "../../Components/InputField";
import TextEditor from "../../Components/TextEditor";
import { ReactComponent as Close } from "../../Assets/icons/cross.svg";
import { getFileName } from "../../Utils/getFileName";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import toast from "react-hot-toast";
import callApi from "../../Apis/callApi";
import axios from "axios";
import ToggleSwitch from "../../Components/ToggleSwitch";
import { cities } from "../../Utils/cities";
import { randomString } from "../../Utils/getAlpha";
import heic2any from "heic2any";
// test

type IState = {
  discountType: string;
  discountTitle: string;
  counponCode: string;
  description: string;
  mediaUrl: any[];
  applicability: any[];
  value: {
    number: string;
    typeName: string;
    limitDiscount: boolean;
    limitDiscountAmount: string;
  };
  minRequirement: {
    title: string;
    value: string;
  };
  useLimit: {
    oneUse: boolean;
    totalUse: {
      limit: boolean;
      count: string;
    };
  };
  bulkDiscount: boolean;
  bulkDiscountDetails: {
    relation: string;
    quantity1: string;
    quantity2: string;
    discountType: string;
    discountValue: string;
  }[];
  locationType: string;
  location: string[];
  schedule: {
    startEnable: boolean;
    start: { date: any; time: any };
    endEnable: boolean;
    end: {
      date: string;
      time: string;
    };
  }[];
  visibility: any;
  categories: any[];
  products: any[];
};

class AddUpdateDiscount extends React.Component<any, IState> {
  state = {
    discountType: "",
    discountTitle: "",
    counponCode: "",
    description: "",
    mediaUrl: [],
    applicability: [],
    value: {
      number: "",
      typeName: "",
      limitDiscount: false,
      limitDiscountAmount: "",
    },
    minRequirement: {
      title: "",
      value: "",
    },
    useLimit: {
      oneUse: false,
      totalUse: {
        limit: false,
        count: "",
      },
    },
    bulkDiscount: false,
    bulkDiscountDetails: [
      {
        relation: "",
        quantity1: "",
        quantity2: "",
        discountType: "",
        discountValue: "",
      },
    ],
    locationType: "", // not for api
    location: [],
    schedule: [
      {
        startEnable: false,
        start: { date: "", time: "" },
        endEnable: false,
        end: {
          date: "",
          time: "",
        },
      },
    ],
    visibility: {
      banner: false,
      indexPrompt: false,
      productPage: false,
    },
    products: [], // not for api
    categories: [], // not for api
  };

  componentDidMount(): void {
    this.getData();
  }

  getData = () => {
    axios({
      url:
        "https://backend.cftcommerce.com/api/products/?identifier=" +
        localStorage.getItem("identifier") +
        "&isVariant=false",
      method: "",
    })
      .then((res: any) => {
        this.setState({ products: res.data.products });
        this.getCategories();
      })
      .catch((err: any) => {
        this.getCategories();
      });
  };

  getCategories = () => {
    axios({
      url:
        "https://backend.cftcommerce.com/api/products/categoryList/" +
        localStorage.getItem("identifier"),
      method: "GET",
    })
      .then((res: any) => {
        this.setState({ categories: res.data.data[0].distinctCategory });
        if (this.props.router.params.id) {
          this.getDiscount();
        }
      })
      .catch((err: any) => {
        console.log(err.response);
        this.getDiscount();
      });
  };

  getDiscount = () => {
    axios({
      url:
        "https://backend.cftcommerce.com/api/discounts/" +
        this.props.router.params.id,
      method: "GET",
    })
      .then((res: any) => {
        let data = res.data.discount;

        console.log(data);

        this.setState({
          discountType: data.discountType,
          discountTitle: data.discountTitle,
          counponCode: data.counponCode,
          description: data.description,
          mediaUrl: data.mediaUrl.map((val: string) => ({
            url: val,
            obj: null,
          })),
          applicability: data.applicability,
          value: data?.value
            ? data.value
            : {
                number: "",
                typeName: "",
                limitDiscount: false,
                limitDiscountAmount: "",
              },
          minRequirement: data?.minRequirement
            ? data.minRequirement
            : {
                title: "",
                value: "",
              },
          useLimit: data.useLimit,
          bulkDiscount: data.bulkDiscount,
          bulkDiscountDetails: data.bulkDiscountDetails,
          locationType: data.location.length === 0 ? "All" : "Some", // not for api
          location: data.location,
          schedule:
            data.schedule.length === 0
              ? [
                  {
                    startEnable: false,
                    start: { date: "", time: "" },
                    endEnable: false,
                    end: {
                      date: "",
                      time: "",
                    },
                  },
                ]
              : data.schedule,
          visibility: data.visibility,
        });
      })
      .catch((err: any) => {
        console.log(err.response);
      });
  };

  addApplicability = () => {
    let temp: any = [...this.state.applicability];

    temp.push({
      applicabilityTitle: "",
      applicabilityValue: "",
      productId: "",
    });

    this.setState({ applicability: temp });
  };

  handleImage = async (e: any) => {
    let temp: any = [...this.state.mediaUrl];

    const file = e.target.files[0];

    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      temp.push({
        url: URL.createObjectURL(convertedFile),
        obj: convertedFile,
      });
    } else
      temp.push({
        url: URL.createObjectURL(e.target.files[0]),
        obj: e.target.files[0],
      });

    this.setState({ mediaUrl: temp });
  };

  handleHeadClick = (e: any) => {
    if (e.target.name === "btn1") {
      this.setup("active");
    } else if (e.target.name === "btn2") {
      this.setup("inactive");
    } else if (e.target.name === "btn3") {
      this.props.router.navigate("/discounts");
    }
  };

  setup = async (status: string) => {
    let temp: any = { ...this.state, status };

    delete temp.products;
    delete temp.categories;

    // if we don't have values in minRequirement and value then we will delete them
    if (temp.minRequirement.title === "") {
      delete temp.minRequirement;
    }

    if (temp.value.typeName === "") {
      delete temp.value;
    }

    temp.applicability = temp.applicability.map((val: any) => {
      if (val.productId) {
        return {
          applicabilityTitle: val.applicabilityTitle,
          applicabilityValue: val.applicabilityValue,
          productId: val.productId,
        };
      } else {
        return {
          applicabilityTitle: val.applicabilityTitle,
          applicabilityValue: val.applicabilityValue,
        };
      }
    });

    temp.identifier = localStorage.getItem("identifier");
    temp.priority = 0;

    let mediaaaa = await this.uploadMediaUrl();

    temp.mediaUrl = mediaaaa;

    temp.schedule = temp.schedule.map((val: any) => {
      return {
        ...val,
        start: val?.startEnable
          ? {
              date: new Date(val.start.date),
              time: new Date(`1970-01-01T${val.start.time}`),
            }
          : {},
        end: val?.endEnable
          ? {
              date: new Date(val.end.date),
              time: new Date(`1970-01-01T${val.end.time}`),
            }
          : {},
      };
    });

    temp.location = this.state.location;

    this.submitData(temp);
  };

  uploadMediaUrl = () => {
    let temp: any = this.state.mediaUrl;

    return new Promise(async (resolve, reject) => {
      let temp_url: any = [];

      for (let i = 0; i < temp.length; i++) {
        if (temp[i].obj) {
          const filename = getFileName(temp[i].obj);

          try {
            let _ = await UploadImage({ file: temp[i].obj, name: filename });
            temp_url.push(filename);
          } catch (err) {
            toast.error("error occured while uploading media");
            reject(err);
          }
        } else {
          temp_url.push(temp[i].url);
        }
      }

      resolve(temp_url);
    });
  };

  submitData = (temp: any) => {
    callApi({
      type: "discounts",
      url: this.props.router.params.id ? "/" + this.props.router.params.id : "",
      method: this.props.router.params.id ? "PATCH" : "POST",
      header: false,
      data: {
        ...temp,
      },
    })
      .then((res: any) => {
        this.props.router.navigate("/discounts");
        toast.success("Discount Added Successfully!");
      })
      .catch((err: any) => {
        toast.error("Something went wrong");
      });
  };

  checkValidation = () => {};

  render(): React.ReactNode {
    const { id } = this.props.router.params;

    return (
      <>
        <Content>
          <ContentHead
            title={id ? "Update Discount" : "New Discount"}
            showBtn1={isUserAllowedToEdit(this.props.admin)}
            showBtn2={isUserAllowedToEdit(this.props.admin)}
            showBtn3={true}
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text="Save"
            btn2Text="Save as Draft"
            btn3Text="Cancel"
            btn1type="filled"
            btn2type="outlined"
            btn3type="outlined_del"
            onClickHandler={this.handleHeadClick}
          />
          <ContentBody>
            {/* discount type */}
            <div style={{ marginBottom: "20px" }}>
              <InputField
                type="select"
                options={[
                  {
                    title: "Amount off Products (Product discount)",
                    value: "aop",
                  },
                  {
                    title: "Amount off Order ( Order discount)",
                    value: "aoo",
                  },
                  {
                    title: "Buy X get Y ( Product discount)",
                    value: "bxgy",
                  },
                  {
                    title: "Free Shipping ( Shipping discount)",
                    value: "fs",
                  },
                ]}
                accessText="title"
                accessValue="value"
                isLabel={true}
                lable="Discount Type"
                value={this.state.discountType}
                onChange={(e: any) => {
                  this.setState({ discountType: e.target.value });
                  if (e.target.value === "bxgy") {
                    this.setState({
                      applicability: [
                        {
                          applicabilityTitle: "CustomerBuy",
                          productId: "",
                          applicabilityValue: "",
                        },
                        {
                          applicabilityTitle: "CustomerGets",
                          productId: "",
                          applicabilityValue: "",
                        },
                      ],
                    });
                  }
                }}
              />
            </div>

            {/* discount details */}
            <div className={`${styles.card}`}>
              <div className={styles.grid_2}>
                <InputField
                  type="text"
                  isLabel={true}
                  lable="Coupon Title (Max 60 Characters)"
                  value={this.state.discountTitle}
                  onChange={(e: any) =>
                    this.setState({ discountTitle: e.target.value })
                  }
                />
                <div style={{ position: "relative" }}>
                  <InputField
                    type="text"
                    isLabel={true}
                    lable="Coupon Code"
                    value={this.state.counponCode}
                    onChange={(e: any) =>
                      this.setState({ counponCode: e.target.value })
                    }
                  />
                  <button
                    style={{
                      position: "absolute",
                      right: "15px",
                      bottom: "10px",
                      height: "30px",
                      borderRadius: "10px",
                      width: "fit-content",
                      border: "none",
                      background: "transparent",
                      cursor: "pointer",
                      color: "#36619b",
                      fontSize: ".9rem",
                    }}
                    onClick={() => {
                      this.setState({ counponCode: randomString(8) });
                    }}
                  >
                    Auto - Generate
                  </button>
                </div>
              </div>

              <InputField
                type={"text"}
                isLabel={true}
                lable="Description (Max 120 Character)"
                value={this.state.description}
                onChange={(e: any) =>
                  this.setState({ description: e.target.value })
                }
              />

              <div style={{ marginTop: "0px", padding: 0, gap: "10px" }}>
                <h2>Coupon Image</h2>
                {this.state.mediaUrl.length === 0 ? (
                  <div className={styles.select_media}>
                    +
                    <input
                      type="file"
                      accept=".heic,image/*"
                      onChange={this.handleImage}
                    />
                  </div>
                ) : (
                  <ul className={styles.selected_media}>
                    {this.state.mediaUrl.map((val: any, index: any) => {
                      return (
                        <li>
                          <Close
                            onClick={() => {
                              let temp = [...this.state.mediaUrl];
                              temp.splice(index, 1);
                              this.setState({ mediaUrl: temp });
                            }}
                          />
                          <img
                            src={
                              val.obj
                                ? val.url
                                : process.env.REACT_APP_IMAGE +
                                  folder_name() +
                                  val.url
                            }
                            alt=""
                          />
                        </li>
                      );
                    })}
                    <li className={styles.small_select}>
                      +
                      <input
                        type="file"
                        accept="image/*"
                        onChange={this.handleImage}
                      />
                    </li>
                  </ul>
                )}
              </div>
            </div>

            <div className={styles.grid_2} style={{ marginTop: "20px" }}>
              {/* Applicability */}
              <div className={styles.card}>
                <h2>
                  Applicability
                  {this.state.discountType !== "bxgy" && (
                    <span
                      className={styles.add_btn}
                      onClick={this.addApplicability}
                    >
                      Add
                    </span>
                  )}
                </h2>
                {this.state.discountType !== "bxgy" &&
                  this.state.applicability.map((val: any, index: any) => {
                    return (
                      <div
                        style={{
                          padding: "20px",
                          border: "1px solid lightgrey",
                          borderRadius: "15px",
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "20px",
                          position: "relative",
                        }}
                      >
                        <Close
                          className={styles.close_icon}
                          onClick={() => {
                            let temp = [...this.state.applicability];
                            temp.splice(index, 1);
                            this.setState({ applicability: temp });
                          }}
                        />

                        <InputField
                          type="select"
                          isLabel={true}
                          lable=""
                          options={[
                            {
                              title: "All Products / Entire cart",
                              value: "All",
                            },
                            { title: "Category", value: "Category" },
                            { title: "Product", value: "Product" },
                          ]}
                          accessText="title"
                          accessValue="value"
                          value={val.applicabilityTitle}
                          onChange={(e: any) => {
                            let temp: any = [...this.state.applicability];
                            temp[index].applicabilityTitle = e.target.value;
                            this.setState({ applicability: temp });
                          }}
                        />
                        {val.applicabilityTitle === "Category" && (
                          <InputField
                            type="select"
                            isLabel={true}
                            lable=""
                            options={this.state.categories.map((val: any) => ({
                              title: val,
                              value: val,
                            }))}
                            accessText="title"
                            accessValue="value"
                            value={val.applicabilityValue}
                            onChange={(e: any) => {
                              let temp: any = [...this.state.applicability];
                              temp[index].applicabilityValue = e.target.value;
                              this.setState({ applicability: temp });
                            }}
                            placeholder="Select Category"
                          />
                        )}

                        {val.applicabilityTitle === "Product" && (
                          <InputField
                            type="select"
                            isLabel={true}
                            lable=""
                            options={this.state.products.map((val: any) => {
                              return {
                                title: val.title,
                                value: val._id,
                              };
                            })}
                            accessText="title"
                            accessValue="value"
                            value={val.productId}
                            onChange={(e: any) => {
                              let temp: any = [...this.state.applicability];
                              temp[index].productId = e.target.value;
                              console.log(temp);

                              this.setState({ applicability: temp });
                            }}
                            placeholder="Select Product"
                          />
                        )}
                      </div>
                    );
                  })}

                {this.state.discountType === "bxgy" && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr",
                      gap: "20px",
                      padding: "20px",
                      position: "relative",
                      borderRadius: "15px",
                      border: "1px solid lightgrey",
                    }}
                  >
                    {this.state.applicability.map((val: any, index: any) => {
                      return (
                        <div style={{}}>
                          <InputField
                            type="select"
                            isLabel={true}
                            lable={
                              val.applicabilityTitle === "CustomerGets"
                                ? "Customer Gets"
                                : "Customer Buys"
                            }
                            options={[
                              ...this.state.products.map((value: any) => ({
                                title: value.title,
                                value: value._id,
                              })),
                            ]}
                            accessText="title"
                            accessValue="value"
                            value={val.productId}
                            onChange={(e: any) => {
                              let temp: any = [...this.state.applicability];
                              temp[index].productId = e.target.value;
                              this.setState({ applicability: temp });
                            }}
                            placeholder="Select Product"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              {/* Value / At a discount value */}
              <div className={styles.card}>
                <h2>
                  {this.state.discountType === "bxgy"
                    ? "At a discounted value"
                    : "Value"}
                </h2>

                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      height: "50px",
                      display: "flex",
                      flexDirection: "row",
                      border: "1px solid grey",
                      width: "fit-content",
                      borderRadius: "10px",
                      overflow: "hidden",
                      flexShrink: 0,
                    }}
                  >
                    <div
                      style={
                        this.state.value.typeName === "percentage"
                          ? {
                              padding: "10px 15px",
                              cursor: "pointer",
                              borderRight: "2px solid grey",
                              background: "lightgrey",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              width: "124px",
                              flexShrink: 0,
                            }
                          : {
                              padding: "10px 15px",
                              cursor: "pointer",
                              borderRight: "2px solid grey",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              width: "124px",
                              flexShrink: 0,
                            }
                      }
                      onClick={() => {
                        this.setState({
                          value: {
                            ...this.state.value,
                            typeName: "percentage",
                          },
                        });
                      }}
                    >
                      Percentage
                    </div>
                    <div
                      style={
                        this.state.value.typeName === "amount" ||
                        this.state.value.typeName === "free"
                          ? {
                              padding: "10px 15px",
                              cursor: "pointer",
                              background: "lightgrey",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              minWidth: "124px",
                              flexShrink: 0,
                            }
                          : {
                              padding: "10px 15px",
                              cursor: "pointer",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              minWidth: "124px",
                              flexShrink: 0,
                            }
                      }
                      onClick={() => {
                        this.setState({
                          value: {
                            ...this.state.value,
                            typeName:
                              this.state.discountType === "bxgy"
                                ? "free"
                                : "amount",
                          },
                        });
                      }}
                    >
                      {this.state.discountType === "bxgy"
                        ? "Free"
                        : "Fixed Amount"}
                    </div>
                  </div>

                  {this.state.value.typeName !== "free" && (
                    <div className={styles.custom_field}>
                      {this.state.value.typeName === "amount" && (
                        <div className={styles.rupees}>₹</div>
                      )}
                      <input
                        type="number"
                        placeholder={
                          this.state.value.typeName === "amount" ? "0.0" : ""
                        }
                        value={this.state.value.number}
                        onChange={(e: any) => {
                          this.setState({
                            value: {
                              ...this.state.value,
                              number: e.target.value,
                            },
                          });
                        }}
                      />
                      {this.state.value.typeName === "percentage" && (
                        <div className={styles.rupees}>%</div>
                      )}
                    </div>
                  )}
                </div>

                {this.state.value.typeName !== "free" && (
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      checked={this.state.value.limitDiscount}
                      onClick={(e: any) => {
                        if (e.target.checked) {
                          this.setState({
                            value: {
                              ...this.state.value,
                              limitDiscountAmount: "",
                            },
                          });
                        }

                        this.setState({
                          value: {
                            ...this.state.value,
                            limitDiscount: e.target.checked,
                          },
                        });
                      }}
                    />
                    <span>Limit Discount Amount</span>
                  </div>
                )}

                {this.state.value.limitDiscount && (
                  <InputField
                    type="number"
                    isLabel={true}
                    lable="Maximum Discount Amount (in Rupees)"
                    value={this.state.value.limitDiscountAmount}
                    onChange={(e: any) => {
                      this.setState({
                        value: {
                          ...this.state.value,
                          limitDiscountAmount: e.target.value,
                        },
                      });
                    }}
                  />
                )}
              </div>

              {/* Minimum Purchase Requirement */}
              {this.state.discountType !== "dxgy" && (
                <div className={styles.card}>
                  <h2>Minimum Purchase Requirement</h2>
                  <InputField
                    type="select"
                    isLabel={true}
                    options={[
                      { title: "No Minimum Requirements", value: "None" },
                      { title: "Minimum Purchase Amount (₹)", value: "Amount" },
                      {
                        title: "Minimum Quantity of Items",
                        value: "ProductCount",
                      },
                    ]}
                    accessText="title"
                    accessValue="value"
                    lable="Type"
                    value={this.state.minRequirement.title}
                    onChange={(e: any) => {
                      this.setState({
                        minRequirement: {
                          ...this.state.minRequirement,
                          title: e.target.value,
                        },
                      });
                    }}
                  />
                  {this.state.minRequirement.title === "Amount" && (
                    <div className={styles.custom_field}>
                      {this.state.minRequirement.title === "Amount" && (
                        <div className={styles.rupees}>₹</div>
                      )}
                      <input
                        type="number"
                        placeholder={
                          this.state.minRequirement.title === "Amount"
                            ? "0.0"
                            : ""
                        }
                        value={this.state.minRequirement.value}
                        onChange={(e: any) => {
                          this.setState({
                            minRequirement: {
                              ...this.state.minRequirement,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  )}

                  {this.state.minRequirement.title === "ProductCount" && (
                    <InputField
                      type="number"
                      isLabel={true}
                      lable="Minimum Quantity"
                      value={this.state.minRequirement.value}
                      onChange={(e: any) => {
                        this.setState({
                          minRequirement: {
                            ...this.state.minRequirement,
                            value: e.target.value,
                          },
                        });
                      }}
                    />
                  )}
                </div>
              )}

              {/* Maximum discount uses */}
              <div className={styles.card}>
                <h2>Maximum discount uses</h2>
                <div className={styles.radio}>
                  <input
                    type="checkbox"
                    checked={this.state.useLimit.totalUse.limit}
                    onChange={(e: any) => {
                      this.setState({
                        useLimit: {
                          ...this.state.useLimit,
                          totalUse: {
                            ...this.state.useLimit.totalUse,
                            limit: e.target.checked,
                          },
                        },
                      });
                    }}
                  />
                  <span>
                    Limit number of times this discount can be used in total
                  </span>
                </div>

                {this.state.useLimit.totalUse.limit && (
                  <InputField
                    type="number"
                    isLabel={false}
                    lable="Uses"
                    value={this.state.useLimit.totalUse.count}
                    onChange={(e: any) => {
                      this.setState({
                        useLimit: {
                          ...this.state.useLimit,
                          totalUse: {
                            ...this.state.useLimit.totalUse,
                            count: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                )}

                <div className={styles.radio}>
                  <input
                    type="checkbox"
                    checked={this.state.useLimit.oneUse}
                    onChange={(e: any) => {
                      this.setState({
                        useLimit: {
                          ...this.state.useLimit,
                          oneUse: e.target.checked,
                        },
                      });
                    }}
                  />
                  <span>Limit to one use per customer</span>
                </div>
              </div>

              {/* Bulk Discount */}
              {this.state.discountType !== "bxgy" && (
                <div className={styles.card}>
                  <h2>
                    Bulk Discount
                    {this.state.bulkDiscount && (
                      <span
                        className={styles.add_btn}
                        onClick={() => {
                          let temp: any = [...this.state.bulkDiscountDetails];
                          temp.push({
                            relation: "",
                            quantity1: "",
                            quantity2: "",
                            discount_typeName: "",
                            discount_value: "",
                          });
                          this.setState({ bulkDiscountDetails: temp });
                        }}
                      >
                        Add
                      </span>
                    )}
                  </h2>

                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      checked={this.state.bulkDiscount}
                      onClick={(e: any) => {
                        this.setState({ bulkDiscount: e.target.checked });
                      }}
                    />{" "}
                    <span>Enable bulk discount</span>
                  </div>
                  {this.state.bulkDiscount &&
                    this.state.bulkDiscountDetails.map(
                      (val: any, index: any) => {
                        return (
                          <div
                            style={{
                              padding: "20px",
                              border: "1px solid lightgrey",
                              borderRadius: "15px",
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Close
                              className={styles.close_icon}
                              onClick={() => {
                                let temp: any = [
                                  ...this.state.bulkDiscountDetails,
                                ];
                                temp.splice(index, 1);
                                this.setState({ bulkDiscountDetails: temp });
                              }}
                            />
                            <InputField
                              type="select"
                              options={[
                                { title: "Less then", value: "lessthen" },
                                { title: "Greater then", value: "greaterthen" },
                                {
                                  title: "Less then equal to",
                                  value: "lessthenEqualTo",
                                },
                                {
                                  title: "Greater then equal to",
                                  value: "greaterthenEqualTo",
                                },
                                { title: "Between", value: "between" },
                              ]}
                              accessText="title"
                              accessValue="value"
                              isLabel={true}
                              lable="Relation"
                              value={val.relation}
                              onChange={(e: any) => {
                                let temp: any = [
                                  ...this.state.bulkDiscountDetails,
                                ];
                                temp[index].relation = e.target.value;
                                this.setState({ bulkDiscountDetails: temp });
                              }}
                              placeholder="Select Relation"
                            />
                            {val.relation === "between" && <div></div>}

                            <InputField
                              type="number"
                              isLabel={true}
                              lable={
                                val.relation === "between"
                                  ? "Minimum Quantity"
                                  : "Quantity of Items"
                              }
                              value={val.quantity1}
                              onChange={(e: any) => {
                                let temp: any = [
                                  ...this.state.bulkDiscountDetails,
                                ];
                                temp[index].quantity1 = e.target.value;
                                this.setState({ bulkDiscountDetails: temp });
                              }}
                            />

                            {val.relation === "between" && (
                              <InputField
                                type="number"
                                isLabel={true}
                                lable={
                                  val.relation === "between"
                                    ? "Maximum Quantity"
                                    : "Quantity of Items"
                                }
                                value={val.quantity2}
                                onChange={(e: any) => {
                                  let temp: any = [
                                    ...this.state.bulkDiscountDetails,
                                  ];
                                  temp[index].quantity2 = e.target.value;
                                  this.setState({ bulkDiscountDetails: temp });
                                }}
                              />
                            )}

                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                                alignItems: "flex-end",
                                gridColumn: "1/3",
                              }}
                            >
                              <div
                                style={{
                                  height: "60px",
                                  display: "flex",
                                  flexDirection: "row",
                                  border: "1px solid grey",
                                  width: "fit-content",
                                  borderRadius: "10px",
                                  overflow: "hidden",
                                  flexShrink: 0,
                                }}
                              >
                                <div
                                  style={
                                    val.discountType === "percentage"
                                      ? {
                                          padding: "10px 15px",
                                          cursor: "pointer",
                                          borderRight: "2px solid grey",
                                          background: "lightgrey",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          width: "124px",
                                          flexShrink: 0,
                                        }
                                      : {
                                          padding: "10px 15px",
                                          cursor: "pointer",
                                          borderRight: "2px solid grey",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          width: "124px",
                                          flexShrink: 0,
                                        }
                                  }
                                  onClick={() => {
                                    let temp: any = [
                                      ...this.state.bulkDiscountDetails,
                                    ];
                                    temp[index].discountType = "percentage";
                                    this.setState({
                                      bulkDiscountDetails: temp,
                                    });
                                  }}
                                >
                                  Percentage
                                </div>
                                <div
                                  style={
                                    val.discountType === "fixedAmount"
                                      ? {
                                          padding: "10px 15px",
                                          cursor: "pointer",
                                          background: "lightgrey",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          minWidth: "124px",
                                          flexShrink: 0,
                                        }
                                      : {
                                          padding: "10px 15px",
                                          cursor: "pointer",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          minWidth: "124px",
                                          flexShrink: 0,
                                        }
                                  }
                                  onClick={() => {
                                    let temp: any = [
                                      ...this.state.bulkDiscountDetails,
                                    ];
                                    temp[index].discountType = "fixedAmount";
                                    this.setState({
                                      bulkDiscountDetails: temp,
                                    });
                                  }}
                                >
                                  Fixed Amount
                                </div>
                              </div>

                              <div className={styles.custom_field}>
                                {val.discountType === "fixedAmount" && (
                                  <div className={styles.rupees}>₹</div>
                                )}
                                <input
                                  type="number"
                                  placeholder={
                                    val.discount_typeName === "fixedAmount"
                                      ? "0.0"
                                      : ""
                                  }
                                  value={val.discountValue}
                                  onChange={(e: any) => {
                                    let temp: any = [
                                      ...this.state.bulkDiscountDetails,
                                    ];
                                    temp[index].discountValue = e.target.value;
                                    this.setState({
                                      bulkDiscountDetails: temp,
                                    });
                                  }}
                                />
                                {val.discountType === "percentage" && (
                                  <div className={styles.rupees}>%</div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              )}

              {/* Location */}
              <div className={styles.card}>
                <h2>Location</h2>
                <div
                  className={styles.radio}
                  onClick={() =>
                    this.setState({ locationType: "All", location: [] })
                  }
                >
                  <input
                    type="radio"
                    checked={this.state.locationType === "All" ? true : false}
                  />
                  <span>All Cities</span>
                </div>
                <div
                  className={styles.radio}
                  onClick={() =>
                    this.setState({ locationType: "Some", location: [] })
                  }
                >
                  <input
                    type="radio"
                    checked={this.state.locationType === "Some" ? true : false}
                  />
                  <span>Selected Cities</span>
                </div>
                {this.state.locationType === "Some" && (
                  <InputField
                    type="select"
                    isLabel={true}
                    lable="Cities"
                    options={cities
                      .filter((val: any) => {
                        let id: any = val.id;
                        // @ts-ignore
                        return !this.state.location.includes(id);
                      })
                      .map((val: any) => {
                        return {
                          title: val.name + ", " + val.state,
                          value: val.id,
                        };
                      })}
                    accessText="title"
                    accessValue="title"
                    value={""}
                    onChange={(e: any) => {
                      let val = e.target.value;
                      let temp: any = [...this.state.location];

                      temp.push({ cityName: val });

                      this.setState({
                        location: temp,
                      });
                    }}
                    placeholder="Select City"
                  />
                )}
                {this.state.locationType === "Some" && (
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}
                  >
                    {this.state.location.map((val: any, index: any) => {
                      console.log(val);
                      let temp = cities.filter((value) => value.id === val)[0];

                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            background: "#eff5fc",
                            color: "#36619b",
                            padding: "10px 15px",
                            borderRadius: "5px",
                            gap: "5px",
                          }}
                        >
                          <p>{val.cityName}</p>
                          <Close
                            style={{
                              height: "15px",
                              cursor: "pointer",
                              fill: "#36619b",
                            }}
                            onClick={() => {
                              let temp = [...this.state.location];
                              temp.splice(index, 1);
                              this.setState({
                                location: temp,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              {/*  Schedule */}
              <div className={styles.card}>
                <h2>Schedule</h2>

                <div className={styles.radio}>
                  <input
                    type="checkbox"
                    checked={this.state.schedule[0].startEnable}
                    onChange={(e: any) => {
                      let temp = [...this.state.schedule];
                      temp[0].startEnable = e.target.checked;
                      this.setState({ schedule: temp });
                    }}
                  />
                  <span>Schedule the Discount</span>
                </div>

                {!this.state.schedule[0].startEnable && (
                  <p style={{ color: "grey" }}>
                    If not selected, the discount will be live upon saving the
                    discount.
                  </p>
                )}

                {this.state.schedule[0].startEnable && (
                  <div
                    style={{
                      width: "100%",
                      height: "fit-content",
                      padding: "0px",
                      borderRadius: "15px",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "20px",
                      position: "relative",
                    }}
                  >
                    <InputField
                      type="date"
                      isLabel={true}
                      lable="Start Date"
                      value={this.state.schedule[0].start.date}
                      onChange={(e: any) => {
                        console.log(new Date(e.target.value).toISOString());
                        let temp = [...this.state.schedule];
                        // @ts-ignore
                        temp[0].start.date = e.target.value;

                        this.setState({ schedule: temp });
                      }}
                    />

                    <InputField
                      type="time"
                      isLabel={true}
                      lable="Start Time"
                      value={this.state.schedule[0].start.time}
                      onChange={(e: any) => {
                        let temp = [...this.state.schedule];
                        //@ts-ignore
                        temp[0].start.time = e.target.value;

                        this.setState({ schedule: temp });
                      }}
                    />

                    <div className={styles.radio}>
                      <input
                        type="checkbox"
                        checked={this.state.schedule[0].endEnable}
                        onChange={(e: any) => {
                          let temp = [...this.state.schedule];
                          temp[0].endEnable = e.target.checked;
                          this.setState({ schedule: temp });
                        }}
                      />
                      <span>Enable Discount Expiry</span>
                    </div>
                  </div>
                )}

                {this.state.schedule[0].endEnable && (
                  <div
                    style={{
                      width: "100%",
                      height: "fit-content",
                      padding: "0px",
                      borderRadius: "15px",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "20px",
                      position: "relative",
                    }}
                  >
                    <InputField
                      type="date"
                      isLabel={true}
                      lable="End Date"
                      value={this.state.schedule[0].end.date}
                      onChange={(e: any) => {
                        let temp = [...this.state.schedule];
                        //@ts-ignore
                        temp[0].end.date = e.target.value;

                        this.setState({ schedule: temp });
                      }}
                    />

                    <InputField
                      type="time"
                      isLabel={true}
                      lable="End Time"
                      value={this.state.schedule[0].end.time}
                      onChange={(e: any) => {
                        let temp = [...this.state.schedule];
                        //@ts-ignore
                        temp[0].end.time = e.target.value;

                        this.setState({ schedule: temp });
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Visibility */}
              <div className={styles.card}>
                <h2>Visibility</h2>
                <div className={styles.toggle_switch}>
                  <p>Banner</p>
                  <ToggleSwitch
                    value={this.state.visibility.banner}
                    onClick={() => {
                      let temp = this.state.visibility;
                      temp.banner = !temp.banner;
                      this.setState({ visibility: temp });
                    }}
                  />
                </div>
                <div className={styles.toggle_switch}>
                  <p>Index</p>
                  <ToggleSwitch
                    value={this.state.visibility.indexPrompt}
                    onClick={() => {
                      let temp = this.state.visibility;
                      temp.indexPrompt = !temp.indexPrompt;
                      this.setState({ visibility: temp });
                    }}
                  />
                </div>
                <div className={styles.toggle_switch}>
                  <p>Product Page</p>
                  <ToggleSwitch
                    value={this.state.visibility.productPage}
                    onClick={() => {
                      let temp = this.state.visibility;
                      temp.productPage = !temp.productPage;
                      this.setState({ visibility: temp });
                    }}
                  />
                </div>
              </div>
            </div>
          </ContentBody>
        </Content>
      </>
    );
  }
}

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.discount_access.write) return true;

  return false;
};

export default withRouter(AddUpdateDiscount);
