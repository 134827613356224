import React, { useEffect, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import dstyles from "../../Styles/discounts.module.css";
import InputField from "../../Components/InputField";
import { ReactComponent as Close } from "../../Assets/icons/cross.svg";
import axios, { AxiosError, AxiosResponse } from "axios";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import toast from "react-hot-toast";
import { getFileName } from "../../Utils/getFileName";
import { addShoppableVideo } from "../../Apis/shoppableApi";
import callApi from "../../Apis/callApi";
import { useAdminContext } from "../../Store/adminContext";

/**
 *  Product Media Model
 *  identifier
 *  productId : [ ]
 *  productVideoUrl: string
 *  productImageUrl: string
 *  title: string
 *  priority: number
 *  status: 'active', 'inactive', 'draft'
 */

const AddUpdateProductVideo = () => {
  const [title, setTitle] = useState("");
  const [mediaUrl, setMediaUrl] = React.useState<any>([]);
  const [product, setProduct]: any = useState([]);
  const [selectedProduct, setSelectedProduct]: any = useState([]);
  const [thumbnail, setThumbnail]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus]: any = useState("active");
  const { admin }: any = useAdminContext();
  const [insta, setInsta] = useState(false);

  const navigate: any = useNavigate();
  const params = useParams();

  useEffect(() => {
    checkIfAllowed();
  }, []);

  const checkIfAllowed = () => {
    if (params.id) {
      getProduct();
    } else {
      axios({
        url: "https://backend.cftcommerce.com/api/admins/getWebsiteData",
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res: any) => {
          let allowed = 0;
          if (res.data.data.productVideo.planType === "basic") {
            allowed = 5;
          } else if (res.data.data.productVideo.planType === "advance") {
            allowed = 20;
          } else if (res.data.data.productVideo.planType === "unlimited") {
            allowed = 100000;
          } else {
            allowed = 5;
          }
          axios({
            url: `https://backend.cftcommerce.com/api/productmedia?identifier=${localStorage.getItem(
              "identifier"
            )}&page=1&limit=10000000`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
            },
          })
            .then((r: any) => {
              const data = r.data?.productMedias;

              if (allowed == data.length) {
                navigate("/product-video");
                toast.error(
                  "Your shoppable video plan is at its limit. Please contact ShopIQ support to increase the limit."
                );
              }

              getProduct();
            })
            .catch((er: any) => {
              // navigate("/product-video");
              // toast.error("Something went wrong while checking permissions.");
              getProduct();
            });
        })
        .catch(() => {
          navigate("/product-video");
          toast.error("Something went wrong while checking permissions.");
        });
    }
  };

  const getData = (products: any[]) => {
    setLoading(true);
    axios({
      url: "https://backend.cftcommerce.com/api/productmedia/" + params.id,
      method: "GET",
    })
      .then((res: AxiosResponse) => {
        const data = res.data.productMedia;
        setTitle(data.title);
        setSelectedProduct(
          products.filter((val) => {
            return data.productId.includes(val._id);
          })
        );
        if (data?.productImageUrl)
          setThumbnail([{ url: data.productImageUrl, obj: null }]);
        if (data?.productVideoUrl)
          setMediaUrl([{ url: data.productVideoUrl, obj: null }]);
        navigate({
          pathname: "/product-video/update/" + params.id,
          search: createSearchParams({
            title: title,
            products: products
              .filter((val) => {
                return data.productId.includes(val._id);
              })
              .map((val: any) => val._id),
            videos: data?.productVideoUrl ? [data.productVideoUrl] : [],
            thumbnail: data?.productImageUrl ? [data.productImageUrl] : [],
          }).toString(),
        });
        setLoading(false);
      })
      .catch((err: AxiosError) => {});
  };

  const getProduct = () => {
    setLoading(true);
    axios({
      url:
        "https://backend.cftcommerce.com/api/products/for-admin?identifier=" +
        localStorage.getItem("identifier"),
      method: "GET",
    })
      .then((res: any) => {
        setProduct(res.data);
        if (params.id) {
          getData(res.data);
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleImage = (e: any) => {
    const file = e.target.files[0];
    const maxFileSize = 20 * 1024 * 1024;

    if (file.size > maxFileSize) {
      toast.error("File size exceeds 20MB");
      return;
    }

    let temp: any = [...mediaUrl];

    setLoading(true);

    addShoppableVideo({ files: e.target.files[0], id: "" })
      .then((response: any) => {
        console.log(response.data.data.productVideo);
        temp.push({
          url: response.data.data.productVideo,
          obj: null,
        });
        setMediaUrl(temp);
        toast.success("Video Uploaded successfully.");
        navigate({
          pathname: params?.id
            ? `/product-video/update/${params?.id}`
            : "/product-video/add",
          search: createSearchParams({
            title: title,
            products: selectedProduct.map((val: any) => val._id),
            videos: temp.map((val: any) => val.url),
            thumbnail: thumbnail.map((val: any) => val.url),
          }).toString(),
        });
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("something went wrong");
      });
  };

  const handleThumbnail = async (e: any) => {
    let temp: any = [...thumbnail];

    setLoading(true);

    const t_name = getFileName(e.target.files[0]);
    let res = await UploadImage({ file: e.target.files[0], name: t_name });

    setLoading(false);
    temp.push({
      url: `${process.env.REACT_APP_IMAGE}${folder_name()}${t_name}`,
      obj: null,
    });

    setThumbnail(temp);

    navigate({
      pathname: params?.id
        ? `/product-video/update/${params?.id}`
        : "/product-video/add",
      search: createSearchParams({
        title: title,
        products: selectedProduct.map((val: any) => val._id),
        videos: mediaUrl.map((val: any) => val.url),
        thumbnail: temp.map((val: any) => val.url),
      }).toString(),
    });
  };

  const validate = () => {
    // if (mediaUrl.length === 0) {
    //   toast.error("Please Select Video.");
    //   return false;
    // }

    // if (thumbnail.length === 0) {
    //   toast.error("Please select thumbnail image");
    //   return false;
    // }

    // if (selectedProduct.length === 0) {
    //   toast.error("Please select products.");
    //   return false;
    // }

    // if (!title) {
    //   toast.error("Title is required");
    //   return false;
    // }

    return true;
  };

  const create = async () => {
    if (validate()) {
      setLoading(true);

      const data: any = {
        identifier: localStorage.getItem("identifier"),
        productId: selectedProduct.map((val: any) => val._id),
        title,
        status,
        priority: -1,
      };

      if (thumbnail[0]?.url) {
        data.productImageUrl = thumbnail[0]?.url;
      }

      if (mediaUrl[0]?.url) {
        data.productVideoUrl = mediaUrl[0]?.url;
      }

      axios({
        url: "https://backend.cftcommerce.com/api/productmedia",
        method: "POST",
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res: any) => {
          // navigate("/product-video");
          // now i need to send video
          // handleVideoUpdate(res);
          toast.success("Product Video created successfully.");
          navigate("product-video");
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          toast.error("Something went wrong");
        });
    }
  };

  const handleVideoUpdate = (res: any) => {
    const video_obj = mediaUrl[0].obj;
    const id = res.data.productMedia._id;

    addShoppableVideo({ files: video_obj, id })
      .then((response: any) => {
        axios({
          url: "https://backend.cftcommerce.com/api/productmedia/" + id,
          method: "PATCH",
          data: {
            productVideoUrl: response.data.data.productVideo,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((ress: any) => {
            navigate("/product-video");
            toast.success(
              params?.id
                ? "Product Video updated successfully."
                : "Product Video created successfully."
            );
            setLoading(false);
          })
          .catch((errr: any) => {
            toast.error("Something went wrong while adding video.");
            setLoading(false);
          });
      })
      .catch((error: any) => {
        toast.error("Not able to upload video.");
        setLoading(false);
      });
  };

  const update = async () => {
    if (validate()) {
      setLoading(true);

      let data: any = {
        identifier: localStorage.getItem("identifier"),
        productId: selectedProduct.map((val: any) => val._id),
        // productVideoUrl: v_name,
        title,
        // productImageUrl: `${
        //   process.env.REACT_APP_IMAGE
        // }${folder_name()}${i_name}`,
        status,
        priority: -1,
      };

      if (thumbnail[0]?.url) {
        data.productImageUrl = thumbnail[0]?.url;
      }

      if (mediaUrl[0]?.url) {
        data.productVideoUrl = mediaUrl[0]?.url;
      }

      axios({
        url: "https://backend.cftcommerce.com/api/productmedia/" + params.id,
        method: "PATCH",
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res: AxiosResponse) => {
          setLoading(false);
          navigate("/product-video");
          toast.success("Shoppable Video updated Successfully.");
        })
        .catch((error: AxiosResponse) => {
          setLoading(false);
          toast.success("Something went wrong.");
        });
    }
  };

  const handleClick = (e: any) => {
    if (e.target.name === "btn1") {
      if (params.id) {
        update();
      } else {
        create();
      }
    }
    if (e.target.name === "btn2") {
      setStatus("draft");
      if (params.id) {
        update();
      } else {
        create();
      }
    }

    if (e.target.name === "btn3") {
      navigate("/product-video");
    }
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={"New Shoppable Video"}
          showBtn1={isUserAllowedToEdit(admin)}
          showBtn2={isUserAllowedToEdit(admin)}
          showBtn3={true}
          btn1Name="btn1"
          btn2Name="btn2"
          btn3Name="btn3"
          btn1Text="Save"
          btn2Text="Save as Draft"
          btn3Text="Cancel"
          btn1type="filled"
          btn2type="outlined"
          btn3type="outlined_del"
          onClickHandler={handleClick}
        />
        <ContentBody>
          <div className="grid">
            <div className={`${dstyles.card}`}>
              <InputField
                type="text"
                isLabel={true}
                lable="Video Title"
                value={title}
                onChange={(e: any) => {
                  setTitle(e.target.value);
                  navigate({
                    pathname: params?.id
                      ? `/product-video/update/${params?.id}`
                      : "/product-video/add",
                    search: createSearchParams({
                      title: e.target.value,
                      products: selectedProduct.map((val: any) => val._id),
                      videos: mediaUrl.map((val: any) => val.url),
                      thumbnail: thumbnail.map((val: any) => val.url),
                    }).toString(),
                  });
                }}
              />
              <div className="grid_3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={insta}
                      id="InstaUpload"
                      onChange={(e) => setInsta(e.target.checked)}
                    />
                    <label htmlFor="InstaUpload">Upload to Instagram</label>
                  </div>
                  {insta && (
                    <a
                      href={`http://zing.cftlab.in/api/v1/auth?redirectUri=${
                        window.location.origin +
                        window.location.pathname +
                        window.location.search
                      }`}
                    >
                      Authenticate facebook
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="card grid">
              <div className={dstyles.grid_2}>
                <InputField
                  type="select"
                  isLabel={true}
                  options={product}
                  accessText="title"
                  accessValue="_id"
                  lable="Select Product"
                  value={""}
                  onChange={(e: any) => {
                    const temp = selectedProduct;
                    const found = product.filter(
                      (val: any) => val._id === e.target.value
                    );

                    temp.push(...found);

                    setSelectedProduct(temp);

                    console.log(temp);

                    navigate({
                      pathname: params?.id
                        ? `/product-video/update/${params?.id}`
                        : "/product-video/add",
                      search: createSearchParams({
                        products: temp.map((val: any) => val._id),
                        title: e.target.value,
                        videos: mediaUrl.map((val: any) => val.url),
                        thumbnail: thumbnail.map((val: any) => val.url),
                      }).toString(),
                    });
                  }}
                />
              </div>
              {selectedProduct.length !== 0 && (
                <ul style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                  {selectedProduct.map((val: any, index: any) => {
                    return (
                      <div key={index} style={{ width: "250px" }}>
                        <div
                          style={{
                            width: "250px",
                            aspectRatio: "1/1",
                            position: "relative",
                          }}
                        >
                          <img
                            src={
                              "https://business-1-images.s3.ap-south-1.amazonaws.com/" +
                              folder_name() +
                              val.mediaUrl[0]
                            }
                            alt=""
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            color={"#36619b"}
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              background: "#eff5fc",
                              height: "15px",
                              width: "15px",
                              padding: "5px",
                              borderRadius: "100%",
                              transform: "translate(50%,-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let temp = [...selectedProduct];

                              temp = [...temp.filter((x) => x._id !== val._id)];

                              setSelectedProduct(temp);

                              navigate({
                                pathname: params?.id
                                  ? `/product-video/update/${params?.id}`
                                  : "/product-video/add",
                                search: createSearchParams({
                                  title: title,
                                  products: temp.map((val: any) => val._id),
                                  videos: mediaUrl.map((val: any) => val.url),
                                  thumbnail: thumbnail.map(
                                    (val: any) => val.url
                                  ),
                                }).toString(),
                              });
                            }}
                          />
                        </div>
                        <p style={{ whiteSpace: "pre-wrap" }}>{val.title}</p>
                      </div>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="grid_2">
              <div className="card">
                <div style={{ marginTop: "0px", padding: 0, gap: "10px" }}>
                  <h3>Product Video</h3>
                  {mediaUrl.length === 0 ? (
                    <div className={dstyles.select_media}>
                      +
                      <input
                        type="file"
                        accept="video/*"
                        onChange={handleImage}
                      />
                    </div>
                  ) : (
                    <ul className={dstyles.selected_media}>
                      {mediaUrl.map((val: any, index: any) => {
                        return (
                          <li>
                            <Close
                              onClick={() => {
                                let temp = [...mediaUrl];
                                temp.splice(index, 1);

                                navigate({
                                  pathname: params?.id
                                    ? `/product-video/update/${params?.id}`
                                    : "/product-video/add",
                                  search: createSearchParams({
                                    title: title,
                                    products: selectedProduct.map(
                                      (val: any) => val._id
                                    ),
                                    videos: temp.map((val: any) => val.url),
                                    thumbnail: thumbnail.map(
                                      (val: any) => val.url
                                    ),
                                  }).toString(),
                                });
                                setMediaUrl(temp);
                              }}
                            />
                            <video
                              src={val.url}
                              autoPlay={false}
                              muted={true}
                              playsInline
                              style={{ width: "100%", height: "100%" }}
                            >
                              <source src={val.url} type={"video/*"}></source>
                            </video>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>

              <div className="card">
                <div style={{ marginTop: "0px", padding: 0, gap: "10px" }}>
                  <h3>Thumbnail Image</h3>
                  {thumbnail.length === 0 ? (
                    <div className={dstyles.select_media}>
                      +
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleThumbnail}
                      />
                    </div>
                  ) : (
                    <ul className={dstyles.selected_media}>
                      {thumbnail.map((val: any, index: any) => {
                        return (
                          <li>
                            <Close
                              onClick={() => {
                                let temp = [...thumbnail];
                                temp.splice(index, 1);
                                navigate({
                                  pathname: params?.id
                                    ? `/product-video/update/${params?.id}`
                                    : "/product-video/add",
                                  search: createSearchParams({
                                    title: title,
                                    products: selectedProduct.map(
                                      (val: any) => val._id
                                    ),
                                    videos: mediaUrl.map((val: any) => val.url),
                                    thumbnail: temp.map((val: any) => val.url),
                                  }).toString(),
                                });

                                setThumbnail(temp);
                              }}
                            />

                            <img
                              src={val.url}
                              style={{ width: "100%", height: "100%" }}
                              alt=""
                            />
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.shoppableVideo_access.write)
    return true;

  return false;
};

export default AddUpdateProductVideo;
