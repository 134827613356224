export const makeSlug = (str: string) => {
  //step one : remove punctuation
  str = str.replace(/[.,\/#!$%\^&\*;:{}=\_`~()]/g, "");

  //step two : convert all the char to lower case
  str = str.toLowerCase();

  //step three : strip front and back spaces
  str = str.trim();

  //step four : now replace all the spaces with '-'
  str = str.replaceAll(" ", "-");

  return str;
};
