import { useEffect, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import callApi from "../../Apis/callApi";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import InputField from "../../Components/InputField";
import DropBtn from "../../Components/DropBtn";
import toast from "react-hot-toast";
import Modal from "../../Components/Modal";
import { useAdminContext } from "../../Store/adminContext";

const LeadDetailView = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData]: any = useState({});
  const [loading, setLoading] = useState(true);
  const [delModal, setDelModal] = useState(false);

  const { admin }: any = useAdminContext();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url: "https://backend.cftcommerce.com/api/contacts/" + params.id,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        console.log("Lead Details : ", res.data.contact);
        setData(res.data.contact);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const updateUserData = () => {
    setLoading(true);

    let tempData = { ...data };
    delete tempData.status;

    axios({
      method: "PATCH",
      url: "https://backend.cftcommerce.com/api/contacts/" + params.id,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
      data: { ...tempData },
    })
      .then((res: any) => {
        setLoading(true);
        navigate("/contacts");
        toast.success("Lead updated successfully.");
      })
      .catch((err: any) => {
        setLoading(true);
        toast.error("Something went wrong while updating lead.");
      });
  };

  const handleClick = (e: any) => {
    const btnName = e.target.name;

    switch (btnName) {
      case "btn1":
        updateUserData();
        break;
      case "btn2":
        setDelModal(true);
        break;
      case "btn3":
        navigate("/contacts");
        break;
      default:
        break;
    }
  };

  const handleDelete = (e: any) => {
    setDelModal(false);
    setLoading(true);

    axios({
      method: "delete",
      url: `https://backend.cftcommerce.com/api/contacts/${params.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setLoading(false);
        toast.success("Deleted successfully.");
        navigate("/contacts");
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleStatusChange = (value: any) => {
    let oldStatus = data?.status;
    setData({ ...data, status: value });
    setLoading(true);

    axios({
      method: "PATCH",
      url: "https://backend.cftcommerce.com/api/contacts/" + params.id,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
      data: { status: value },
    })
      .then((res: any) => {
        setLoading(false);
        toast.success("Status updated successfully.");
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error("Something went wrong while updating status.");
        setData({ ...data, status: oldStatus });
      });
  };

  const header_drop_part = (
    <>
      <div style={{ fontSize: "1rem" }}>
        <DropBtn
          text={data?.status ? data?.status : "Status"}
          isIcon={false}
          drop_list={[
            { id: "lead", title: "Lead" },
            { id: "open", title: "Open" },
            { id: "closed", title: "Closed" },
          ].filter((val) => {
            if (data?.status) {
              return val.id !== data?.status.toLowerCase();
            } else {
              return true;
            }
          })}
          on_click={handleStatusChange}
        />{" "}
      </div>
    </>
  );

  return (
    <>
      <Content>
        <ContentHead
          title={
            <>
              Lead Details{" "}
              {admin.role === "subadmin" ? (
                admin.contact_access.write ? (
                  header_drop_part
                ) : (
                  <></>
                )
              ) : (
                header_drop_part
              )}
            </>
          }
          showBtn1={
            admin.role === "subadmin" ? admin.contact_access.write : true
          }
          showBtn2={
            admin.role === "subadmin" ? admin.contact_access.delete : true
          }
          showBtn3={true}
          btn1Name="btn1"
          btn2Name="btn2"
          btn3Name="btn3"
          btn1Text={"Save"}
          btn2Text={"Delete"}
          btn3Text={"Back"}
          btn1type="filled"
          btn2type="filled_red"
          btn3type="light_grey"
          onClickHandler={handleClick}
        />
        <ContentBody>
          <div className="grid">
            <div className="card grid">
              <h3>User Details</h3>
              <div className="grid_3">
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"First Name"}
                  value={data?.firstName}
                  onChange={(e: any) => {
                    setData({ ...data, firstName: e.target.value });
                  }}
                  placeholder="Enter First Name"
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"Last Name"}
                  value={data?.lastName}
                  onChange={(e: any) => {
                    setData({ ...data, lastName: e.target.value });
                  }}
                  placeholder="Enter Last Name"
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"Personal Email"}
                  value={data?.personalEmail}
                  onChange={(e: any) => {
                    setData({ ...data, personalEmail: e.target.value });
                  }}
                  placeholder="Enter Email"
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"Cooperate Email"}
                  value={data?.cooperateEmail}
                  onChange={(e: any) => {
                    setData({ ...data, cooperateEmail: e.target.value });
                  }}
                  placeholder="Enter Email"
                />
                <InputField
                  type="number"
                  isLabel={true}
                  lable={"Phone no."}
                  value={data?.mobileNumber}
                  onChange={(e: any) => {
                    setData({ ...data, mobileNumber: e.target.value });
                  }}
                  placeholder="Enter Phone no."
                />
                <InputField
                  type="number"
                  isLabel={true}
                  lable={"Alternate Phone no."}
                  value={data?.altMobileNumber}
                  onChange={(e: any) => {
                    setData({ ...data, altMobileNumber: e.target.value });
                  }}
                  placeholder="Enter Phone no."
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"City"}
                  value={data?.altMobileNumber}
                  onChange={(e: any) => {
                    setData({ ...data, altMobileNumber: e.target.value });
                  }}
                  placeholder="Enter Phone no."
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"State"}
                  value={data?.altMobileNumber}
                  onChange={(e: any) => {
                    setData({ ...data, altMobileNumber: e.target.value });
                  }}
                  placeholder="Enter Phone no."
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable={"Country"}
                  value={data?.altMobileNumber}
                  onChange={(e: any) => {
                    setData({ ...data, altMobileNumber: e.target.value });
                  }}
                  placeholder="Enter Phone no."
                />
              </div>
            </div>

            <div className="card grid">
              <h3>Query</h3>
              <div
                className="grid"
                style={{
                  background: "rgba(0,0,0,.1)",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <p>Reason 1 : {data?.reason1}</p>
                <p>Reason 2 : {data?.reason2}</p>
                <p>Message : {data?.message}</p>
              </div>
            </div>

            <div className="card grid">
              <h3>Remark</h3>
              <textarea
                style={{
                  minHeight: "200px",
                  borderRadius: "20px",
                  fontSize: "1rem",
                  fontFamily: "inherit",
                  padding: "15px",
                }}
                placeholder="Enter Remark"
                value={data?.remarks}
              />
            </div>
          </div>
          {loading && (
            <div className="loading">
              <span className="loader"></span>
            </div>
          )}
        </ContentBody>
      </Content>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => setDelModal(false)}
        onBtn2Click={handleDelete}
        state={delModal}
      />
    </>
  );
};

export default LeadDetailView;
