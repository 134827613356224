import React, { useState } from "react";
import styles from "../../Styles/content.module.css";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faListDots,
} from "@fortawesome/free-solid-svg-icons";
// import Button from "../Custom/Button";

import { ReactComponent as ImportIcon } from "../../Assets/newIcons/import.svg";
import { ReactComponent as ExportIcon } from "../../Assets/newIcons/export.svg";
import { ReactComponent as LogsIcon } from "../../Assets/newIcons/logs.svg";

type props = {
  title: any;
  showBtn1?: boolean;
  showBtn2?: boolean;
  showBtn3?: boolean;
  showBtn4?: boolean;
  showStatusUpdateBtn?: boolean;
  showReturnBtn?: boolean;
  showRefundBtn?: boolean;
  statusUpdateBtnText?: any;
  refundStatusBtnText?: any;
  returnStatusBtnText?: any;
  refundStatusBtnType?: any;
  returnStatusBtnType?: any;
  btn1Text?: any;
  btn2Text?: any;
  btn3Text?: any;
  btn4Text?: any;
  btn1type?:
    | "outlined"
    | "filled"
    | "outlined_del"
    | "light_grey"
    | "outlined_success"
    | "filled_red"
    | "filled_green"
    | undefined;
  btn2type?:
    | "outlined"
    | "filled"
    | "outlined_del"
    | "light_grey"
    | "outlined_success"
    | "filled_red"
    | "filled_green"
    | undefined;
  btn3type?:
    | "outlined"
    | "filled"
    | "outlined_del"
    | "light_grey"
    | "outlined_success"
    | "filled_red"
    | "filled_green"
    | undefined;
  btn4type?:
    | "outlined"
    | "filled"
    | "outlined_del"
    | "light_grey"
    | "outlined_success"
    | "filled_red"
    | "filled_green"
    | undefined;
  statusUpdateBtnType?:
    | "outlined"
    | "filled"
    | "outlined_del"
    | "light_grey"
    | "outlined_success"
    | "filled_red"
    | "filled_green";
  onClickHandler?: any;
  btn1Name?: string;
  btn2Name?: string;
  btn3Name?: string;
  refundStatusBtnName?: any;
  returnStatusBtnName?: any;
  btn4Name?: string;
  statusUpdateBtnName?: string;
  isIcon?: boolean;
  iconType?: any;
  isIcon1?: boolean;
  iconType1?: any;
  isIcon2?: boolean;
  iconType2?: any;
  isIcon3?: boolean;
  iconType3?: any;
  isIcon4?: boolean;
  iconType4?: any;
  customElement?: any;
  isMoreSettings?: any;
  moreSettingsList?: any;
  handleMoreClick?: any;
};

const ContentHead = (props: props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.content_container}>
      <h1 style={{ display: "flex", alignItems: "center", gap: "15px" }}>
        {props.title}
      </h1>
      <div style={{ flexGrow: 1 }}></div>
      {props?.customElement}
      {props?.showBtn4 && (
        <Button
          name={props?.btn4Name}
          type={props?.btn4type}
          text={props?.btn4Text}
          style={{ marginRight: "10px" }}
          clickHandler={props.onClickHandler}
          isIcon={props?.isIcon4 ? props?.isIcon4 : false}
          iconType={props?.iconType4}
        />
      )}
      {props?.showBtn3 && (
        <Button
          name={props?.btn3Name}
          type={props?.btn3type}
          text={props?.btn3Text}
          style={{ marginRight: "10px" }}
          clickHandler={props.onClickHandler}
          isIcon={props?.isIcon3 ? props?.isIcon3 : false}
          iconType={props?.iconType3}
        />
      )}

      {props?.showBtn2 && (
        <Button
          name={props?.btn2Name}
          type={props?.btn2type}
          text={props?.btn2Text}
          style={{ marginRight: "10px" }}
          clickHandler={props.onClickHandler}
          isIcon={props?.isIcon2 ? props?.isIcon2 : false}
          iconType={props?.iconType2}
        />
      )}
      {props?.showStatusUpdateBtn && (
        <Button
          name={props?.statusUpdateBtnName}
          type={props?.statusUpdateBtnType}
          text={props?.statusUpdateBtnText}
          style={{ marginRight: "10px" }}
          clickHandler={props?.onClickHandler}
          isIcon={props?.isIcon1 ? props?.isIcon1 : false}
          iconType={props?.iconType1}
        />
      )}

      {props?.showReturnBtn && (
        <Button
          name={props?.statusUpdateBtnName}
          type={props?.returnStatusBtnType}
          text={props?.returnStatusBtnText}
          style={{ marginRight: "10px" }}
          clickHandler={props?.onClickHandler}
          isIcon={props?.isIcon ? props?.isIcon : false}
          iconType={props?.iconType}
        />
      )}
      {props?.showRefundBtn && (
        <Button
          name={props?.refundStatusBtnName}
          type={props?.refundStatusBtnType}
          text={props?.refundStatusBtnText}
          style={{ marginRight: "10px" }}
          clickHandler={props?.onClickHandler}
          isIcon={props?.isIcon ? props?.isIcon : false}
          iconType={props?.iconType}
        />
      )}
      {props?.showBtn1 && (
        <Button
          name={props?.btn1Name}
          type={props?.btn1type}
          text={props?.btn1Text}
          style={{ marginRight: "10px" }}
          clickHandler={props.onClickHandler}
          isIcon={props?.isIcon1 ? props?.isIcon1 : false}
          iconType={props?.iconType1}
        />
      )}
      {props?.isMoreSettings && (
        <div className={styles.more_menu} onClick={() => setOpen(true)}>
          <FontAwesomeIcon icon={faEllipsisVertical} />
          {open && (
            <ul className={styles.more_list}>
              {props.moreSettingsList?.map((v: any, i: any) => {
                const Icon = more_list_icons[v.icon];
                return (
                  <li
                    key={i}
                    className={styles.more_list_item}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);
                      props.handleMoreClick(v.name);
                    }}
                  >
                    <Icon />
                    {v.title}
                  </li>
                );
              })}
            </ul>
          )}
          {open && (
            <div
              className={styles.more_cover}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            ></div>
          )}
        </div>
      )}
    </div>
  );
};

const more_list_icons: any = {
  import: ImportIcon,
  export: ExportIcon,
  logs: LogsIcon,
};

export default React.memo(ContentHead);
