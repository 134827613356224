import React from "react";
import AddUpdateProduct from "../AddUpdate";

class CloneProduct extends React.Component {
  render() {
    return (
      <>
        <AddUpdateProduct />
      </>
    );
  }
}

export default CloneProduct;
