import React, { useEffect, useState } from "react";
import styles from "./addsubadmin.module.css";
import SubAdminForm from "../../../Components/Settings/addNewAdminPage/SubAdminForm";
import Content from "../../../Components/Content/Content";
import ContentHead from "../../../Components/Content/ContentHead";
import ContentBody from "../../../Components/Content/ContentBody";
import axios from "axios";
import InputField from "../../../Components/InputField";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
type Props = {};

const AddSubAdmin = (props: Props) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [permissions, setPermissions]: any = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    getAdminByToken();
  }, []);

  const getAdminByToken = () => {
    axios({
      method: "get",
      url: "https://backend.cftcommerce.com/api/admins/getAdminByToken",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        const user = res.data.data;
        let per = [
          { title: "blog", value: user?.blog || false },
          { title: "contact", value: user?.contact || false },
          { title: "career", value: user?.career || false },
          { title: "caseStudy", value: user?.caseStudy || false },
          { title: "ecommerce", value: user?.ecommerce || false },
          { title: "compliance", value: user?.compliance || false },
          { title: "service", value: user?.service || false },
        ];

        per = per.filter((v) => v.value);

        const final_per: any = {};

        per.forEach((v) => {
          if (v.title === "ecommerce") {
            final_per["catalog_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["sales_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["shoppableVideo_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["users_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["discount_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["content_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["store_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["overview_access"] = {
              read: false,
              write: false,
              delete: false,
            };
          } else if (v.title === "career") {
            final_per["job_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["applicant_access"] = {
              read: false,
              write: false,
              delete: false,
            };
          } else {
            final_per[v.title + "_access"] = {
              read: false,
              write: false,
              delete: false,
            };
          }
        });

        setPermissions(final_per);

        if (params?.id) {
          getSubAdminById();
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubAdminById = () => {
    axios({
      method: "get",
      url: `https://backend.cftcommerce.com/api/sub-admin/${params.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setEmail(res.data.sub_admin.email);
        setPhone(res.data.sub_admin.mobileNumber.value);
        setFirstName(res.data.sub_admin.firstName);
        setLastName(res.data.sub_admin.lastName);
        setPermissions((prev: any) => {
          let temp = { ...prev };

          Object.keys(prev).forEach((v) => {
            temp[v] = res.data.sub_admin[v];
          });

          return temp;
        });

        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleClick = (e: any) => {
    console.log(e.target.name);
    if (e.target.name === "btn1") {
      handleSubmit();
    }
    if (e.target.name === "btn2") {
      navigate("/settings");
    }
  };

  const handleSubmit = () => {
    const data: any = {
      firstName,
      lastName,
      email,
      mobileNumber: {
        value: phone,
        countryCode: "+91",
        isVerified: false,
      },
    };

    Object.keys(permissions).forEach((v) => {
      data[v] = permissions[v];
    });

    setLoading(true);

    console.log(data);

    axios({
      method: params?.id ? "patch" : "post",
      url: params?.id
        ? `https://backend.cftcommerce.com/api/sub-admin/update/${params.id}`
        : "https://backend.cftcommerce.com/api/sub-admin/create",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        navigate("/settings");
        toast.success(params?.id ? "" : "SubAdmin added successfully.");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div className="loading">
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={"Add New Team Member"}
          showBtn1={true}
          btn1Name="btn1"
          btn1Text={"Save"}
          btn1type="filled"
          showBtn2={true}
          btn2Name="btn2"
          btn2Text={"Cancel"}
          btn2type="outlined_del"
          onClickHandler={handleClick}
        />
        <ContentBody>
          <div className="grid">
            <div className={"card grid"}>
              <h3>Person's Details</h3>
              <div className="grid_2">
                <InputField
                  isLabel={true}
                  lable={"Person's Email"}
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  isRequired={true}
                  type="mail"
                />
                <InputField
                  isLabel={true}
                  lable={"Person's Mobile Number"}
                  value={phone}
                  onChange={(e: any) => setPhone(e.target.value)}
                  isRequired={true}
                  type="number"
                />
                <InputField
                  isLabel={true}
                  lable={"Person's First Name"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                  isRequired={true}
                  type="text"
                />
                <InputField
                  isLabel={true}
                  lable={"Person's Last Name"}
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                  isRequired={true}
                  type="text"
                />
              </div>
            </div>
            <div className="card grid">
              <h3>Permissions</h3>
              <div className={styles.container}>
                {Object.keys(permissions).map((permission, index) => {
                  return (
                    <div className={styles.item} key={index}>
                      <span style={{ textTransform: "capitalize" }}>
                        {permission.replace("_access", "")}
                      </span>
                      <label>
                        <input
                          type="checkbox"
                          checked={permissions[permission]?.read}
                          onChange={(e: any) => {
                            setPermissions((prev: any) => ({
                              ...prev,
                              [permission]: {
                                ...prev[permission],
                                read: e.target.checked,
                              },
                            }));
                          }}
                        />
                        Read
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={permissions[permission]?.write}
                          onChange={(e: any) => {
                            setPermissions((prev: any) => ({
                              ...prev,
                              [permission]: {
                                ...prev[permission],
                                write: e.target.checked,
                              },
                            }));
                          }}
                        />
                        Write
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={permissions[permission]?.delete}
                          onChange={(e: any) => {
                            setPermissions((prev: any) => ({
                              ...prev,
                              [permission]: {
                                ...prev[permission],
                                delete: e.target.checked,
                              },
                            }));
                          }}
                        />
                        Delete
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default AddSubAdmin;
