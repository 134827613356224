import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";

const Profile = () => {
  return (
    <Content>
      <ContentHead title={"Profile"} />
      <ContentBody>
        <div className="grid"></div>
      </ContentBody>
    </Content>
  );
};

export default Profile;
