import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import styles from "./prePrintModal.module.css";
import { useReactToPrint } from "react-to-print";
import Invoice from "../invoice";
import ThermalInvoice from "../ThermalInvoice/thermalInvoice";

const PrintOptions = (props:any) => {
    const [selectedOption, setSelectedOption] = useState("a4");
    const invoiceRef:any = useRef();

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   console.log(event.target.value)
    setSelectedOption(event.target.value);
  };
   const handlePrint = useReactToPrint({
     content: () => invoiceRef.current,
   });
  
   useEffect(() => {
    console.log(invoiceRef.current)
   }, [])
   
    
    const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>)=>{
        // console.log(e.target.checked)
        if (e.target.checked) {
            localStorage.setItem('printingOption',selectedOption)
        }
    }
    
    return (
      <>
        <div className={styles.modal_container} onClick={props.close}>
          <div
            className={styles.container}
            onClick={(e: any) => e.stopPropagation()}
          >
            <div className={styles.header}>
              <h2>Print Options</h2>
              {/* <button className={styles.closeButton}>✖</button> */}
            </div>
            <form className={styles.form}>
              <div className={styles.option}>
                <input
                  type="radio"
                  id="a4"
                  name="printOption"
                  value="a4"
                  checked={selectedOption === "a4"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="a4">
                  Print on A4 sheet
                  <p>A standard A4 sheet will be used in landscape mode.</p>
                </label>
              </div>
              <div className={styles.option}>
                <input
                  type="radio"
                  id="thermal"
                  name="printOption"
                  value="thermal"
                  checked={selectedOption === "thermal"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="thermal">
                  Print on thermal(57mm x 80mm)
                  <p>A thermal printer will be used for printing.</p>
                </label>
              </div>
            </form>
            <div className={styles.footer}>
              <button className={styles.printButton} onClick={handlePrint}>
                Print
              </button>
              <button className={styles.cancelButton} onClick={props.close}>
                Cancel
              </button>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  id="dontShowAgain"
                  onChange={handleCheckBox}
                />
                <label htmlFor="dontShowAgain">Do not show this again</label>
              </div>
            </div>
          </div>
        </div>
        {selectedOption === "a4" && (
          <div style={{ display: "none" }}>
            <Invoice
              user={props.user}
              // product={props.product}
              order={props.order}
              admin={props.admin}
              ref={invoiceRef}
            />
          </div>
        )}
        {selectedOption === "thermal" && (
          <div style={{ position:"absolute",top:"-1000000px",left:"-10000px"}}>
            <ThermalInvoice
              user={props.user}
              // product={props.product}
              order={props.order}
              admin={props.admin}
              ref={invoiceRef}
            />
          </div>
        )}
      </>
    );
};

export default PrintOptions;
