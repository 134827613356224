import React, { useEffect, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import InputField from "../../Components/InputField";
import { useLangContext } from "../../Store/langContext";
import styles from "../../Styles/settings.module.css";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import axios from "axios";
import toast from "react-hot-toast";
import { getFileName } from "../../Utils/getFileName";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import { identity } from "lodash";
import SubAdminPanel from "../../Components/Settings/subAdminPanel/SubAdminPanel";
import { useAdminContext } from "../../Store/adminContext";
import heic2any from "heic2any";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ContentOuterHead from "../../Components/Content/contentOuterHead";

type ColorType = {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  neutralColor: string;
  bodyColor: string;
};

const Settings = () => {
  const { lang, setLang }: any = useLangContext();

  const [logo, setLogo]: any = useState({ url: "", obj: null });
  const [isChanged, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const { admin }: any = useAdminContext();
  const [minOrderValue, setMinOrderValue] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [scripts, setScripts]: any = useState([]);
  const [isMinOrderValueActive, setIsMinOrderValueActive] = useState(false);
  const [isShippingChargeActive, setIsShippingChargeActive] = useState(false);
  const [isCancellationRules, setIsCancellationRules] = useState(false);
  const [color, setColor] = useState<ColorType>({
    primaryColor: "",
    secondaryColor: "",
    tertiaryColor: "",
    neutralColor: "",
    bodyColor: "",
  });
  const [orderCancelList, setOrderCancelList]: any = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url: "https://backend.cftcommerce.com/api/admins/getWebsiteData",
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        console.log(res);
        const d = res.data.data;
        setColor(d.color);
        setLogo({
          url: d.logoUrl,
          obj: null,
        });
        setMinOrderValue(d?.minOrderValue || 0);
        setShippingCharge(d?.shippingCharge || 0);
        setIsMinOrderValueActive(d?.isMinOrderValueActive);
        setIsShippingChargeActive(d?.isShippingChargeActive);
        setScripts(d?.scripts || []);
        setIsCancellationRules(d?.isCancellationRules);
        setOrderCancelList(d?.orderCancelList || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addImg = async (e: any) => {
    setChanged(true);
    const file = e.target.files[0];
    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      setLogo({
        url: URL.createObjectURL(convertedFile),
        obj: convertedFile,
      });
    } else
      setLogo({
        url: URL.createObjectURL(e.target.files[0]),
        obj: e.target.files[0],
      });
  };

  const uploadImage = async () => {
    if (!logo.url) return "";

    if (logo.obj) {
      const filename = getFileName(logo.obj);
      await UploadImage({
        file: logo.obj,
        name: filename,
      });
      return `https://business-1-images.s3.ap-south-1.amazonaws.com/${folder_name()}${filename}`;
    } else {
      return logo.url;
    }
  };

  const handleSave = async () => {
    setLoading(true);

    const res = await uploadImage();

    axios({
      url: "https://backend.cftcommerce.com/api/admins/updateSelf",
      method: "patch",
      data: {
        identity: localStorage.getItem("identifier"),
        data: {
          color,
          logoUrl: res,
          minOrderValue,
          isMinOrderValueActive,
          shippingCharge,
          isShippingChargeActive,
          scripts,
          isCancellationRules,
          orderCancelList,
        },
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        toast.success("Website data updated successfully.");
        setLoading(false);
        setChanged(false);
      })
      .catch((err) => {
        toast.error("Something went wrong while updating website data.");
        setLoading(false);
      });
  };

  return (
    <Content>
      <ContentHead
        title={"settings"}
        showBtn1={isChanged}
        btn1Name="btn1"
        btn1Text="Save"
        btn1type="filled"
        onClickHandler={handleSave}
      />
      <ContentBody>
        <div className="grid">
          {canAdminAddSubAdmin(admin) && (
            <div className={"card grid"}>
              <SubAdminPanel />
            </div>
          )}
          <div className="grid_2">
            {admin.role !== "subadmin" && (
              <div className="card grid">
                <h3>Store Settings</h3>
                <div className={styles.input}>
                  <p>Logo</p>
                  {logo.url ? (
                    <div className={styles.img_container}>
                      <img src={logo.url} />
                      <div
                        className={styles.close_icon}
                        onClick={()=>setLogo({ url: "", obj: null })}
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  ) : (
                    <div className={styles.img_input}>
                      +
                      <input
                        type="file"
                        accept=".heic,image/*"
                        onChange={addImg}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.input}>
                  <p>Colors</p>
                  <div className={styles.color_input}>
                    <label>Primary Color</label>
                    <input
                      type="color"
                      value={color.primaryColor}
                      onChange={(e: any) => {
                        setChanged(true);
                        setColor((prev) => ({
                          ...prev,
                          primaryColor: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className={styles.color_input}>
                    <label>Secondary Color</label>
                    <input
                      type="color"
                      value={color.secondaryColor}
                      onChange={(e: any) => {
                        setChanged(true);
                        setColor((prev) => ({
                          ...prev,
                          secondaryColor: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className={styles.color_input}>
                    <label>Tertiary Color</label>
                    <input
                      type="color"
                      value={color.tertiaryColor}
                      onChange={(e: any) => {
                        setChanged(true);
                        setColor((prev) => ({
                          ...prev,
                          tertiaryColor: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className={styles.color_input}>
                    <label>Neutral Color</label>
                    <input
                      type="color"
                      value={color.neutralColor}
                      onChange={(e: any) => {
                        setChanged(true);
                        setColor((prev) => ({
                          ...prev,
                          neutralColor: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className={styles.color_input}>
                    <label>Body Color</label>
                    <input
                      type="color"
                      value={color.bodyColor}
                      onChange={(e: any) => {
                        setChanged(true);
                        setColor((prev) => ({
                          ...prev,
                          bodyColor: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="card grid" style={{ alignSelf: "start" }}>
              <h3>Preferred language</h3>
              <p>
                When you're logged in to ShopIQ, this is the language you will
                see. It doesn't affect the language your customers see on your
                online store.
              </p>
              <InputField
                type="select"
                isLabel={false}
                lable={"Language"}
                value={lang}
                onChange={(val: any) => setLang(val.target.value)}
                options={[
                  { value: "en", title: "English" },
                  { value: "hn", title: "Hindi" },
                ]}
                accessText="title"
                accessValue="value"
              />
            </div>
          </div>
          <div className="grid_2">
            {admin.role !== "subadmin" && (
              <div className="card grid">
                <div className="grid">
                  <h3>Store Rules</h3>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <input
                      type="checkbox"
                      checked={isMinOrderValueActive}
                      onChange={(e: any) => {
                        setChanged(true);
                        setIsMinOrderValueActive(e.target.checked);
                      }}
                      id="isMinOrderValueActiveValue"
                    />
                    <label htmlFor="isMinOrderValueActiveValue">
                      Set Min. Order Value
                    </label>
                  </div>
                  {isMinOrderValueActive && (
                    <InputField
                      type="number"
                      value={String(minOrderValue)}
                      isLabel={true}
                      lable={"Min. Order Value"}
                      onChange={(e: any) => {
                        setChanged(true);
                        setMinOrderValue(e.target.value);
                      }}
                    />
                  )}
                  <div style={{ display: "flex", gap: "5px" }}>
                    <input
                      type="checkbox"
                      checked={isShippingChargeActive}
                      onChange={(e: any) => {
                        setChanged(true);
                        setIsShippingChargeActive(e.target.checked);
                      }}
                      id="isShippingChargeActiveValue"
                    />
                    <label htmlFor="isShippingChargeActiveValue">
                      Set Shipping Charge
                    </label>
                  </div>
                  {isShippingChargeActive && (
                    <InputField
                      type="number"
                      value={String(shippingCharge)}
                      isLabel={true}
                      lable={"Shipping Charges"}
                      onChange={(e: any) => {
                        setChanged(true);
                        setShippingCharge(e.target.value);
                      }}
                    />
                  )}
                  <div style={{ display: "flex", gap: "5px" }}>
                    <input
                      type="checkbox"
                      checked={isCancellationRules}
                      onChange={(e: any) => {
                        setChanged(true);
                        setIsCancellationRules(e.target.checked);
                      }}
                      id="isCancellationRules"
                    />
                    <label htmlFor="isCancellationRules">
                      Cancellation Rules
                    </label>
                  </div>
                  {isCancellationRules && (
                    <>
                      <div>
                        <InputField
                          isLabel={true}
                          lable={
                            "User can cancel Order, When Fulfillment Status is :"
                          }
                          type="select"
                          options={[
                            { title: "Received", value: "received" },
                            { title: "Packaging", value: "packaging" },
                            { title: "Shipped", value: "shipped" },
                            {
                              title: "Out for delivery",
                              value: "outfordelivery",
                            },
                            { title: "Completed", value: "completed" },
                          ].filter((v: any) => {
                            return !orderCancelList.includes(v.value);
                          })}
                          accessText="title"
                          accessValue="value"
                          value=""
                          onChange={(e: any) => {
                            setOrderCancelList((prev: any) => [
                              ...prev,
                              e.target.value,
                            ]);
                          }}
                        />
                        <p
                          style={{
                            fontSize: ".8rem",
                            color: "grey",
                            marginTop: "5px",
                          }}
                        >
                          If you don't want the user to cancel the order at any
                          stage, mark the cancellation rules as true and leave
                          the above list empty.
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          flexWrap: "wrap",
                        }}
                      >
                        {orderCancelList.map((v: any, i: any) => {
                          return (
                            <div
                              key={i}
                              style={{
                                background: "var(--blue-light)",
                                padding: "5px 10px",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                textTransform: "capitalize",
                                color: "var(--blue-dark)",
                                borderRadius: "10px",
                              }}
                            >
                              <p>
                                {v === "outfordelivery"
                                  ? "out for delivery"
                                  : v}
                              </p>
                              <FontAwesomeIcon
                                icon={faClose}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  let temp = [...orderCancelList];
                                  temp.splice(i, 1);
                                  setOrderCancelList(temp);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {admin.role !== "subadmin" && (
              <div className="card grid">
                <h3>
                  Scripts{" "}
                  <button
                    onClick={() => {
                      setScripts((prev: any) => [
                        ...prev,
                        { scriptType: "", scriptPosition: "", value: "" },
                      ]);
                    }}
                  >
                    Add
                  </button>
                </h3>

                {scripts.map((script: any, index: any) => {
                  return (
                    <div className={styles.scripts_list_item} key={index}>
                      <CloseIcon
                        onClick={() => {
                          setScripts((prev: any) => {
                            let temp = [...prev];
                            temp.splice(index, 1);
                            return temp;
                          });
                        }}
                      />
                      <InputField
                        type="select"
                        options={[
                          {
                            title: "Google Tag Manager",
                            value: "google_tag_manager",
                          },
                          {
                            title: "Google Analytics",
                            value: "google_analytics",
                          },
                          {
                            title: "Google Search Console",
                            value: "google_search_console",
                          },
                          { title: "Meta Pixel", value: "meta_pixel" },
                        ]}
                        accessText="title"
                        accessValue="value"
                        value={script.scriptType}
                        isLabel={true}
                        lable={"Script Type"}
                        onChange={(e: any) => {
                          setChanged(true);
                          setScripts((prev: any) => {
                            let temp = [...prev];
                            temp[index].scriptType = e.target.value;
                            return temp;
                          });
                        }}
                        placeholder="Select Script type"
                      />
                      {(script.scriptType === "google_tag_manager" ||
                        script.scriptType === "other") && (
                        <InputField
                          type="select"
                          options={[
                            {
                              title: "Head",
                              value: "head",
                            },
                            {
                              title: "Body",
                              value: "body",
                            },
                          ]}
                          accessText="title"
                          accessValue="value"
                          value={script.scriptPosition}
                          isLabel={true}
                          lable={"Script Position"}
                          onChange={(e: any) => {
                            setChanged(true);
                            setScripts((prev: any) => {
                              let temp = [...prev];
                              temp[index].scriptPosition = e.target.value;
                              return temp;
                            });
                          }}
                          placeholder="Select Script Position"
                        />
                      )}
                      <InputField
                        type="text"
                        value={script.value}
                        isLabel={true}
                        lable={"Script Value"}
                        onChange={(e: any) => {
                          setChanged(true);
                          setScripts((prev: any) => {
                            let temp = [...prev];
                            temp[index].value = e.target.value;
                            return temp;
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </ContentBody>
    </Content>
  );
};

const canAdminAddSubAdmin = (admin: any) => {
  if (admin.role === "subadmin") return false;

  if (!admin.subAdmin) return false;

  return true;
};

export default Settings;
