import React from "react";
import styles from "./singleComingFrom.module.css";
type Props = {
  label: string;
  count: number;
};

const SingleComingFrom = ({ label, count }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <p>{label}</p>
        <p>{count}</p>
      </div>
      <div className={styles.progressContainer}>
        <div
          style={{ width: `${count.toString()}%` }}
          className={styles.progress}
        />
      </div>
    </div>
  );
};

export default SingleComingFrom;
