import styles from "./contactPreview.module.css";
import { ReactComponent as DownIcon } from "../../../Assets/icons/down.svg";
import { useState } from "react";

const ContactPreview = ({
  headers,
  assignedFields,
  ready,
  skipped,
  duplicate,
}: any) => {
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const unmapped = headers.filter((v: any) => {
    return !Object.values(assignedFields).includes(v);
  });

  console.log(unmapped);

  return (
    <div className={styles.container}>
      <div className={styles.section_container}>
        <div
          className={`${styles.header} ${first && styles.active_header}`}
          style={{ borderTop: "1px solid #d2d2d2" }}
          onClick={() => setFirst((prev) => !prev)}
        >
          <p>Customers That are ready to imported - {ready.length}</p>
          <DownIcon className={`${first && styles.active_icon}`} />
        </div>
        <div
          className={`${styles.table_hidden_container} ${
            first && styles.active_table_container
          }`}
        >
          <table className={styles.table}>
            <thead>
              <tr>
                {fields.map((v: any, i: any) => {
                  return <th key={i}>{v.title}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {ready.map((v: any, i: any) => {
                return (
                  <tr key={i}>
                    {fields.map((val: any, ind: any) => {
                      return <td key={ind}>{v[val.value] || "--"}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.section_container}>
        <div
          className={`${styles.header} ${second && styles.active_header}`}
          onClick={() => setSecond((prev) => !prev)}
        >
          <p>No. of Records skipped - {skipped.length}</p>
          <DownIcon className={`${second && styles.active_icon}`} />
        </div>
        <div
          className={`${styles.table_hidden_container} ${
            second && styles.active_table_container
          }`}
        >
          <table className={styles.table}>
            <thead>
              <tr>
                {fields.map((v: any, i: any) => {
                  return <th key={i}>{v.title}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {skipped.map((v: any, i: any) => {
                return (
                  <tr key={i}>
                    {fields.map((val: any, ind: any) => {
                      return <td key={ind}>{v[val.value] || "--"}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.section_container}>
        <div
          className={`${styles.header} ${third && styles.active_header}`}
          style={{ borderBottom: "1px solid #d2d2d2" }}
          onClick={() => setThird((prev) => !prev)}
        >
          <p>Unmapped Fields - {unmapped.length}</p>
          <DownIcon className={`${third && styles.active_icon}`} />
        </div>
        <div className={`${styles.content} ${third && styles.active_content}`}>
          <div className={styles.sub_content}>
            {unmapped.map((v: any, i: any) => {
              return (
                <div key={i} className={styles.content_tags}>
                  {v}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const fields = [
  { title: "First Name", value: "firstName" },
  { title: "Middle Name", value: "middleName" },
  { title: "Last Name", value: "lastName" },
  { title: "Email", value: "personalEmail" },
  { title: "Cooperate Email", value: "cooperateEmail" },
  { title: "Phone Number", value: "mobileNumber" },
  { title: "Alt. Phone Number", value: "altMobileNumber" },
  { title: "Contact Owner", value: "contactOwner" },
  { title: "Company Name", value: "companyName" },
  { title: "Company Website", value: "companyWebsite" },
  { title: "Company HQ", value: "companyHQ" },
  { title: "Lead Status", value: "status" },
  { title: "Reason 1", value: "reason1" },
  { title: "Reason 2", value: "reason2" },
  { title: "Message", value: "message" },
  { title: "Country", value: "country" },
  { title: "State", value: "state" },
  { title: "City", value: "city" },
  { title: "Address", value: "address" },
  { title: "Pincode", value: "pincode" },
  { title: "Remarks", value: "remarks" },
];

export default ContactPreview;
