import React, { useState, useEffect } from "react";
import WithTableView from "../../GenericPages/WithTableView";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import ObjToExcel from "../../Utils/objToExcel";
import Pagination from "../../Components/Table/Pagination";
import { folder_name } from "../../Utils/UploadImage";
import { ReactComponent as Cross } from "../../Assets/icons/cross.svg";
import styles from "../../Styles/applications.module.css";
import { useLangContext } from "../../Store/langContext";
import langObj from "../../Utils/lang";

type props = {
  data?: any;
  modal?: boolean;
  modalData?: any;
  loading?: boolean;
  deleteModal?: boolean;
};

let inFocus: any;

const Applications: React.FC<props> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang }: any = useLangContext();

  const [data, setData] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    getData();
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      method: "get",
      url: `https://backend.cftcommerce.com/api/aspirants?identifier=${localStorage.getItem(
        "identifier"
      )}&page=${Number(search.get("page")) || 1}&limit=${
        Number(search.get("limit")) || 15
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        console.log(res.data?.aspirants);
        setData(
          res.data.aspirants.map((val: any) => ({
            ...val,
            createdAt: val.createdAt.split("T")[0].split("-").join("-"),
            dob: val?.dob
              ? val?.dob.split("T")[0].split("-").reverse().join("-")
              : "",
          }))
        );
        setLoading(false);
        setTotal(res?.data?.Total);
      })
      .catch(() => {
        setLoading(false);
        setData([]);
      });
  };

  const handleDelete = () => {
    setDeleteModal(false);

    const promise = new Promise<void>((resolve, reject) => {
      axios({
        method: "delete",
        url: `https://backend.cftcommerce.com/api/aspirants/${inFocus}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then(() => {
          inFocus = "";
          resolve();
          getData();
        })
        .catch(() => {
          reject();
        });
    });

    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then();
  };

  const handleJoiningStatusChange = (e: any, a_id: any) => {
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "patch",
        url: `https://backend.cftcommerce.com/api/aspirants/${a_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
        data: {
          joiningStatus: e.target.value,
        },
      })
        .then((res) => {
          getData();
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Updating joining status...",
        success: "Joining status updated successfully.",
        error: "Error when updating joining status.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  const handleRemarkUpdate = (data: any, remark: any) => {
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "patch",
        url: `https://backend.cftcommerce.com/api/aspirants/${data._id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
        data: { remarksInput: remark },
      })
        .then((res: any) => {
          getData();
          resolve(true);
        })
        .catch((err: any) => {
          toast.error(err.response.data.message);
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Updating remarks...",
        success: "Remarks updated successfully.",
        error: "Error when updating remarks.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  const handleDropdownUpdate = (data: any, event: any) => {
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "patch",
        url: `https://backend.cftcommerce.com/api/aspirants/${data._id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
        data: { joiningStatus: event.target.value },
      })
        .then((res: any) => {
          getData();
          resolve(true);
        })
        .catch((err: any) => {
          toast.error(err.response.data.message);
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Updating status ...",
        success: "Status updated successfully.",
        error: "Error while updating status.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  const exportExcel = async () => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const response = await axios({
            url: `https://backend.cftcommerce.com/api/aspirants?identifier=${localStorage.getItem(
              "identifier"
            )}&limit=${total}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
            },
          });

          const data = response.data.aspirants.map((val: any) => ({
            ...val,
            createdAt: val?.createdAt
              ? val?.createdAt.split("T")[0].split("-").reverse().join("-")
              : "",
            cvUrl: val?.cvUrl
              ? `${process.env.REACT_APP_IMAGE}${folder_name()}${val?.cvUrl}`
              : "",
          }));

          console.log("all aspirants ::: ", data);

          ObjToExcel(data, "Applicants", "applicants", [
            { title: "Applied On", value: "createdAt" },
            { title: "Name", value: "firstName" },
            { title: "Applied Position", value: "appliedPosition" },
            { title: "Current City", value: "currentCity" },
            { title: "Phone Number", value: "mobileNumber" },
            { title: "Email", value: "email" },
            { title: "DOB", value: "dob" },
            { title: "Notice Period", value: "noticePeriod" },
            { title: "Qualification", value: "qualification" },
            { title: "Joining Status", value: "joiningStatus" },
            { title: "Experience", value: "experience" },
            { title: "Short Bio", value: "bioShort" },
            { title: "CV URL", value: "cvUrl", link: true },
          ]);
          resolve(true);
        } catch (err) {
          toast.error("Something went wrong while generating excel.");
          reject(true);
        }
      }),
      {
        loading: "Generating excel, Please wait...",
        success: "Excel Generated Successfully.",
        error: "Something went wrong while generating excel.",
      }
    );
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={deleteModal}
        onBtn1Click={() => {
          inFocus = "";
          setDeleteModal(false);
        }}
        onBtn2Click={handleDelete}
      />
      {modal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            background: "rgba(0,0,0,.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              maxHeight: "90vh",
              width: "60vw",
              background: "white",
              overflowY: "scroll",
              padding: "20px",
              borderRadius: "15px",
              position: "relative",
            }}
          >
            <Cross
              style={{
                position: "absolute",
                fill: "black",
                height: "30px",
                width: "30px",
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModal(false);
                setModalData({});
              }}
            />
            <h1
              style={{
                borderBottom: "2px solid black",
                width: "fit-content",
                marginBottom: "20px",
              }}
            >
              {modalData?.firstName}
              {modalData?.lastName}
            </h1>
            <div className={styles.enteries}>
              {modalData?.createdAt && (
                <p>
                  Applied On :<span>{modalData?.createdAt}</span>
                </p>
              )}
              <p>
                {/* @ts-ignore */}
                Email Id : <span>{modalData?.email}</span>
              </p>
              <p>
                {/* @ts-ignore */}
                Mobile : <span>{modalData?.mobileNumber}</span>
              </p>
              <p>
                {/* @ts-ignore */}
                DOB : <span>{modalData?.dob}</span>
              </p>
              <p>
                Current City : {/* @ts-ignore */}{" "}
                <span>{modalData?.currentCity}</span>
              </p>
              <p>
                Applied Position : {/* @ts-ignore */}{" "}
                <span>{modalData?.appliedPosition}</span>
              </p>
              <p>
                Education Qualification : {/* @ts-ignore */}{" "}
                <span>{modalData?.qualification}</span>
              </p>
              <p>
                Experience : {/* @ts-ignore */}{" "}
                <span>{modalData?.experience}</span>
              </p>
              <p>
                CV Url :{" "}
                <span>
                  <a
                    href={`${process.env.REACT_APP_IMAGE}${folder_name()}${
                      // @ts-ignore
                      modalData?.cvUrl
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* @ts-ignore */}
                    {modalData?.cvUrl}
                  </a>
                </span>
                <span
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "25px",
                    width: "25px",
                    aspectRatio: "1/1",
                    marginLeft: "10px",
                    background: "rgba(0,0,0, .5)",
                    borderRadius: "5px",
                    color: "white",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  <a
                    // @ts-ignore
                    href={`https://business-1-images.s3.ap-south-1.amazonaws.com/bion/${modalData?.cvUrl}`}
                    download={true}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    ↓
                  </a>
                </span>
              </p>
              <p>
                Notice Period :{/* @ts-ignore */}{" "}
                <span>{modalData?.noticePeriod}</span>
              </p>
              <p>
                Message :{/* @ts-ignore */}{" "}
                <span>{modalData?.noticePeriod}</span>
              </p>
            </div>
          </div>
        </div>
      )}
      <WithTableView
        loading={loading}
        heading="Applications"
        head={[
          { title: "S.No.", value: "s.no" },
          { title: "Applied On", value: "createdAt" },
          { title: "First Name", value: "firstName" },
          { title: "Email", value: "email" },
          { title: "Phone no.", value: "phone" },
          { title: "Applied Position", value: "applied_position" },
          { title: "Remarks", value: "" },
          { title: "Application Status", value: "" },
          { title: "Action", value: "action" },
          // delete, view(text)
        ]}
        body={[...data]}
        body_keys={[
          "createdAt",
          "firstName",
          "email",
          "mobileNumber",
          "appliedPosition",
          "remarks",
          "status:dropdown",
          "action:delete",
        ]}
        show_btn_1={true}
        btn1Text={langObj.exportExcel[lang]}
        btn1Type="light_grey"
        onClickHandler={() => exportExcel()}
        onRowClick={(val: any) => {
          setModal(true);
          setModalData(val);
        }}
        onRemarksSave={handleRemarkUpdate}
        onActionClick={(name: any, id: any) => {
          inFocus = id;
          setDeleteModal(true);
        }}
        handleDropdownUpdate={handleDropdownUpdate}
        customElement={
          <Pagination
            pageNo={
              Number(new URLSearchParams(location.search).get("page")) || 1
            }
            changePage={(val: number) => {
              let search = new URLSearchParams(location.search);

              search.set("page", String(val));

              navigate(`/applicants?${String(search)}`);
            }}
            total={total}
            showDrop={true}
            perPage={
              Number(new URLSearchParams(location.search).get("limit")) || 15
            }
            changeDrop={(val: number) => {
              let search = new URLSearchParams(location.search);

              search.set("limit", String(val));

              navigate(`/applicants?${String(search)}`);
            }}
          />
        }
      />
    </>
  );
};

export default Applications;
