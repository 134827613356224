import React from "react";
import styles from "./location.module.css";
import SingleLocation from "./SingleLocation";
import { ReactComponent as IndiaMap } from "./IndiaMap.svg";

type Props = {};

const Location = (props: Props) => {
  return (
    <>
      <h3>Location</h3>
      <div className={styles.mainContainer}>
        <div className={styles.mapContainer}>
          <IndiaMap
            style={{ width: "400px", height: "auto", marginInline: "auto" }}
          />
        </div>
        <div className={styles.SideContainer}>
          <div className={styles.heading}>
            <h5>City,Country</h5>
            <h5>Users</h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80%",
              color: "grey",
            }}
          >
            No Data Found
          </div>
          {/* <div className={styles.mainData}>
            <SingleLocation label="Bangalore" count={15} />
            <SingleLocation label="Jaipur" count={55} />
            <SingleLocation label="Mumbai" count={25} />
            <SingleLocation label="Pune" count={25} />
            <SingleLocation label="Ajmer" count={65} />
            <SingleLocation label="Agra" count={5} />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Location;
