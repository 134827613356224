import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WithTableView from "../../GenericPages/WithTableView";
import withRouter from "../../Components/withRouter";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import Pagination from "../../Components/Table/Pagination";
import langObj from "../../Utils/lang";
import { useLangContext } from "../../Store/langContext";
import { useAdminContext } from "../../Store/adminContext";

interface CaseStudyProps {
  router: any;
}

const CaseStudy: React.FC<CaseStudyProps> = ({ router }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang }: any = useLangContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const [inFocus, setInFocus] = useState<string>("");
  const { admin }: any = useAdminContext();

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    getData();
  };

  const getData = () => {
    let search = new URLSearchParams(location.search);
    setLoading(true);

    callApi({
      method: "get",
      url: `?identifier=${localStorage.getItem("identifier")}&page=${
        Number(search.get("page")) || 1
      }&limit=${Number(search.get("limit")) || 15}`,
      type: "casestudy",
      data: {},
    })
      .then((res) => {
        setData(res.data.caseStudies);
        setLoading(false);
        setTotal(res?.data?.blogsCount);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const deleteItem = () => {
    setModal(false);
    // call delete api
    const promise = new Promise<void>((resolve, reject) => {
      callApi({
        method: "delete",
        url: inFocus,
        data: {},
        header: true,
        type: "casestudy",
      })
        .then((res) => {
          setInFocus("");
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then(() => {
        getData();
      });
  };

  const handleAction = (val: string, id: string) => {
    if (val === "delete") {
      setModal(true);
      setInFocus(id);
    }
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => setModal(false)}
        onBtn2Click={deleteItem}
        state={modal}
      />
      <WithTableView
        heading={langObj.caseStudy[lang]}
        head={[
          { title: "S.No." },
          { title: "Title" },
          // { title: "Status" },
          { title: "" },
        ]}
        body={[...data]}
        body_keys={[
          "title",
          admin.role === "subadmin"
            ? admin.caseStudy_access.delete
              ? "action:delete"
              : ""
            : "action:delete",
        ]}
        show_btn_1={
          admin.role === "subadmin" ? admin.caseStudy_access.write : true
        }
        isIcon={true}
        iconType="add"
        btn1Text={langObj.newCaseStudy[lang]}
        btn1Type={"filled"}
        onActionClick={handleAction}
        onClickHandler={() => {
          router.navigate("/case-study/create");
        }}
        onRowClick={(val: any) => {
          router.navigate(`/case-study/update/${val._id}`);
        }}
        loading={loading}
        customElement={
          <Pagination
            pageNo={
              Number(new URLSearchParams(location.search).get("page")) || 1
            }
            changePage={(val: number) => {
              let search = new URLSearchParams(location.search);
              search.set("page", String(val));
              navigate(`/case-study/?${String(search)}`);
            }}
            total={total}
            showDrop={true}
            perPage={
              Number(new URLSearchParams(location.search).get("limit")) || 15
            }
            changeDrop={(val: number) => {
              let search = new URLSearchParams(location.search);
              search.set("limit", String(val));
              navigate(`/case-study/?${String(search)}`);
            }}
          />
        }
      />
    </>
  );
};

export default withRouter(CaseStudy);
