import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

import {
  Chart as CharJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

CharJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

type Props = {
  data: any;
};

const HorizontalBarChart = ({ data }: Props) => {
  const options: any = {
    indexAxis: "y" as const,
    tooltips: {
      callbacks: {
        label: function (data: any) {
          var label = data.datasets.label || "";
          if (label) {
            label += ": ";
          }
          label += data.datasets.label.xLabel;
          return label;
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: "end" as "end",
        align: "right" as "right",
        formatter: (value: number, context: { dataIndex: number }) => {
          return value.toString();
        },
      },
      legend: {
        display: false, // Disable the legend
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  };
  return (
    <div>
      <Bar
        data={data}
        options={options}
        style={{ width: "100%", height: "auto" }}
      ></Bar>
    </div>
  );
};

export default HorizontalBarChart;
