import { useEffect, useRef, useState } from "react";
import styles from "../../Pages/Leads/leads.module.css";
import { ReactComponent as DownIcon } from "../../Assets/icons/down.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SingleRowUser = ({
  data,
  changeSelect,
  disableForAction,
  updatedContacts,
  setUpdatedContacts,
  subAdmins,
  onRowClick,
}: any) => {
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editOwner, setEditOwner] = useState(false);
  const [ownerList, setOwnerList] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [statusList, setStatusList] = useState(false);

  const [ownerSearch, setOwnerSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const emailInputRef: any = useRef(null);
  const phoneInputRef: any = useRef(null);

  useEffect(() => {
    setEmail(data?.personalEmail || "");
    setPhone(data?.mobileNumber || "");
  }, [data]);

  useEffect(() => {
    if (updatedContacts.length === 0) {
      setEditEmail(false);
      setEditPhone(false);
    }
  }, [updatedContacts]);

  useEffect(() => {
    if (editEmail && emailInputRef.current) {
      emailInputRef?.current.focus();
    }
  }, [editEmail]);

  useEffect(() => {
    if (editPhone && phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, [editPhone]);

  console.log(data);

  return (
    <tr onClick={onRowClick}>
      <td>
        {/* <input
          type="checkbox"
          //   checked={data.actions.select}
          onClick={(e) => {
            e.stopPropagation();
            changeSelect(data._id);
          }}
        /> */}
      </td>
      <td>{data?.firstName || "--"}</td>
      <td>{data?.lastName || "--"}</td>
      <td>{data?.email || "--"}</td>
      <td>{data?.mobile?.number || "--"}</td>
      <td>{data?.dob || "--"}</td>

      <td>
        {data?.modifiedAt
          ? `${data?.modifiedAt
              ?.split("T")[0]
              .split("-")
              .reverse()
              .join("/")} ${data?.modifiedAt
              ?.split("T")[1]
              .split(":")
              .slice(0, 2)
              .join(":")} `
          : "--"}
      </td>

      <td>
        {data?.createdAt?.split("T")[0].split("-").reverse().join("/")}{" "}
        {data?.createdAt?.split("T")[1].split(":").slice(0, 2).join(":")}
      </td>
    </tr>
  );
};

const leads_obj_ver: any = {
  new: "New",
  open: "Open",
  in_progress: "In Progress",
  open_deal: "Open Deal",
  unqualified: "Unqualified",
  attempted_to_contact: "Attempted to Contact",
  connected: "Connected",
  bad_timing: "Bad Timing",
  unassigned: "Unassigned",
};

const lead_values = [
  { title: "New", value: "new" },
  { title: "Open", value: "open" },
  { title: "In Progress", value: "in_progress" },
  { title: "Open Deal", value: "open_deal" },
  { title: "Unqualified", value: "unqualified" },
  { title: "Attempted to Contact", value: "attempted_to_contact" },
  { title: "Connected", value: "connected" },
  { title: "Bad Timing", value: "bad_timing" },
  { title: "Unassigned", value: "unassigned" },
];

export default SingleRowUser;
