import { Component } from "react";
import CreateJob from "../createJob";

class UpdateJob extends Component {
	render() {
		return (
			<>
				<CreateJob title={"Update Job"} />
			</>
		);
	}
}
export default UpdateJob;
