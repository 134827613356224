import styles from "../../Styles/draggableTable.module.css";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Grip } from "./grip.svg";
import langObj from "../../Utils/lang";
import { useLangContext } from "../../Store/langContext";

const DraggableTable = ({
  head,
  body,
  body_keys,
  onSwap,
  onDelete,
  onEdit,
  pageNo,
}: any) => {
  const { lang }: any = useLangContext();

  const swapPriority = (id1: any, id2: any, priority1: any, priority2: any) => {
    // make swap api call
    // console.log("swap called");
    // console.log(id1, id2, priority1, priority2);
    onSwap(id1, id2, priority1, priority2);
  };

  function handleDragEnd(event: any) {
    // console.log("drag End called");
    const { active, over } = event;
    // console.log(active, over);

    if (active.id !== over.id) {
      const draggedItem = body.find((item: any) => item.id === active.id);
      const targetItem = body.find((item: any) => item.id === over.id);
      console.log(draggedItem);
      console.log(targetItem);

      if (draggedItem && targetItem) {
        swapPriority(
          draggedItem.id,
          targetItem.id,
          draggedItem.priority,
          targetItem.priority
        );
      }
    }
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <div className={styles.container}>
        {/* table head */}
        <div
          className={styles.table_head}
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${head.length - 1}, 1fr) 0.5fr`,
            background: `rgba(57, 126, 230, 0.2)`,
          }}
        >
          {head.map((val: any, index: any) => {
            return (
              <div className={styles.table_head_cell} key={index}>
                {langObj[val][lang]}
              </div>
            );
          })}
        </div>

        {/* table body */}
        <SortableContext items={body} strategy={verticalListSortingStrategy}>
          {body.map((val: any, index: any) => {
            return (
              <TableBodyRow
                key={index}
                val={val}
                index={index}
                body_keys={body_keys}
                head={head}
                onDelete={onDelete}
                onEdit={onEdit}
                pageNo={pageNo}
              />
            );
          })}
        </SortableContext>
      </div>
    </DndContext>
  );
};

const TableBodyRow = ({
  val,
  index,
  body_keys,
  head,
  onDelete,
  onEdit,
  pageNo,
}: any) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: val.id, transition: null });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
        display: "grid",
        gridTemplateColumns: `repeat(${head.length - 1}, 1fr) 0.5fr`,
        position: "relative",
      }}
      className={styles.table_body_row}
    >
      <Grip
        style={{
          height: "100%",
          position: "absolute",
          padding: "10px 0",
          left: "10p",
          width: "20px",
          cursor: "grab",
          outline: "none",
          marginLeft: "10px",
        }}
        {...attributes}
        {...listeners}
      />
      {body_keys.map((key: any, ind: any) => {
        if (key === "action") {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ fontSize: "1rem", zIndex: 10, cursor: "pointer" }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  onEdit(val.id);
                }}
              />
              <div
                onClick={(e: any) => {
                  e.stopPropagation();
                  onDelete(val, val.id);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  color={"red"}
                  style={{ fontSize: "1rem", zIndex: 10, cursor: "pointer" }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onDelete(val, val.id);
                  }}
                />
              </div>
            </div>
          );
        }
        if (key === "show") {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
              }}
            >
              <FontAwesomeIcon
                icon={faEye}
                style={{ fontSize: "1rem", zIndex: 10, cursor: "pointer" }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  onEdit(val.id);
                }}
              />
            </div>
          );
        }
        if (key === "write") {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ fontSize: "1rem", zIndex: 10, cursor: "pointer" }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  onEdit(val.id);
                }}
              />
            </div>
          );
        }
        return (
          <>
            {body_keys[body_keys.length - 1] === "htmlelem" ? (
              <>
                {/* {
               key == "sno" ? (
                  <p className={styles.table_head_cell}>{index + 1}</p>
                ) :
                 ( */}
                <p
                  className={styles.table_head_cell}
                  dangerouslySetInnerHTML={{ __html: val[key] }}
                ></p>
                {/* // ) */}
                {/* }{" "} */}
              </>
            ) : (
              <>
                {typeof val[key] === "boolean" ? (
                  <p className={styles.table_head_cell}>
                    {val[key] ? "True" : "False"}
                  </p>
                ) : key === "sno" ? (
                  <p className={styles.table_head_cell}>
                    {pageNo * 1 + index + 1}
                  </p>
                ) : (
                  <p
                    className={styles.table_head_cell}
                    style={{
                      maxWidth: "40ch",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textAlign: "start",
                    }}
                  >
                    {makeItShort(val[key], 40)}
                  </p>
                )}
              </>
            )}
          </>
        );
      })}
    </div>
  );
};

const makeItShort = (val: any, number: any) => {
  if (val) {
    if (val.length > number) {
      return val.slice(0, number) + "...";
    }
  }
  return val;
};

export default DraggableTable;
