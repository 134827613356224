import React from "react";
import AddUpdateProduct from "../AddUpdate";

class UpdateProduct extends React.Component {
  render() {
    return (
      <>
        <AddUpdateProduct />
      </>
    );
  }
}

export default UpdateProduct;
