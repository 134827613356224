import React, { useEffect, useState } from "react";
import styles from "./topSelling.module.css";
import axios from "axios";
import HorizontalBarChart from "../../../Components/Charts/HorizontalBarChart";
import FilterData from "../../../Components/Analytics/filterData";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

type Props = {};
type TopSellingProducts = Product[];
type Product = {
  name: string;
  quantity: number;
  category: string;
};
const url = "https://backend.cftcommerce.com/";
const TopSellingProducts = (props: any) => {
  const [topSellingProducts, setTopSellingProducts] =
    useState<TopSellingProducts>();
  const [selectedCategory, setSelectedCategory] = useState("");
  useEffect(() => {
    getTopSellingProducts();
  }, []);

  const getTopSellingProducts = () => {
    axios({
      url: `${url}api/analytics/getTopSellingProducts?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res) => {
        console.log(res.data.topSellingProducts);
        setTopSellingProducts(res.data.topSellingProducts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterProductsByCategory = (): Product[] => {
    if (!selectedCategory) {
      return topSellingProducts as Product[];
    }
    return topSellingProducts?.filter(
      (product: Product) =>
        product.category.toLowerCase() === selectedCategory.toLowerCase()
    ) as Product[];
  };
  const filteredData = filterProductsByCategory();

  // Get unique categories
  const getUniqueCategories = () => {
    const categories = new Set();
    topSellingProducts?.forEach((product) => categories.add(product?.category));
    return Array.from(categories) as string[];
  };

  const categories = getUniqueCategories();
  const data = {
    labels: filteredData?.map((data) => data.name),
    datasets: [
      {
        label: "Top Selling",
        data: filteredData?.map((data) => data.quantity),
        backgroundColor: "#397EE6",
      },
    ],
  };
  return (
    <div className={styles.container}>
      <div className={styles.Top}>
        <Link
          className={styles.Link}
          to={"/analytics"}
          style={{ background: "none" }}
        >
          <MdArrowBack size={22} color="#0E81F1" />
        </Link>
        <h1 className={styles.Heading}>Top Selling Products</h1>
      </div>
      <div className="styles.filterContainer">
        <FilterData
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categories={categories}
        />
      </div>
      <div className={styles.chartContainer}>
        <HorizontalBarChart data={data} />
      </div>
    </div>
  );
};

export default TopSellingProducts;
