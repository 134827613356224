import styles from "../../Styles/pagination.module.css";

const Pagination = ({
  pageNo,
  changePage,
  total,
  showDrop = false,
  dropValue,
  changeDrop,
  perPage = 15,
}: {
  pageNo: any;
  changePage: any;
  total: any;
  showDrop?: boolean;
  dropValue?: number;
  changeDrop?: any;
  perPage?: number;
}) => {
  const total_pages = Math.ceil(total / perPage);

  return (
    <div className={styles.container}>
      {/* for pages */}
      <div className={styles.pagination_container}>
        {/* go to first page */}
        {/* <div
          className={styles.controls}
          style={pageNo === 1 ? { cursor: "not-allowed", color: "grey" } : {}}
        >
          ⇤
        </div> */}
        {/* go to previous page */}
        <div
          className={styles.controls}
          style={pageNo === 1 ? { cursor: "not-allowed", color: "grey" } : {}}
          onClick={() => {
            if (pageNo !== 1) {
              changePage(pageNo - 1);
            }
          }}
        >
          ←
        </div>
        {/* page numbers */}
        <ul>
          {total_pages === 1 ? (
            <>
              <li className={styles.selected}>{pageNo}</li>
            </>
          ) : total_pages === 2 ? (
            <>
              {pageNo === 1 ? (
                <>
                  <li className={styles.selected}>{pageNo}</li>
                  <li onClick={() => changePage(pageNo + 1)}>{pageNo + 1}</li>
                </>
              ) : (
                <>
                  <li onClick={() => changePage(pageNo - 1)}>{pageNo - 1}</li>
                  <li className={styles.selected}>{pageNo}</li>
                </>
              )}
            </>
          ) : total_pages === pageNo ? (
            <>
              <li onClick={() => changePage(pageNo - 2)}>{pageNo - 2}</li>
              <li onClick={() => changePage(pageNo - 1)}>{pageNo - 1}</li>
              <li className={styles.selected}>{pageNo}</li>
            </>
          ) : pageNo === 1 ? (
            <>
              <li className={styles.selected} onClick={() => changePage(1)}>
                {pageNo}
              </li>
              <li onClick={() => changePage(pageNo + 1)}>{pageNo + 1}</li>
              <li onClick={() => changePage(pageNo + 2)}>{pageNo + 2}</li>
            </>
          ) : (
            <>
              <li onClick={() => changePage(pageNo - 1)}>{pageNo - 1}</li>
              <li className={styles.selected}>{pageNo}</li>
              <li onClick={() => changePage(pageNo + 1)}>{pageNo + 1}</li>
            </>
          )}
        </ul>
        {/* next page */}
        <div
          className={styles.controls}
          style={
            pageNo === total_pages
              ? { cursor: "not-allowed", color: "grey" }
              : {}
          }
          onClick={() => {
            if (pageNo !== total_pages) {
              changePage(pageNo + 1);
            }
          }}
        >
          →
        </div>
        {/* last page */}
        {/* <div className={styles.controls}>⇥</div> */}
      </div>

      {showDrop && (
        <div className={styles.select_container}>
          <select
            value={dropValue}
            onChange={(e: any) => {
              changeDrop(e.target.value);
            }}
          >
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={150}>150</option>
          </select>
        </div>
      )}
    </div>
  );
};

export default Pagination;
