import React from "react";
import styles from "./topCard.module.css";

type Props = {
  setOrderType: React.Dispatch<React.SetStateAction<string>>;
  orders: string[];
  orderType: string;
};

const TopCard = ({ setOrderType, orders, orderType }: Props) => {
  return (
    <div className={styles.container}>
      {orders.map((order, i) => (
        <button
          key={i}
          onClick={() => setOrderType(order)}
          className={`${styles.singleCard} ${
            orderType === order ? styles.selected : ""
          }`}
        >
          <h5 style={{ fontSize: ".9rem" }}>{order}</h5>
        </button>
      ))}
    </div>
  );
};

export default TopCard;
