import heic2any from "heic2any";
import compressVideo from "./compressVideo";
import { convertToAVIF } from "./videoConversion";

function compressImage(file: any, quality: any) {
  return new Promise(async (resolve, reject) => {
    // Checks file type
    // if file is pdf or avif it return the file as it is
    if (file.type.includes("application/pdf")) {
      resolve(file);
    }

    if (file.type.includes("image/avif")) {
      resolve(file);
    }

    // if file is video it should convert it to avif and return the values accordingly
    if (file.type.includes("video")) {
      resolve(file);
    }

    // Handle HEIC files
    if (file.type.includes("image/heic")) {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/webp",
        });
        resolve(convertedBlob);
        return;
      } catch (error) {
        reject(error);
        return;
      }
    }

    // at last if file is of type image it compresses it and return it's value
    const reader = new FileReader();
    reader.onload = function (event: any) {
      const img: any = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx: any = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Get the compressed image as a Blob object
        canvas.toBlob(
          function (blob) {
            resolve(blob);
          },
          "image/webp",
          quality
        );
      };

      img.onerror = function (error: any) {
        reject(error);
      };

      img.src = event.target.result;
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export default compressImage;
