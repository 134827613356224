import AppsContainer from "../../Components/Apps/Container";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";

const Apps = () => {
  return (
    <Content>
      <ContentHead title={"Apps"} />
      <ContentBody>
        <AppsContainer />
      </ContentBody>
    </Content>
  );
};

export default Apps;
